import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalStereotypeService
} from '@nexnox-web/core-portal';
import {AppEntityType, FilterOperators, StereotypeDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {cloneDeep, isNull, omit} from 'lodash';
import {distinctUntilChanged, filter, map, pairwise, takeUntil, tap} from 'rxjs/operators';
import {corePortalSettingsStereotypesEntityTypeOptions} from '../../models';
import {BehaviorSubject, Subject} from 'rxjs';
import {
  CORE_PORTAL_FEATURE_STEREOTYPE_ADDITIONAL_FIELDS,
  CORE_PORTAL_FEATURE_STEREOTYPES_ALLOWED_ENTITY_TYPES,
  CorePortalFeatureStereotypeAdditionalFieldsToken
} from '../../tokens';
import {FormGroup} from '@angular/forms';
import {CustomPropertySetsEditComponent} from '../custom-property-sets-edit/custom-property-sets-edit.component';

@Component({
  selector: 'nexnox-web-settings-stereotypes-stereotype-edit',
  templateUrl: './stereotype-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureSettingsStereotypeEditComponent extends CorePortalEntityEditBaseComponent<StereotypeDto> implements OnInit {
  @ViewChild('customPropertySetsEditComponent') public customPropertySetsEditComponent: CustomPropertySetsEditComponent;

  private refreshSubject: Subject<void> = new Subject<void>();
  private entityTypeSubject: BehaviorSubject<AppEntityType> = new BehaviorSubject<AppEntityType>(null);
  private resetSubject: Subject<void> = new Subject<void>();

  private additionalFieldNames: string[] = [];
  public options: FormlyFormOptions;

  constructor(
    protected injector: Injector,
    @Optional() @Inject(CORE_PORTAL_FEATURE_STEREOTYPE_ADDITIONAL_FIELDS)
    private additionalFields: CorePortalFeatureStereotypeAdditionalFieldsToken,
    private stereotypeService: CorePortalStereotypeService,
    @Optional() @Inject(CORE_PORTAL_FEATURE_STEREOTYPES_ALLOWED_ENTITY_TYPES) private allowedEntityTypes: AppEntityType[],
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(injector, 'CorePortalFeatureSettingsStereotypeEditComponent');
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (!this.creating) {
      this.subscribe(this.entityTypeSubject.asObservable().pipe(
        distinctUntilChanged()
      ), () => {
        this.form = new FormGroup({});
        this.fields = this.createForm();
        this.model = cloneDeep(this.model);
        this.resetSubject.next();
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  public onCustomPropertySetsChange(model: StereotypeDto): void {
    this.setModel({
      ...this.model,
      customPropertySets: model?.customPropertySets ?? []
    });
    setTimeout(() => this.onModelChange(this.model));
  }

  /* istanbul ignore next */
  public onModelChange(model: StereotypeDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      customPropertySets: this.customPropertySetsEditComponent ? this.customPropertySetsEditComponent.isModelValid() : true
    });

    super.onModelChange(model);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          titleKey: 'name',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'position',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          type: 'number',
          min: 0,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.position',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'isDefault',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-default'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          className: () => this.creating ? 'col-md-2' : 'col-md-2',
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'entityType',
        hooks: {
          onInit: field => {
            this.form.updateValueAndValidity();

            this.subscribe(field.formControl.valueChanges.pipe(
              takeUntil(this.resetSubject.asObservable()),
              filter(value => !isNull(value)),
              tap(value => {
                this.entityTypeSubject.next(value);

                if (this.creating) {
                  this.form = new FormGroup({});
                  this.fields = this.createForm();
                  this.model = omit({
                    ...this.model,
                    entityType: value
                  }, ['parent', ...this.additionalFieldNames]);
                  this.resetSubject.next();
                  this.changeDetectorRef.detectChanges();
                }
              }),
              pairwise(),
              filter(([value1, value2]) => value1 !== value2)
            ), () => this.refreshSubject.next());
          }
        }
      },
      {key: 'parentId'},
      {
        key: 'parent',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.inherits-from'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: this.module
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          wholeObject: true,
          entityId: this.model?.stereotypeId,
          defaultFilters$: this.entityTypeSubject.asObservable().pipe(
            map(entityType => ([
              {
                property: 'entityType',
                operator: FilterOperators.Equal,
                value: entityType?.toString()
              },
              {
                property: 'isArchived',
                operator: FilterOperators.Equal,
                value: false.toString()
              }
            ]))
          ),
          idKey: 'stereotypeId',
          displayKey: 'name',
          skipGetOne: true,
          refresh$: this.refreshSubject.asObservable(),
          link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
          module: this.module
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || !this.model?.entityType,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            takeUntil(this.resetSubject.asObservable())
          ), (value: StereotypeDto) => this.form.controls.parentId.setValue(value?.stereotypeId ?? null))
        }
      },
      ...this.getAdditionalFields()
    ];
  }

  /* istanbul ignore next */
  private getAdditionalFields(): FormlyFieldConfig[] {
    const additionalFields = this.additionalFields ? this.additionalFields(this.injector, this) : null;

    if (!additionalFields) {
      return [];
    }

    this.additionalFieldNames = (additionalFields[this.model?.entityType] ?? []).map(field => field.key as string);
    return (additionalFields[this.model?.entityType] ?? []).map(field => ({
      ...field,
      defaultValue: (this.model ? this.model[field.key as string] : undefined) ?? field.defaultValue
    }));
  }
}
