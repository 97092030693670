import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreSharedBreadcrumb, CoreSharedBreadcrumbsResolver } from '../../modules';

@Injectable()
export class I18nBreadcrumbResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private translate: TranslateService
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    const path = this.getFullPath(route);
    const permissions = route.routeConfig.data?.breadcrumbPermissions;

    return this.translate.stream(route.data.text).pipe(
      map(text => ([{ text, path, permissions }]))
    );
  }
}
