import {ChangeDetectionStrategy, Component, EventEmitter, Injector, OnInit, Output} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  TechPortalFeatureTicketSettingsPriorityService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {distinctUntilChanged, filter, map, skip, startWith, take} from 'rxjs/operators';
import {
  AppEntityType,
  AppPermissions,
  ContactDto, CoreSharedIssueLinkUtilityService,
  EscalationLevelDto,
  FilterDto,
  FilterOperators,
  FilterTypes, IssueSimpleDto,
  LocationSimpleDto,
  PriorityDto,
  ResourceInMissionDto, TicketDto
} from '@nexnox-web/core-shared';
import {isNumber} from 'lodash';
import {
  TechPortalFeatureTicketSettingsEscalationLevelService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/escalation-levels';
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {Observable} from 'rxjs';
import {faFolder} from '@fortawesome/free-solid-svg-icons/faFolder';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {faInbox} from "@fortawesome/free-solid-svg-icons/faInbox";

@Component({
  selector: 'nexnox-web-tickets-ticket-status-edit',
  templateUrl: './ticket-status-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketStatusEditComponent extends CorePortalEntityEditBaseComponent<TicketDto> implements OnInit {
  @Output() public editEditor: EventEmitter<void> = new EventEmitter<void>();

  public editor$: Observable<ContactDto>;
  public resource$: Observable<ResourceInMissionDto>;
  public resourcePath$: Observable<any[]>;

  public locationReference$: Observable<LocationSimpleDto>;
  public locationAddressesReference$: Observable<any[]>;
  public primaryIssue$: Observable<IssueSimpleDto>;

  public canAssignTo$: Observable<boolean>;

  public faCircle = faCircle;
  public faPencilAlt = faPencilAlt;
  public faFolder = faFolder;
  public faUser = faUser;
  public faHome = faHome;
  public faInbox = faInbox;

  constructor(
    protected injector: Injector,
    private escalationLevelService: TechPortalFeatureTicketSettingsEscalationLevelService,
    private priorityService: TechPortalFeatureTicketSettingsPriorityService,
    private permissionService: CorePortalPermissionService,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.editor$ = this.modelSubject.asObservable().pipe(
      filter(model => Boolean(model)),
      map(model => model.editor)
    );

    this.resource$ = this.modelSubject.asObservable().pipe(
      filter(model => Boolean(model)),
      map(model => model.resource)
    );

    this.resourcePath$ = this.resource$.pipe(
      filter(resource => Boolean(resource)),
      map(resource => (['/resources', resource.resourceId]))
    );

    this.locationReference$ = this.modelSubject.asObservable().pipe(
      filter(ticket => Boolean(ticket?.location)),
      map(ticket => ticket.location)
    );

    this.canAssignTo$ = this.permissionService.hasPermission$(AppPermissions.AssignTicketEditor);

    this.primaryIssue$ = this.modelSubject.asObservable().pipe(
      map(model => model?.origin?.primaryIssue)
    );
  }

  public getIssueLink(primaryIssue: IssueSimpleDto): Array<string | number> {
    return this.issueUtilityService.getIssueSegments(primaryIssue);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      { key: 'escalationLevelId' },
      {
        key: 'escalationLevel',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'tickets.fields.escalation-level'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (escalationLevel: EscalationLevelDto) => escalationLevel?.escalationLevelId ?
              ['escalation-levels', escalationLevel.escalationLevelId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.escalationLevelService,
          idKey: 'escalationLevelId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          link: (escalationLevel: EscalationLevelDto) => escalationLevel?.escalationLevelId ?
            [ 'escalation-levels', escalationLevel.escalationLevelId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges, (value: EscalationLevelDto) => {
            this.form.controls.escalationLevelId.setValue(value?.escalationLevelId ?? null);
          })
        }
      },
      { key: 'priorityId' },
      {
        key: 'priority',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.priority',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.priorityService,
          idKey: 'priorityId',
          displayKey: 'name',
          wholeObject: true,
          clearable: false,
          defaultFilters$: this.selectedStereotype$.pipe(
            map(stereotype => {
              if (!stereotype || stereotype.entityType !== AppEntityType.Ticket) {
                return [];
              }

              return [{
                property: 'prioritySetId',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: stereotype.prioritySet?.prioritySetId?.toString()
              }] as FilterDto[];
            })
          ),
          skipGetOne: true,
          waitUntil$: this.selectedStereotype$.pipe(
            filter(selectedStereotype => Boolean(selectedStereotype)),
            take(1)
          )
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly || !isNumber(this.model?.stereotypeId),
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => {
            this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged()
            ), (value: PriorityDto) => this.form.controls.priorityId.setValue(value?.priorityId ?? null));

            this.subscribe(this.selectedStereotype$.pipe(
              filter(stereotype => Boolean(stereotype)),
              skip(1),
              distinctUntilChanged()
            ), () => field.formControl.setValue(null));
          }
        }
      },
      {
        key: 'solution.threadMailAddress',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'tech-portal.fields.ticket-address'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          readonly: true
        }
      }
    ];
  }
}
