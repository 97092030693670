import {CorePortalContactService, CorePortalDashboardConfig, CorePortalFunctionService} from '@nexnox-web/core-portal';
import {AppEntityType, ControllerOperationId} from '@nexnox-web/core-shared';
import {TechPortalTicketService} from '@nexnox-web/tech-portal-lib';
import {
  TechPortalFeatureTicketSettingsEscalationLevelService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/escalation-levels';
import {Injector} from '@angular/core';
import {
  TechPortalFeatureTicketSettingsStateService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/states';
import {
  TechPortalFeatureResourceDetailListComponent,
  TechPortalFeatureResourceMissionService,
  TechPortalFeatureResourceService,
  TechPortalFeatureResourceTicketService,
  TechPortalFeatureVirtualResourceService
} from '@nexnox-web/tech-portal/features/resources';
import {
  CorePortalFeatureMasterDataLocationListComponent,
  CorePortalFeatureMasterDataLocationService
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {
  CorePortalFeatureMasterDataLocationGroupService
} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {
  TechPortalFeatureDashboardMissionActionsFacade,
  TechPortalFeatureDashboardMissionDatatableStore,
  TechPortalFeatureMissionListComponent,
  TechPortalFeatureMissionService
} from '@nexnox-web/tech-portal/features/missions';
import {CorePortalFeatureResourceService} from '@nexnox-web/core-portal/features/resources';
import {TechPortalFeatureResourceTaskService} from '@nexnox-web/tech-portal/features/resource-tasks';
import {
  TechPortalFeatureContractListComponent,
  TechPortalFeatureContractService
} from '@nexnox-web/tech-portal/features/contracts';
import {
  CorePortalFeatureMasterDataCompanyListComponent,
  CorePortalFeatureMasterDataCompanyService
} from '@nexnox-web/core-portal/features/master-data/features/companies';
import {
  TechPortalFeatureDashboardTicketActionsFacade,
  TechPortalFeatureDashboardTicketDatatableStore,
  TechPortalFeatureTicketListComponent
} from '@nexnox-web/tech-portal/features/tickets';
import {
  CorePortalFeatureMasterDataContactListComponent,
  CorePortalFeatureMasterDataContactService
} from '@nexnox-web/core-portal/features/master-data/features/contacts';
import {TechPortalFeatureIssueListComponent, TechPortalFeatureIssueListService} from "@nexnox-web/tech-portal/features/issues";

export function provideDashboardConfig(): CorePortalDashboardConfig {
  return {
    [ControllerOperationId.TicketControllerList]: {
      listComponent: TechPortalFeatureTicketListComponent,
      datatableStore: TechPortalFeatureDashboardTicketDatatableStore,
      actionsFacade: TechPortalFeatureDashboardTicketActionsFacade,
      serializedName: 'TicketListDto',
      idKey: 'ticketId',
      entityService: TechPortalTicketService,
      entityType: AppEntityType.Ticket,
      detailLink: '/tech-dashboard/tickets',
      module: 'management',
      providers: [
        {
          provide: TechPortalFeatureTicketSettingsEscalationLevelService,
          useClass: TechPortalFeatureTicketSettingsEscalationLevelService,
          deps: [Injector]
        },
        {
          provide: TechPortalFeatureTicketSettingsStateService,
          useClass: TechPortalFeatureTicketSettingsStateService,
          deps: [Injector]
        },
        {
          provide: TechPortalFeatureResourceService,
          useClass: TechPortalFeatureResourceService,
          deps: [Injector]
        },
        {
          provide: CorePortalContactService,
          useClass: CorePortalContactService,
          deps: [Injector]
        },
        {
          provide: CorePortalFunctionService,
          useClass: CorePortalFunctionService,
          deps: [Injector]
        },
        {
          provide: CorePortalFeatureMasterDataLocationService,
          useClass: CorePortalFeatureMasterDataLocationService,
          deps: [Injector]
        },
        {
          provide: CorePortalFeatureMasterDataLocationGroupService,
          useClass: CorePortalFeatureMasterDataLocationGroupService,
          deps: [Injector]
        }
      ]
    },
    [ControllerOperationId.ContactControllerList]: {
      listComponent: CorePortalFeatureMasterDataContactListComponent,
      serializedName: 'ContactListDto',
      idKey: 'contactId',
      entityService: CorePortalContactService,
      entityType: AppEntityType.Contact,
      detailLink: '/tech-dashboard/contacts',
      module: 'management',
      providers: [
        {
          provide: CorePortalContactService,
          useClass: CorePortalContactService,
          deps: [Injector]
        },
        {
          provide: CorePortalFeatureMasterDataContactService,
          useClass: CorePortalFeatureMasterDataContactService,
          deps: [Injector]
        }
      ]
    },
    [ControllerOperationId.IssueControllerId]: {
      listComponent: TechPortalFeatureIssueListComponent,
      serializedName: 'IssueListDto',
      idKey: 'issueId',
      entityService: TechPortalFeatureIssueListService,
      entityType: AppEntityType.Issue,
      detailLink: null,
      module: 'management',
      providers: [
        {
          provide: TechPortalFeatureIssueListService,
          useClass: TechPortalFeatureIssueListService,
          deps: [Injector]
        }
      ]
    },
    [ControllerOperationId.ResourceControllerList]: {
      listComponent: TechPortalFeatureResourceDetailListComponent,
      listComponentProps: {
        allowMargin: true
      },
      serializedName: 'ResourceListDto',
      idKey: 'resourceId',
      entityService: TechPortalFeatureVirtualResourceService,
      entityType: AppEntityType.Resource,
      detailLink: '/tech-dashboard/resources',
      module: 'management',
      providers: [
        {
          provide: TechPortalFeatureVirtualResourceService,
          useClass: TechPortalFeatureVirtualResourceService,
          deps: [Injector]
        },
        {
          provide: TechPortalFeatureResourceTicketService,
          useClass: TechPortalFeatureResourceTicketService,
          deps: [Injector]
        },
        {
          provide: TechPortalFeatureResourceMissionService,
          useClass: TechPortalFeatureResourceMissionService,
          deps: [Injector]
        },
        {
          provide: CorePortalFeatureMasterDataLocationGroupService,
          useClass: CorePortalFeatureMasterDataLocationGroupService,
          deps: [Injector]
        }
      ]
    },
    [ControllerOperationId.MissionControllerList]: {
      listComponent: TechPortalFeatureMissionListComponent,
      datatableStore: TechPortalFeatureDashboardMissionDatatableStore,
      actionsFacade: TechPortalFeatureDashboardMissionActionsFacade,
      serializedName: 'MissionCompleteDto',
      idKey: 'missionId',
      entityService: TechPortalFeatureMissionService,
      entityType: AppEntityType.Mission,
      detailLink: '/tech-dashboard/missions',
      module: 'management',
      providers: [
        {
          provide: CorePortalFeatureResourceService,
          useClass: CorePortalFeatureResourceService,
          deps: [Injector]
        },
        {
          provide: TechPortalFeatureResourceTaskService,
          useClass: TechPortalFeatureResourceTaskService,
          deps: [Injector]
        },
        {
          provide: CorePortalContactService,
          useClass: CorePortalContactService,
          deps: [Injector]
        },
        {
          provide: CorePortalFunctionService,
          useClass: CorePortalFunctionService,
          deps: [Injector]
        },
        {
          provide: CorePortalFeatureMasterDataLocationGroupService,
          useClass: CorePortalFeatureMasterDataLocationGroupService,
          deps: [Injector]
        },
      ]
    },
    [ControllerOperationId.ContractControllerList]: {
      listComponent: TechPortalFeatureContractListComponent,
      serializedName: 'ContractListDto',
      idKey: 'contractId',
      entityService: TechPortalFeatureContractService,
      entityType: AppEntityType.Contract,
      detailLink: '/contracts',
      module: 'inventory',
      providers: [
        {
          provide: TechPortalFeatureContractService,
          useClass: TechPortalFeatureContractService,
          deps: [Injector]
        }
      ]
    },
    [ControllerOperationId.LocationControllerList]: {
      listComponent: CorePortalFeatureMasterDataLocationListComponent,
      serializedName: 'LocationListDto',
      idKey: 'locationId',
      entityService: CorePortalFeatureMasterDataLocationService,
      entityType: AppEntityType.Location,
      detailLink: '/masterdata/locations',
      module: 'management',
      providers: [{
        provide: CorePortalFeatureMasterDataLocationService,
        useClass: CorePortalFeatureMasterDataLocationService,
        deps: [Injector]
      }]
    },
    [ControllerOperationId.CompanyControllerList]: {
      listComponent: CorePortalFeatureMasterDataCompanyListComponent,
      serializedName: 'CompanyListDto',
      idKey: 'companyId',
      entityService: CorePortalFeatureMasterDataCompanyService,
      entityType: AppEntityType.Company,
      detailLink: '/masterdata/companies',
      module: 'management',
      providers: [{
        provide: CorePortalFeatureMasterDataCompanyService,
        useClass: CorePortalFeatureMasterDataCompanyService,
        deps: [Injector]
      }]
    }
  };
}
