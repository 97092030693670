import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  CORE_PORTAL_DASHBOARD_CONFIG,
  CorePortalDashboardConfig,
  CorePortalDashboardItemDatatableConfig,
  CorePortalDatatableColumnService
} from '@nexnox-web/core-portal';
import {
  DashboardItemTypes,
  DashboardTableReferenceDto,
  DataTableDto,
  DataTableFilterDto,
  Filter,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {DashboardItemDatatableStore, dashboardStore} from '../../store';
import {DashboardItemEntityActionsFacade, DashboardItemEntitySelectorsFacade} from '../../facades';
import {DashboardValueDatatable} from '../../store/stores/dashboard/dashboard-value-item.model';
import {BehaviorSubject, combineLatest, map, of} from 'rxjs';
import {cloneDeep, isNull} from 'lodash';
import {filter, take} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

@Component({
  selector: 'nexnox-web-new-dashboard-item-datatable',
  templateUrl: './new-dashboard-item-datatable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewDashboardItemDatatableComponent extends UnsubscribeHelper implements AfterViewInit {
  @Input()
  public set item(item: DashboardTableReferenceDto) {
    this.itemSubject.next({...item, dataTable: {...item?.dataTable, filters: []}});
    this.removedFiltersSubject.next(item?.dataTable?.filters ?? []);
  }

  public get item(): DashboardTableReferenceDto {
    return this.itemSubject.getValue();
  }

  @Input() public position: number;

  @ViewChild('viewContainer', {read: ViewContainerRef, static: true}) public viewContainer: ViewContainerRef;

  @Output() public itemChange = new EventEmitter<DashboardTableReferenceDto>();
  @Output() public loadPage = new EventEmitter<DashboardValueDatatable>();

  public config: CorePortalDashboardItemDatatableConfig;

  public datatableStore: DashboardItemDatatableStore<any>;

  public itemSubject = new BehaviorSubject<DashboardTableReferenceDto>(null);
  public savedFiltersSubject = new BehaviorSubject<Filter[]>(null);
  public removedFiltersSubject = new BehaviorSubject<DataTableFilterDto[]>([]);

  constructor(
    private injector: Injector,
    @Inject(CORE_PORTAL_DASHBOARD_CONFIG) private dashboardConfig: CorePortalDashboardConfig,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef,
    private datatableColumnService: CorePortalDatatableColumnService,
    private store: Store
  ) {
    super();
  }

  public async ngAfterViewInit(): Promise<void> {
    this.config = this.getConfig();
    if (!this.config) return;

    if (!this.config.datatableStore) {
      this.datatableStore = new DashboardItemDatatableStore<any>(
        this.injector,
        this.config.idKey,
        this.config.entityService,
        this.config.entityType,
        [],
        this.config
      );
    } else {
      this.datatableStore = new this.config.datatableStore(this.injector, this.config);
    }

    const loaded = await this.store.pipe(select(dashboardStore.selectors.selectLoaded), take(1)).toPromise();
    const datatableValueItem = cloneDeep(await this.store.pipe(
      select(dashboardStore.selectors.selectItem, {position: this.position}),
      take(1)
    ).toPromise()) as DashboardValueDatatable;

    this.createComponent(loaded, datatableValueItem);
    this.initialiseStore(loaded, datatableValueItem);
  }

  public onConfigChange(config: DataTableDto): void {
    this.item = {...this.item, dataTable: !isNull(config) ? {...this.item.dataTable, ...config} : config};
    this.itemChange.emit(this.item);
  }

  public initialiseStore(loaded: boolean, datatableValueItem: DashboardValueDatatable): void {
    if (loaded && datatableValueItem) {
      this.datatableStore.getStereotypesSuccess(datatableValueItem.stereotypes);
      this.datatableStore.getPageSuccess({
        items: datatableValueItem.items,
        paging: datatableValueItem.paging
      });
      this.savedFiltersSubject.next(datatableValueItem.filters);
    }

    /* istanbul ignore next */
    this.subscribe(combineLatest([
      this.datatableStore.actions$.pipe(filter(x => x.action === 'GET_PAGE_SUCCESS')),
      this.datatableStore.actions$.pipe(filter(x => x.action === 'GET_STEREOTYPES_SUCCESS'))
    ]), ([{state}, {state: stereotypesState}]) => {
      const clonedState = {...cloneDeep(state), stereotypes: stereotypesState.stereotypes};
      this.loadPage.emit({
        filters: clonedState.filters,
        items: this.datatableStore.adapter.getSelectors().selectAll(clonedState),
        stereotypes: clonedState.stereotypes,
        paging: clonedState.paging,
        position: this.position,
        type: DashboardItemTypes.DataTable
      });
    });
  }

  private createComponent(loaded: boolean, datatableValueItem: DashboardValueDatatable): void {
    this.viewContainer.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.config.listComponent);
    const component = this.viewContainer.createComponent(
      componentFactory,
      0,
      Injector.create({parent: this.injector, providers: this.config.providers ?? []})
    )?.instance;

    component.title = this.item?.dataTable?.name;
    component.disableTitleTranslation = true;
    component.isEmbedded = true;
    component.canCreate = false;
    component.canDelete = false;
    component.detailLink = this.config.detailLink;
    component.module = this.config.module;
    component.componentId = undefined;
    component.customDatatableConfig = this.item?.dataTable;
    component.disableSettingsViewSelect = true;
    component.getEntityOnModeChange = false;
    component.noInitialGet = loaded && Boolean(datatableValueItem);
    component.savedFilters$ = this.savedFiltersSubject.asObservable();
    component.filtersForExport$ = this.removedFiltersSubject.asObservable();

    this.datatableStore.actions$.pipe(
      filter(x => x.action === 'GET_STEREOTYPES_SUCCESS'),
      map(x => x.state?.stereotypes)
    ).subscribe(x => component.stereotypes$ = of(x));

    for (const key in this.config.listComponentProps ?? {}) {
      component[key] = this.config.listComponentProps[key];
    }

    this.subscribe(component.datatableConfigChange, config => this.onConfigChange(config));

    component.customActionsFacade = this.config.actionsFacade ?
      new this.config.actionsFacade(this.datatableStore) : new DashboardItemEntityActionsFacade(this.datatableStore);
    component.customSelectorsFacade = new DashboardItemEntitySelectorsFacade(this.datatableStore);

    this.changeDetector.detectChanges();
  }

  private getConfig(): CorePortalDashboardItemDatatableConfig {
    return this.dashboardConfig[this.item?.dataTable?.pageOperation] ?? null;
  }
}
