import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BasicTableColumnType, BasicTableConfig, TenantUsageDto, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {CorePortalPageTitleService} from "@nexnox-web/core-portal";
import {mergeMap, Observable} from "rxjs";
import {TenantService} from "@nexnox-web/core-portal/features/service/src/lib/tenants/store";
import {map} from "rxjs/operators";

interface TenantUsageLocalDto {
  title: string;
  availableVolume: number;
  usage: {
    usage: number;
    total: number
  }
}

@Component({
  selector: 'nexnox-web-service-tenants-tenant-usage',
  templateUrl: './tenant-usage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantUsageComponent extends UnsubscribeHelper implements OnInit {

  @Input() public tenantId: number | string;
  @Input() public loading$: Observable<boolean>;

  public config: BasicTableConfig;
  public usageData$: Observable<TenantUsageLocalDto[]>;

  constructor(
    private titleSerivce: CorePortalPageTitleService,
    private tenantService: TenantService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.titleSerivce.setPageTitle('core-portal.service.tenants.license-usage');

    this.config = {
      class: 'w-100',
      columns: [
        {
          key: 'title',
          header: 'core-portal.service.tenants.fields.license-kind',
          type: BasicTableColumnType.TextTranslation,
        },
        {
          key: 'availableVolume',
          header: 'core-portal.service.tenants.fields.available-licenses',
          type: BasicTableColumnType.Text,
        },
        {
          key: 'usage',
          header: 'core-portal.service.tenants.fields.usage',
          type: BasicTableColumnType.UsageOfTotal
        }
      ]
    }

    this.usageData$ = this.loading$?.pipe(mergeMap(loading => this._getUsageForTenant(this.tenantId)));
  }

  private _getUsageForTenant(tenantId: number | string): Observable<TenantUsageLocalDto[]> {
    return this.tenantService.getUsage(tenantId).pipe(map((usage) => this._mapUsageData(usage)));
  }

  private _mapUsageData(usage: TenantUsageDto): TenantUsageLocalDto[] {

    return [
      {
        title: 'core-portal.service.tenants.fields.users',
        availableVolume: usage.activeUserVolume - usage.activeUsers,
        usage: {
          usage: usage.activeUsers,
          total: usage.activeUserVolume
        }
      },
      {
        title: 'core-portal.service.tenants.fields.locations',
        availableVolume: usage.activeLocationVolume - usage.activeLocations,
        usage: {
          usage: usage.activeLocations,
          total: usage.activeLocationVolume
        }
      },
      {
        title: 'core-portal.service.tenants.fields.gdpr-rules',
        availableVolume: usage.activeGdprRuleVolume - usage.activeGdprRules,
        usage: {
          usage: usage.activeGdprRules,
          total: usage.activeGdprRuleVolume
        }
      },
      {
        title: 'core-portal.service.tenants.fields.notification-rules',
        availableVolume: usage.activeNotificationRuleVolume - usage.activeNotificationRules,
        usage: {
          usage: usage.activeNotificationRules,
          total: usage.activeNotificationRuleVolume
        }
      }
    ];
  }
}
