import {Directive, HostListener, Injector, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {
  AppPermissions,
  CORE_SHARED_ENVIRONMENT,
  CoreSharedExportService,
  CoreSharedModalService,
  Environment,
  ExportType,
  MonitorSet,
  StereotypeDto,
  UnsavedChanges,
  UnsubscribeHelper,
  ViewChildrenHelper
} from '@nexnox-web/core-shared';
import {ModelPristine, ModelValid} from '../../../models';
import {BehaviorSubject, combineLatest, combineLatestWith, lastValueFrom, Observable, of, ReplaySubject} from 'rxjs';
import {Action, select, Store} from '@ngrx/store';
import {ActivatedRoute, ParamMap, Router, UrlTree} from '@angular/router';
import {EntityXsStore, EntityXsStoreSaveSuccessPayload} from '@nexnox-web/core-store';
import {
  ActionButton,
  CorePortalActionBarService,
  CorePortalCurrentModuleService,
  CorePortalCurrentTenantService,
  CorePortalPageTitleService,
  ErrorService
} from '../../../../../services';
import {headerStore} from '../../../../../store/core/gui/header';
import {Actions, ofType} from '@ngrx/effects';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {distinctUntilChanged, filter, last, map, mergeMap, pairwise, scan, startWith, take} from 'rxjs/operators';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {Location} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import {flatten, isString, isUndefined} from 'lodash';
import {CorePortalTenantRouter} from '../../../../../router-overrides';
import {CorePortalEntityCreatePresetService} from "../../../services";

@Directive()
export abstract class CorePortalEntityDetailBaseComponent<E, M = E> extends UnsubscribeHelper implements OnInit, UnsavedChanges, OnDestroy {
  /**
   * Children being checked for being valid.
   */
  @ViewChildren('modelComponent') public modelComponents: QueryList<ModelValid>;

  /**
   * Children checked for being pristine.
   */
  @ViewChildren('pristineComponent') public pristineComponents: QueryList<ModelPristine>;

  public entity$: Observable<E>;
  public model$: Observable<M>;
  public loading$: Observable<boolean>;
  public loaded$: Observable<boolean>;
  public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public stereotypes$: Observable<StereotypeDto[]>;

  public isModelValid$: Observable<boolean>;
  public isOwnModelValid$: Observable<boolean>;

  public id: number | string;
  public parentIds: Array<number | string>;
  public passedIds: Array<number | string>;
  public readonly$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public abstract title: string;

  public route: ActivatedRoute;
  public isArchived$: Observable<boolean>;

  public monitors: MonitorSet<M>;
  public bypassMonitorsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /**
   *  Workaround prevents unsaved changes dialog from getting called twice
   */
  public isDeactivateUnsavedChangesModal = false;

  protected abstract idParam: string;
  protected displayKey: string;

  /**
   * If enabled, will clear the store when this component is destroyed.
   *
   * @default true
   */
  protected canClear = true;

  /**
   * If enabled, will reset the model when this component is destroyed and {@link canClear} is disabled.
   *
   * @default true
   */
  protected canReset = true;

  /**
   * Whether this entity is deletable or not.
   *
   * @default true
   */
  protected canDelete = true;

  /**
   * If enabled, the {@link subscribeToParams} method will use the param map and pass it to {@link resolveId} and {@link resolveParentIds}.
   * Disabling this behaviour may be useful if the id and parent ids are hardcoded or don't need the param map to be loaded.
   *
   * @default true
   */
  protected useParamMap = true;

  /**
   * If enabled will fetch the entity on load.
   *
   * @default true
   */
  protected initialGet = true;

  protected store: Store<any>;
  protected modalService: CoreSharedModalService;
  protected actionBarService: CorePortalActionBarService;
  protected actions$: Actions;
  protected router: Router;
  protected tenantRouter: CorePortalTenantRouter;
  protected currentTenantService: CorePortalCurrentTenantService;
  protected currentModuleService: CorePortalCurrentModuleService;
  protected environment: Environment;
  protected location: Location;
  protected errorService: ErrorService;
  protected pageTitleService: CorePortalPageTitleService;
  protected exportService: CoreSharedExportService;
  protected entityCreatePresetService: CorePortalEntityCreatePresetService;

  protected updateSubject: ReplaySubject<void> = new ReplaySubject<void>();

  protected constructor(
    protected injector: Injector,
    protected entityStore: EntityXsStore<E, M>
  ) {
    super();

    this.store = injector.get(Store) as Store<any>;
    this.route = injector.get(ActivatedRoute);
    this.modalService = injector.get(CoreSharedModalService);
    this.actionBarService = injector.get(CorePortalActionBarService);
    this.actions$ = injector.get(Actions);
    this.router = injector.get(Router);
    this.tenantRouter = injector.get(CorePortalTenantRouter);
    this.currentTenantService = injector.get(CorePortalCurrentTenantService);
    this.currentModuleService = injector.get(CorePortalCurrentModuleService);
    this.environment = injector.get(CORE_SHARED_ENVIRONMENT);
    this.location = injector.get(Location);
    this.errorService = injector.get(ErrorService);
    this.pageTitleService = injector.get(CorePortalPageTitleService);
    this.exportService = injector.get(CoreSharedExportService);
    this.entityCreatePresetService = injector.get(CorePortalEntityCreatePresetService);

    this.entity$ = this.store.pipe(select(entityStore.selectors.selectEntity));
    this.model$ = this.store.pipe(select(entityStore.selectors.selectModel));

    // Store loading combined with export loading and loading subject
    this.loading$ = this.store.pipe(select(entityStore.selectors.selectLoading)).pipe(
      combineLatestWith(this.exportService.getLoading(), this.loadingSubject),
      map(([a, b, c]) => a || b || c)
    );

    this.loaded$ = this.store.pipe(select(entityStore.selectors.selectLoaded));
    this.stereotypes$ = this.store.pipe(select(entityStore.selectors.selectStereotypes));

    this.isModelValid$ = this.updateSubject.asObservable().pipe(
      mergeMap(() => this.isModelValid()),
      startWith(true)
    );
    this.isOwnModelValid$ = this.updateSubject.asObservable().pipe(
      mergeMap(() => this.isOwnModelValid()),
      startWith(true)
    );

    this.monitors = new MonitorSet<M>(injector, this.model$, this.loadingSubject, this.bypassMonitorsSubject);
  }

  public ngOnInit(): void {
    this.ngOnInitSimple();
    this.subscribeToParams();

    this.store.dispatch(headerStore.actions.setTitle({title: this.title}));
    this.pageTitleService.setPageTitle(this.title);

    this.isArchived$ = this.model$.pipe(filter(model => Boolean(model ? model[this.idParam] : false)), map(model => Boolean((model as any)?.isArchived)));
    this.getActionButtons().then(actionButtons => this.actionBarService.setActions(actionButtons));
  }

  public ngOnInitSimple(): void {
    this.subscribeToActions();
    this.subscribeToFragment();
    this.initMonitors();

    this.subscribe(this.model$.pipe(
      map(model => model && this.displayKey ? model[this.displayKey] : undefined),
      distinctUntilChanged()
    ), entity => this.pageTitleService.setPageTitle(this.title, entity));
  }

  @HostListener('window:beforeunload', ['$event'])
  public async onBeforeWindowUnload(event: BeforeUnloadEvent): Promise<void> {
    if (!this.environment?.production) {
      return;
    }

    const hasUnsavedChanges = await lastValueFrom(this.hasUnsavedChanges().pipe(take(1)));
    if (hasUnsavedChanges) {
      event.returnValue = true;
    }
  }

  public hasUnsavedChanges(): Observable<boolean> {
    return this.hasModelChanged().pipe(
      mergeMap(hasChanged => this.readonly$.pipe(
        take(1),
        map(readonly => hasChanged && !readonly)
      ))
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.canClear) {
      this.store.dispatch(this.entityStore.actions.clear());
    } else if (this.canReset) {
      this.store.dispatch(this.entityStore.actions.modelReset());
    }

    this.actionBarService.reset();
  }

  public onNavigateToTab(commands: any[]): void {
    if (this.id) {
      this.router.navigate(commands, {relativeTo: this.route, preserveFragment: true});
    }
  }

  public onModelChange(model: M): void {
    this.store.dispatch(this.entityStore.actions.modelUpdate({model}));
  }

  public onError(error: string | HttpErrorResponse | Error, action: Action): void {
    if (action.type === this.entityStore.actions.get.type) {
      this.errorService.navigateToError(error);
    }
  }

  public isModelValid(): Observable<boolean> {
    return ViewChildrenHelper.untilViewChildrenLoaded$(this.modelComponents).pipe(
      map(modelComponent => modelComponent.isModelValid()),
      scan((all, current) => [...all, current], []),
      last(),
      map(all => all.every(isValid => Boolean(isValid))),
      mergeMap(valid => this.readonly$.asObservable().pipe(
        map(readonly => valid || readonly)
      ))
    );
  }

  public isOwnModelValid(): Observable<boolean> {
    return ViewChildrenHelper.untilViewChildrenLoaded$(this.modelComponents).pipe(
      take(1),
      map(modelComponent => modelComponent.isModelValid()),
      mergeMap(valid => this.readonly$.asObservable().pipe(
        map(readonly => valid || readonly)
      ))
    );
  }

  protected onCreateNative(returnPath?: Array<any> | UrlTree): void {
    if (returnPath) {
      this.isDeactivateUnsavedChangesModal = true;
    }
    this.store.dispatch(this.entityStore.actions.createOneNative({parentIds: this.parentIds, returnPath}));
  }

  protected getId(paramMap: ParamMap): number | string | Promise<number | string> {
    if (!paramMap) {
      return null;
    }

    return paramMap.get(this.idParam);
  }

  protected getParentIds(paramMap: ParamMap): Array<number | string> | Promise<Array<number | string>> {
    paramMap = this.route.snapshot.paramMap ? this.route.snapshot.paramMap : paramMap;
    if (!paramMap) {
      return [];
    }
    // remove own id key and return parent ids from route
    const parentIds = []
    const keys = paramMap.keys;
    if (keys.indexOf(this.idParam) !== -1) keys.splice(keys.indexOf(this.idParam), 1);
    for (let i = 0; i < keys.length; i++) {
      parentIds.push(paramMap.get(keys[i]));
    }
    return parentIds;
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return [];
  }

  /* istanbul ignore next */
  protected getDefaultActionButtons(
    editKey: string,
    saveKey: string,
    deleteKey: string,
    deleteDescriptionKey: string,
    savePermission: AppPermissions,
    deletePermission?: AppPermissions,
    returnPath?: any[],
    module?: string,
    moreButtons: ActionButton[] = [],
    exportType?: ExportType
  ): ActionButton[] {
    const dropdownButtons: ActionButton[] = !isUndefined(deletePermission) ? [{
      label: deleteKey,
      type: 'button',
      permission: deletePermission,
      isLoading: () => this.loading$,
      callback: (_, button: ActionButton) => {
        let adjustedPath;

        if (returnPath) {
          if (returnPath?.length) {
            if (isString(returnPath[0]?.toString()) && returnPath[0].toString().startsWith('/')) {
              returnPath[0] = returnPath[0].toString().substring(1);
            }

            for (let i = 0; i < returnPath.length; i++) {
              if (isString(returnPath[i]?.toString()) && returnPath[i].toString().includes('/')) {
                returnPath[i] = returnPath[i].toString().split('/');
              }
            }

            returnPath = flatten(returnPath).filter(command => Boolean(command));
          }

          adjustedPath = ['/tenant',
            this.currentTenantService.getTenantDomain(),
            !isUndefined(module) ? module : this.currentModuleService.getModule(),
            ...returnPath
          ];
        }

        this.onDeleteAction(button, deleteDescriptionKey, returnPath ? adjustedPath : this.getReturnToListPath());
      },
      shouldShow: () => of(this.canDelete)
    }] : [];

    const exportButton: ActionButton[] = !isUndefined(exportType) ? [
      {
        label: 'core-shared.shared.table.tooltip.export-object',
        type: 'button',
        class: 'btn-outline-primary',
        permission: AppPermissions.ExportEntity,
        isLoading: () => this.loading$,
        callback: () => this.exportService.export(exportType, +this.id)
      }
    ] : [];

    return [
      {
        label: 'core-portal.core.general.cancel',
        type: 'button',
        class: 'btn-outline-secondary',
        icon: faTimesCircle,
        shouldShow: () => this.readonly$.asObservable().pipe(
          map(readonly => !readonly)
        ),
        isLoading: () => this.loading$,
        callback: () => this.onCancelAction().catch(() => null)
      },
      {
        label: editKey,
        type: 'button',
        class: 'btn-outline-primary',
        permission: savePermission,
        icon: faPencilAlt,
        shouldShow: () => this.readonly$.asObservable(),
        isLoading: () => this.loading$,
        callback: () => this.onEditAction()
      },
      {
        label: saveKey,
        type: 'button',
        class: 'btn-primary',
        permission: savePermission,
        icon: faSave,
        shouldShow: () => this.readonly$.asObservable().pipe(
          map(readonly => !readonly)
        ),
        isDisabled: () => this.shouldDisablePrimaryAction(),
        isLoading: () => this.loading$,
        callback: () => this.onSaveAction()
      },

      {
        label: '',
        type: 'dropdown',
        class: 'btn-outline-secondary',
        icon: faEllipsisV,
        tooltip: 'core-portal.core.general.more',
        alignRight: true,
        noArrow: true,
        buttons: of([
          ...moreButtons,
          ...dropdownButtons,
          ...exportButton
        ]),
        isLoading: () => this.loading$
      }
    ];
  }

  protected getReturnToListPath(): string[] {
    const goBackToList: string[] = this.router.routerState.snapshot.url.split('/');

    // Find #edit fragment and remove if found
    const editFragmentIndex = goBackToList.findIndex(element => element.includes("#edit"));
    if (editFragmentIndex > -1) {
      goBackToList[editFragmentIndex] = goBackToList[editFragmentIndex].replace('#edit', '');
    }

    // Pop tab routes without id  (list/23/tab)
    while (/^\d+$/.test(goBackToList.at(-1)) === false && goBackToList.length > 0) {
      goBackToList.pop();
    }
    // Pop id (list/23)
    goBackToList.pop();
    return goBackToList;
  }

  protected onEditAction(): void {
    window.location.hash = '#edit';
  }

  protected onSaveAction(returnPath?: any[] | UrlTree): void {
    this.isDeactivateUnsavedChangesModal = true;

    if (!this.areModelComponentsPristine()) {
      this.modalService.showConfirmationModal(
        'core-shared.shared.non-pristine-tabs.title',
        'core-shared.shared.non-pristine-tabs.text',
        'warning',
        'core-portal.core.general.yes'
      )
        .then(() => this.onSave(returnPath))
        .catch(() => null);
    } else {
      this.onSave(returnPath)
    }
  }

  protected onCancelAction(): Promise<void> {
    const cancel = (resolve): void => {
      window.location.hash = '';
      resolve();
    };

    const checkPristine = (resolve, reject): void => {
      if (!this.areModelComponentsPristine()) {
        this.modalService.showConfirmationModal(
          'core-shared.shared.non-pristine-tabs.title',
          'core-shared.shared.non-pristine-tabs.text',
          'warning',
          'core-portal.core.general.yes'
        )
          .then(() => cancel(resolve))
          .catch(() => reject());
      } else {
        cancel(resolve);
      }
    };

    const showModal = (resolve, reject): void => {
      this.modalService.showConfirmationModal(
        'core-shared.shared.unsaved-changes.title',
        'core-shared.shared.unsaved-changes.text',
        'warning',
        'core-portal.core.general.yes'
      )
        .then(() => {
          this.isDeactivateUnsavedChangesModal = true;
          checkPristine(resolve, reject);
        })
        .catch(() => reject());
    };

    return new Promise((resolve, reject) => {
      this.hasUnsavedChanges().pipe(
        take(1)
      ).subscribe(hasUnsavedChanged => hasUnsavedChanged ? showModal(resolve, reject) : checkPristine(resolve, reject));
    });
  }

  protected onDeleteAction(button: ActionButton, deleteDescriptionKey: string, returnPath?: any[]): void {
    this.modalService.showConfirmationModal(button.label, deleteDescriptionKey, 'error', button.label)
      .then(() => {
        this.store.dispatch(this.entityStore.actions.delete({
          id: this.id,
          parentIds: (this.parentIds ?? []).map(x => +x),
          returnPath: returnPath
        }));
      })
      .catch(() => null);
  }

  protected hasModelChanged(): Observable<boolean> {
    return this.store.pipe(select(this.entityStore.selectors.selectHasChanged));
  }

  protected shouldDisablePrimaryAction(): Observable<boolean> {
    return this.hasUnsavedChanges().pipe(
      mergeMap(hasChanged => this.isModelValid$.pipe(
        map(isValid => !isValid || !hasChanged)
      ))
    );
  }

  protected initMonitors(): void {
    this.subscribeToMonitorBypass();
    this.subscribe<M>(this.monitors.modifiedModel$, (modified) => this.onModelChange(modified))
  }

  protected subscribeToMonitorBypass(): void {
    // Bypass monitors on loading and readonly
    this.subscribe(
      combineLatest([this.readonly$.asObservable(), this.loading$, this.loaded$, this.hasModelChanged(), this.model$]).pipe(
        map(([readonly, loading, loaded, hasChanges, model]) => (!hasChanges && !readonly && !model[this.idParam]) || (!readonly && !loaded) || loading || readonly),
        distinctUntilChanged(),
      ), (bypass) => this.bypassMonitorsSubject.next(bypass)
    );
  }

  protected subscribeToActions(): void {
    this.subscribe(this.actions$.pipe(ofType(this.entityStore.actions.update)), () => {
      setTimeout(() => this.updateSubject.next());
    });

    this.subscribe(this.actions$.pipe(ofType(this.entityStore.actions.error)), ({
                                                                                  error,
                                                                                  action
                                                                                }) => this.onError(error, action));
    this.subscribe(this.actions$.pipe(ofType(this.entityStore.actions.saveSuccess)), payload => this.onSaveSuccess(payload));
  }

  protected subscribeToParams(): void {
    if (!this.useParamMap) {
      setTimeout(async () => {
        this.id = await this.resolveId(null);
        this.parentIds = await this.resolveParentIds(null);
        this.passedIds = this.parentIds;
        if (this.initialGet) this.getEntity(this.id, this.parentIds ?? []);
      });
      return;
    }

    this.subscribe(this.route.paramMap, async paramMap => {
      this.id = await this.resolveId(paramMap);
      this.parentIds = await this.resolveParentIds(paramMap);
      this.passedIds = [...this.parentIds, this.id];
      if (this.id && this.initialGet) {
        this.getEntity(this.id, this.parentIds ?? []);
      }
    });
  }

  protected subscribeToFragment(): void {
    this.subscribe(this.route.fragment.pipe(startWith(null), pairwise()), async ([prevFragment, currFragment]) => {

      const hasUnsavedChanges = await this.hasUnsavedChanges().pipe(take(1)).toPromise()

      if ((prevFragment === 'create' || prevFragment === 'edit') && hasUnsavedChanges && !(await this.canDeactivate())) {
        window.location.hash = prevFragment;
        return;
      }

      switch (currFragment) {
        case 'edit':
          this.setEditMode();
          break;
        case null:
          this.setViewMode();
          break;
        default:
          window.location.hash = '';
          this.setViewMode();
          break;
      }

      this.isDeactivateUnsavedChangesModal = false;
    });
  }

  protected setEditMode(): void {
    this.readonly$.next(false);
  }

  protected setViewMode(): void {
    this.bypassMonitorsSubject.next(true);
    this.readonly$.next(true);
    this.store.dispatch(this.entityStore.actions.modelReset());
  }

  protected getEntity(id: number | string, parentIds: Array<number | string>): void {
    this.bypassMonitorsSubject.next(true);
    this.store.dispatch(this.entityStore.actions.get({id, parentIds}));
  }

  protected onSave(returnPath?: any[] | UrlTree): void {
    this.store.dispatch(this.entityStore.actions.save({id: this.id, parentIds: this.parentIds, returnPath}))
  }

  protected onSaveSuccess(payload: EntityXsStoreSaveSuccessPayload<E, M>): void {
    if (!payload.doNotEmitEvents) {
      window.location.hash = '';
    }
  }

  protected areModelComponentsPristine(): boolean {
    return this.getPristineModelComponents().every(x => x.isPristine());
  }

  protected async canDeactivate(): Promise<boolean> {
    if (!this.isDeactivateUnsavedChangesModal) {
      return await this.modalService.promptUnsavedChangesModal();
    } else {
      return true;
    }
  }

  private resolveId(paramMap: ParamMap): Promise<number | string> {
    const gotId = this.getId(paramMap);

    return new Promise<number | string>(async resolve => {
      resolve(gotId instanceof Promise ? await gotId : gotId);
    });
  }

  private resolveParentIds(paramMap: ParamMap): Promise<Array<number | string>> {
    const gotParentIds = this.getParentIds(paramMap);

    return new Promise<Array<number | string>>(async resolve => {
      resolve(gotParentIds instanceof Promise ? await gotParentIds : gotParentIds);
    });
  }

  private getPristineModelComponents(): ModelPristine[] {
    return this.modelComponents
      .toArray()
      .filter(x => !isUndefined(x.isOwnModelPristine))
      .map(x => ({isPristine: () => x.isOwnModelPristine()} as ModelPristine))
      .concat(this.pristineComponents.toArray());
  }
}
