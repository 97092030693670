import {
  locationAddressDetailStore,
  LocationAddressDetailStoreState,
  locationAddressListStore,
  LocationAddressListStoreState,
  locationContactListStore,
  LocationContactListStoreState,
  locationContractListStore,
  LocationContractListStoreState,
  locationDetailStore,
  LocationDetailStoreState,
  locationListStore,
  LocationListStoreState,
  locationLocationGroupListStore,
  LocationLocationGroupListStoreState,
  locationMissionListStore,
  LocationMissionListStoreState,
  locationResourceListStore,
  LocationResourceListStoreState,
  locationTaskActiveListStore,
  LocationTaskActiveListStoreState,
  locationTaskArchiveListStore,
  LocationTaskArchiveListStoreState,
  locationTaskListStore,
  LocationTaskListStoreState,
  locationTicketListStore,
  LocationTicketListStoreState,
  locationAuditListStore,
  LocationAuditListStoreState
} from './stores';

export interface LocationsState {
  locationList: LocationListStoreState;
  locationDetail: LocationDetailStoreState;
  locationAuditList: LocationAuditListStoreState;

  locationAddressList: LocationAddressListStoreState;
  locationResourceList: LocationResourceListStoreState;
  locationMissionList: LocationMissionListStoreState;
  locationTaskList: LocationTaskListStoreState;
  locationContractList: LocationContractListStoreState;
  locationContactList: LocationContactListStoreState;
  locationTicketList: LocationTicketListStoreState;
  locationTaskArchiveList: LocationTaskArchiveListStoreState;
  locationTaskActiveList: LocationTaskActiveListStoreState;
  locationLocationGroupList: LocationLocationGroupListStoreState;
  locationAddressDetail: LocationAddressDetailStoreState;
}

export const getInitialLocationsState = (): LocationsState => ({
  locationList: locationListStore.getInitialState(),
  locationDetail: locationDetailStore.getInitialState(),
  locationAuditList: locationAuditListStore.getInitialState(),

  locationAddressList: locationAddressListStore.getInitialState(),
  locationResourceList: locationResourceListStore.getInitialState(),
  locationMissionList: locationMissionListStore.getInitialState(),
  locationTaskList: locationTaskListStore.getInitialState(),
  locationContractList: locationContractListStore.getInitialState(),
  locationContactList: locationContactListStore.getInitialState(),
  locationTicketList: locationTicketListStore.getInitialState(),
  locationTaskArchiveList: locationTaskArchiveListStore.getInitialState(),
  locationTaskActiveList: locationTaskActiveListStore.getInitialState(),
  locationLocationGroupList: locationLocationGroupListStore.getInitialState(),
  locationAddressDetail: locationAddressDetailStore.getInitialState()
});
