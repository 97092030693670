import { CoreSharedBreadcrumb, CoreSharedBreadcrumbsResolver } from '@nexnox-web/core-shared';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { resourceDetailStore, resourceEntitiesStore } from '../../store/stores';
import { isEmpty } from 'lodash';
import { CorePortalTenantRouter } from '@nexnox-web/core-portal';

@Injectable({
  providedIn: 'root'
})
export class ResourceBreadcrumbsResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private store: Store<any>,
    private tenantRouter: CorePortalTenantRouter
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    const path = super.getFullPath(route);

    return this.store.pipe(
      select(resourceDetailStore.selectors.selectEntity),
      mergeMap(selectedEntity => {
        if (!selectedEntity || isEmpty(selectedEntity)) {
          return of([]);
        }

        const ids = selectedEntity.path.map(part => part.id);

        return this.store.pipe(
          select(resourceEntitiesStore.selectors.selectFiltered(ids)),
          filter(entities => entities.length === ids.length),
          map(entities => entities.map(entity => ({
            text: entity.name,
            path: this.tenantRouter.createUrlTree(['resources', entity.resourceId]).toString()
          }))),
          take(1),
          catchError(() => of([]))
        );
      }),
      catchError(() => of([]))
    );
  }
}
