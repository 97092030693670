import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {TemplateUsageTypes} from "@nexnox-web/tech-portal/features/templates/src/lib/models";
import {DocumentTemplateDetailComponent} from "@nexnox-web/tech-portal/features/templates/src/lib/containers";

@Component({
  selector: 'nexnox-web-ticket-document-template-detail-wrapper',
  templateUrl: '../../containers/document-templates/document-template-detail/document-template-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketDocumentTemplateDetailWrapperComponent extends DocumentTemplateDetailComponent {

  public templateUsageType = TemplateUsageTypes.Ticket

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
