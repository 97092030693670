import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResourcesComponent } from './containers';
import { UnsavedChangesGuard } from '@nexnox-web/core-shared';
import { ResourceBreadcrumbsResolver } from './resolvers';

const CORE_PORTAL_RESOURCES_ROUTES: Routes = [
  {
    path: '**',
    component: ResourcesComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: ResourceBreadcrumbsResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_RESOURCES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ResourcesRoutingModule {
}
