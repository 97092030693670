import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyActionWrapperTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalPermissionService,
  CorePortalTenantRouter
} from '@nexnox-web/core-portal';
import {AppPermissions, StateDto, TicketIssueDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  CorePortalTicketSettingsStateService
} from "@nexnox-web/core-portal/features/settings/features/stereotypes/src/lib/store/services";
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import {TechPortalFeatureVirtualResourceService} from "../../../../../resources";
import {TechPortalFeatureTicketSettingsPriorityService} from "../../../../../ticket-settings/features/priority-sets";

@Component({
  selector: 'nexnox-web-issues-ticket-issue-edit',
  templateUrl: './ticket-issue-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketIssueEditComponent extends CorePortalEntityEditBaseComponent<TicketIssueDto> {

  constructor(
    protected injector: Injector,
    private ticketStateService: CorePortalTicketSettingsStateService,
    private tenantRouter: CorePortalTenantRouter,
    private permissionService: CorePortalPermissionService,
    private resourceService: TechPortalFeatureVirtualResourceService,
    private priorityService: TechPortalFeatureTicketSettingsPriorityService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC,
            link: (state: StateDto) => state?.stateId ? ['states', state.stateId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      // Ticket fields
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 field-group-mb-0 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.ticket',
            title: true,
            labelClass: 'pl-2'
          } as CorePortalFormlyTranslatedTyping
        },
        fieldGroup: [
          {
            key: 'ticket.title',
            type: 'input',
            wrappers: ['core-portal-translated', 'core-portal-actions', 'core-portal-readonly'],
            className: 'col-md-6',
            templateOptions: {
              type: 'text',
              corePortalTranslated: {
                label: 'core-shared.shared.fields.name',
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
              corePortalActionWrapper: {
                actions: [
                  {
                    icon: faExternalLinkAlt,
                    tooltip: 'core-shared.shared.actions.view-mode',
                    onClick: () => this.tenantRouter.navigate(['tickets', this.model?.ticket?.ticketId], {module: 'communication'}).then(),
                    hidden: (model) => !Boolean(model?.ticket),
                    permissions$: [this.permissionService.hasPermission$(AppPermissions.ReadTicket)]
                  }
                ]
              } as CorePortalFormlyActionWrapperTyping,
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            },
          },
          {
            key: 'ticket.currentState',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.settings.fields.contract-settings-ticket-state',
                validationMessages: {
                  required: 'core-portal.core.validation.required'
                }
              },
              corePortalReadonly: {
                type: 5,
                displayKey: 'name',
                link: (state: StateDto) => state?.stateId ? ['states', state.stateId] : null,
                module: 'settings'
              },
              entityService: this.ticketStateService,
              idKey: 'stateId',
              displayKey: 'name',
              wholeObject: true,
              skipGetOne: true,
              clearable: false,
              link: (state: StateDto) => state?.stateId ? ['states', state.stateId] : null,
              module: 'settings'
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          },
          {
            key: 'resource',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            defaultValue: [],
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.settings.fields.ticket-settings.processes.resource'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'name'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.resourceService,
              idKey: 'resourceId',
              displayKey: 'name',
              wholeObject: true,
              multiple: false
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          },
          {
            key: 'priority',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            defaultValue: [],
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.priority'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'name'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.priorityService,
              idKey: 'priorityId',
              displayKey: 'name',
              wholeObject: true,
              skipGetOne: true
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          }
        ]
      },
      ...this.getStereotypeFields(true)
    ];
  }
}
