import {NgModule} from '@angular/core';
import {
  CorePortalFeatureMasterDataLocationListComponent,
  LocationAddressDetailStandaloneComponent,
  LocationAddressListComponent,
  LocationContactListComponent,
  LocationContractListComponent,
  LocationDetailComponent,
  LocationLocationGroupListComponent,
  LocationMissionListComponent,
  LocationResourceListComponent,
  LocationTaskActiveListComponent,
  LocationTaskArchiveListComponent,
  LocationTaskListComponent,
  LocationTasksPlannedTasksComponent,
  LocationTicketListComponent,
  LocationAuditListComponent
} from './containers';
import {
  CorePortalFeatureLocationEditComponent,
  CorePortalFeatureMasterDataLocationLocationNumberComponent
} from './components';
import {CorePortalAttachmentsModule, CorePortalEntityModule, CorePortalModule} from '@nexnox-web/core-portal';
import {CoreSharedFileService, CoreSharedModule} from '@nexnox-web/core-shared';
import {
  CorePortalFeatureMasterDataAddressesModule
} from '@nexnox-web/core-portal/features/master-data/features/addresses';
import {locationsServices} from './store';
import {CorePortalFeatureMasterDataLocationDatatableSearchModalComponent} from './modals';
import {CorePortalFeatureResourcesModule} from '@nexnox-web/core-portal/features/resources';
import {
  CorePortalFeatureMasterDataHolidaysModule
} from '@nexnox-web/core-portal/features/master-data/features/holidays/src/lib/holidays.module';
import {FilledformService, OrgaPortalFeatureTaskService} from "@nexnox-web/orga-portal/features/tasks/src/lib/store";
import {LocationAddressBreadcrumbsResolver} from "./resolvers";
import {
  CreateAreaAssignmentSidebarComponent,
  CreateResourcesByDefinitionSidebarComponent,
  CreateTicketsByDefinitionSidebarComponent,
  LocationStatusSidebarComponent
} from "./sidebars";
import {
  CorePortalSettingsStereotypeService,
  CorePortalTicketSettingsPriorityService,
  CorePortalTicketSettingsStateService
} from "@nexnox-web/core-portal/features/settings/features/stereotypes/src/lib/store/services";
import {CorePortalFeatureAuditModule} from "libs/core-portal/features/audit/src/lib/audit.module";

@NgModule({
  declarations: [
    CorePortalFeatureMasterDataLocationListComponent,
    LocationDetailComponent,
    LocationAddressListComponent,
    LocationResourceListComponent,
    LocationMissionListComponent,
    LocationTaskListComponent,
    LocationContractListComponent,
    LocationContactListComponent,
    LocationTicketListComponent,
    CorePortalFeatureMasterDataLocationLocationNumberComponent,
    CorePortalFeatureMasterDataLocationDatatableSearchModalComponent,
    CorePortalFeatureLocationEditComponent,
    LocationTaskActiveListComponent,
    LocationTaskArchiveListComponent,
    LocationTasksPlannedTasksComponent,
    LocationLocationGroupListComponent,
    LocationAddressDetailStandaloneComponent,

    CreateAreaAssignmentSidebarComponent,
    CreateResourcesByDefinitionSidebarComponent,
    CreateTicketsByDefinitionSidebarComponent,
    LocationStatusSidebarComponent,
    LocationAuditListComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CorePortalModule,
    CorePortalFeatureMasterDataAddressesModule.forFeature(),
    CorePortalFeatureMasterDataHolidaysModule.forFeature(),
    CorePortalFeatureResourcesModule.forFeature(),
    CorePortalAttachmentsModule,
    CorePortalFeatureAuditModule
  ],
  providers: [
    ...locationsServices,
    CoreSharedFileService,
    FilledformService,
    OrgaPortalFeatureTaskService,
    LocationAddressBreadcrumbsResolver,
    CorePortalTicketSettingsPriorityService,
    CorePortalTicketSettingsStateService,
    CorePortalSettingsStereotypeService
  ],
  exports: [
    CorePortalFeatureMasterDataLocationLocationNumberComponent,
    CorePortalFeatureMasterDataLocationDatatableSearchModalComponent,
    CorePortalFeatureLocationEditComponent
  ]
})
export class LocationsDeclarationsModule {
}
