import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {ResizeEvent} from 'angular-resizable-element';
import {UnsavedChanges, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {Store} from '@ngrx/store';
import {CorePortalTenantLocation, headerStore} from '@nexnox-web/core-portal';
import {NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map, pairwise, startWith} from 'rxjs/operators';
import {isNull, isUndefined} from 'lodash';
import {resourcesGuiStore} from '../../store/stores';
import {ResourceListComponent} from '../resource-list/resource-list.component';
import {Observable, of} from 'rxjs';
import {ResourceDetailComponent} from '../resource-detail/resource-detail.component';

@Component({
	selector: 'nexnox-web-resources',
	templateUrl: './resources.component.html',
	styleUrls: ['./resources.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesComponent extends UnsubscribeHelper implements OnInit, UnsavedChanges {
	@ViewChild('resourceDetailComponent') public resourceDetailComponent: ResourceDetailComponent;
	@ViewChild('resourceListComponent') public resourceListComponent: ResourceListComponent;

	public resourceListWidth = 350;

	constructor(
		private store: Store<any>,
		private router: Router,
		private tenantLocation: CorePortalTenantLocation
	) {
		super();
	}

	public ngOnInit(): void {
		this.store.dispatch(headerStore.actions.setTitle({title: 'resources.title'}));

		const resourceId$ = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map((event: NavigationEnd) => event.url),
			startWith(this.router.url),
			map(url => {
				let urlParts = url.split('#')[0].split('/');
				const startIndex = urlParts.findIndex(x => x === 'resources');
				urlParts = urlParts.slice(startIndex, urlParts.length);

				const id = parseInt(urlParts[urlParts.length - 1], 10);

				if (urlParts.length !== 2 || isNaN(id) || !isFinite(id)) {
					this.tenantLocation.replaceState('/resources', 'inventory');
					return null;
				}

				return id;
			}),
			startWith(undefined),
			distinctUntilChanged(),
			pairwise()
		);

		this.subscribe(resourceId$.pipe(
			filter(([first, second]) => {
				if (isUndefined(first) || isNull(second)) {
					return true;
				}

				return this.resourceListComponent?.selectedItem?.id !== second;
			}),
			map(([first, second]) => second)
		), resourceId => {
			this.resourceDetailComponent?.bypassMonitorsSubject?.next(true);
			setTimeout(() => this.store.dispatch(resourcesGuiStore.actions.selectById({resourceId: resourceId ?? null})));
		});
	}

	public onResizing(event: ResizeEvent): void {
		this.resourceListWidth = event.rectangle.width;
	}

	public hasUnsavedChanges(): Observable<boolean> {
		if (!this.resourceDetailComponent) {
			return of(false);
		}

		return this.resourceDetailComponent.hasUnsavedChanges();
	}
}
