<div class="d-flex w-100 h-100 align-items-start">
  <div class="d-md-flex d-none flex-shrink-0 mr-4 resource-list h-100"
       [style.width.px]="resourceListWidth"
       mwlResizable
       (resizing)="onResizing($event)">
    <nexnox-web-resources-resource-list #resourceListComponent
                                        [parent]="this"
                                        class="w-100">
    </nexnox-web-resources-resource-list>

    <div class="list-resize-handle" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
  </div>

  <div class="d-flex w-100 h-100 resource-detail">
    <nexnox-web-resources-resource-detail #resourceDetailComponent
                                          class="w-100">
    </nexnox-web-resources-resource-detail>
  </div>
</div>
