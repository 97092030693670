import {ChangeDetectionStrategy, Component, Injector, Input} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {BillingType, ProductEditions, ProductKind, ProductSubscriptionDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {nonClassicTenantSubscriptionTypeEnumOptions} from '../../models';

export const tenantSubscriptionKindOptions = [
  {label: 'core-portal.service.tenants.kind-types.0', value: ProductKind.Internal},
  {label: 'core-portal.service.tenants.kind-types.1', value: ProductKind.Demo},
  {label: 'core-portal.service.tenants.kind-types.2', value: ProductKind.Customer}
];

export const tenantSubscriptionBillingOptions = [
  {label: 'core-portal.service.tenants.billing-types.0', value: BillingType.None},
  {label: 'core-portal.service.tenants.billing-types.1', value: BillingType.PerLocation},
  {label: 'core-portal.service.tenants.billing-types.2', value: BillingType.PerUser},
  {label: 'core-portal.service.tenants.billing-types.3', value: BillingType.PerGdprRule},
  {label: 'core-portal.service.tenants.billing-types.4', value: BillingType.PerNotificationRule},
  {label: 'core-portal.service.tenants.billing-types.5', value: BillingType.PerSubscription}
];

@Component({
  selector: 'nexnox-web-service-tenants-tenant-subscription-edit',
  templateUrl: './tenant-subscription-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantSubscriptionEditComponent extends CorePortalEntityEditBaseComponent<ProductSubscriptionDto> {

  @Input() public tenantId: number | string;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {key: 'tenantId', defaultValue: this.tenantId},
      {
        key: 'edition',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        defaultValue: ProductEditions.OrgaModule,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.subscription',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalNgSelect: {
            items: nonClassicTenantSubscriptionTypeEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        }
      },
      {
        key: 'kind',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: ProductKind.Internal,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.kind',
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: tenantSubscriptionKindOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: tenantSubscriptionKindOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping,
          change: () => this.form.get('billingType').setValue(BillingType.None),
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.required': () => !this.readonly
        },
      },
      {
        key: 'billingType',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: BillingType.None,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.billing-type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: tenantSubscriptionBillingOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: tenantSubscriptionBillingOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.model?.kind !== ProductKind.Customer,
          'templateOptions.readonly': () => this.model?.kind !== ProductKind.Customer,
          'templateOptions.required': () => !this.readonly && this.model?.kind === ProductKind.Customer,
        },
      },
      {
        key: 'expiresIn',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.expires-in',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.required': () => this.form.get('kind').value === ProductKind.Demo
        },
      }
    ];
  }
}
