import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { CoreSharedModalBaseComponent } from '@nexnox-web/core-shared';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'nexnox-web-settings-stereotypes-create-custom-property-set-modal',
  templateUrl: './create-custom-property-set-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateCustomPropertySetModalComponent extends CoreSharedModalBaseComponent implements OnInit {
  public form: FormGroup;
  public fields: FormlyFieldConfig[];
  public model: { name: string };

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model = {} as any;

    this.subscribe(this.form.valueChanges, () => this.form.valid ? this.enableConfirmButton() : this.disableConfirmButton());
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text',
          required: true
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected onConfirmButtonAvailable(): void {
    super.onConfirmButtonAvailable();
    this.disableConfirmButton();
  }

  /* istanbul ignore next */
  protected onPreConfirm(): any {
    return this.model;
  }
}
