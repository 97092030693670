<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        displayKey="title"
                        [stereotyped]="false">
</nexnox-web-entity-edit>
<nexnox-web-knowledge-paths-articles-edit #articlesComponent
                                          [loading]="loading"
                                          [readonly]="readonly"
                                          [actions]="createArticleActions()"
                                          [articlesSubject]="articlesSubject"
                                          (articlesChange)="onArticlesChange($event)">

</nexnox-web-knowledge-paths-articles-edit>

<nexnox-web-resource-article-preview-sidebar #articlePreviewSidebar>
</nexnox-web-resource-article-preview-sidebar>

