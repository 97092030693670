import {Injectable, Injector} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {authStore} from './auth.store';
import {
  CoreSharedApplicationUuidService,
  CoreSharedLocalStorageService,
  LocalStorageKeys,
  SettingType
} from '@nexnox-web/core-shared';
import {Action, select} from '@ngrx/store';
import {of, throwError} from 'rxjs';
import {AuthXsStoreActions} from './auth-xs-store.actions';
import {CorePortalPermissionService} from '../../../services/permission/permission.service';
import {CorePortalAuthService} from '../../../services/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthXsStoreSelectors} from './auth-xs-store.selectors';
import {Router} from '@angular/router';
import {generalTenantSettingsStore} from '../general-tenant-settings';
import {HttpErrorResponse} from '@angular/common/http';
import {CorePortalTenantLocalStorageService} from '../../../services/local-storage/local-storage.service';
import {packagesStore} from '../packages';
import {BaseXsStoreErrorPayload} from '@nexnox-web/core-store';
import {AuthXsStoreChangePasswordSuccessPayload} from './auth-xs-store.payloads';

@Injectable()
export class AuthStoreEffects extends authStore.effects {
  public login$: any;
  public loginWithRefresh$: any;
  public changeTenant$: any;
  public loginSuccess$: any;

  public changePassword$: any;
  public changePasswordSuccess$: any;

  public resetPassword$: any;
  public resetPasswordSuccess$: any;

  public forgotPassword$: any;
  public forgotPasswordSuccess$: any;

  public logout$: any;

  protected actions: AuthXsStoreActions;
  protected selectors: AuthXsStoreSelectors;

  constructor(
    protected injector: Injector,
    private authService: CorePortalAuthService,
    private permissionService: CorePortalPermissionService,
    private applicationUuidService: CoreSharedApplicationUuidService,
    private tenantLocalStorage: CorePortalTenantLocalStorageService,
    private localStorage: CoreSharedLocalStorageService,
    private translate: TranslateService,
    private router: Router
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.login$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.login),
      exhaustMap(({email, passwordBase64}) => this.authService.login(email, passwordBase64).pipe(
        withLatestFrom(this.store.pipe(select(packagesStore.selectors.selectPackages))),
        mergeMap(([response, packages]) => {
          if (packages.length) {
            return of(response);
          }

          this.store.dispatch(packagesStore.actions.get());
          return this.permissionService.selectPackagesOrTimeout().pipe(
            map(() => response),
            catchError(error => throwError(error))
          );
        }),
        exhaustMap(({refreshToken: allRefreshToken, info: allInfo}) => {
          const activeTenant = this.authService.getActiveTenantFromInfo(allInfo);
          this.tenantLocalStorage.set(LocalStorageKeys.ACTIVE_LANGUAGE, allInfo.userCulture);
          this.translate.use(allInfo.userCulture);

          return this.authService.refreshLoginV3(allRefreshToken, activeTenant?.tenantId).pipe(
            exhaustMap(({
                          token,
                          refreshToken,
                          restrictedRoleIds,
                          info
                        }) => this.permissionService.checkAndMergeTokenPermissions(token).pipe(
              map(permissions => this.actions.loginSuccess(
                token,
                refreshToken,
                restrictedRoleIds,
                permissions,
                info,
                activeTenant
              )),
              catchError(error => throwError(error))
            )),
            catchError(error => throwError(error))
          );
        }),
        catchError(error => of(this.actions.error({error, action: this.actions.login})))
      ))
    ));

    this.loginWithRefresh$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.loginWithRefresh),
      exhaustMap(({
                    accessToken,
                    refreshToken,
                    restrictedRoleIds,
                    info,
                    activeTenant,
                    navigate
                  }) => this.permissionService.checkAndMergeTokenPermissions(accessToken).pipe(
        map(permissions => {
          return this.actions.loginSuccess(accessToken, refreshToken, restrictedRoleIds, permissions, info, activeTenant, navigate);
        }),
        catchError(error => of(this.actions.error({error, action: this.actions.loginWithRefresh})))
      ))
    ));

    this.changeTenant$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changeTenant),
      withLatestFrom(this.store.pipe(select(this.selectors.selectRefreshToken))),
      exhaustMap(([{tenantId}, refreshToken]) => this.authService.refreshLoginV3(refreshToken, tenantId).pipe(
        exhaustMap(({
                      token,
                      restrictedRoleIds,
                      info
                    }) => this.permissionService.checkAndMergeTokenPermissions(token).pipe(
          map(permissions => {
            const activeTenant = info.tenants.find(tenant => tenant.tenantId === tenantId) ?? info.tenants[0];
            this.applicationUuidService.generateUuid();
            return this.actions.loginSuccess(token, refreshToken, restrictedRoleIds, permissions, info, activeTenant, true);
          }),
          catchError(error => throwError(error))
        )),
        catchError(error => of(this.actions.error({error, action: this.actions.changeTenant})))
      ))
    ));

    this.loginSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.loginSuccess),
      tap(async ({refreshToken, restrictedRoleIds, activeTenant, navigate}) => {
        this.authService.saveRefreshToken(refreshToken);
        this.authService.saveActiveTenant(activeTenant);

        if (activeTenant) {
          this.store.dispatch(generalTenantSettingsStore.actions.get({
            id: SettingType.General,
            parentIds: [activeTenant.tenantId]
          }));

          if (navigate) {
            await this.router.navigate(['/tenant', activeTenant.domain]);
          }
        } else {
          this.store.dispatch(generalTenantSettingsStore.actions.clear());

          if (navigate) {
            await this.router.navigate(['/']);
          }
        }
      })
    ), {dispatch: false});

    this.changePassword$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changePassword),
      exhaustMap(({email, passwordBase64, newPasswordBase64}) => this.authService.changePassword(
        email,
        passwordBase64,
        newPasswordBase64
      ).pipe(
        withLatestFrom(this.store.pipe(select(this.selectors.selectLoggedIn))),
        map(([_, loggedIn]) => this.actions.changePasswordSuccess({isLoggedIn: loggedIn})),
        catchError(error => of(this.actions.error({error, action: this.actions.changePassword})))
      ))
    ));

    this.changePasswordSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changePasswordSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.resetPassword$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.resetPassword),
      exhaustMap(({
                    email,
                    newPasswordBase64,
                    token
                  }) => this.authService.resetPassword(email, newPasswordBase64, token).pipe(
        map(() => this.actions.resetPasswordSuccess()),
        catchError(error => of(this.actions.error({error, action: this.actions.resetPassword})))
      ))
    ));

    this.resetPasswordSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.resetPasswordSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.forgotPassword$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.forgotPassword),
      exhaustMap(({email}) => this.authService.requestPasswordResetByEmail(email).pipe(
        map(() => this.actions.forgotPasswordSuccess()),
        catchError(error => of(this.actions.error({error, action: this.actions.forgotPassword})))
      ))
    ));

    this.forgotPasswordSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.forgotPasswordSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.logout$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.logout),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.changePasswordSuccess, action, payload => this.changePasswordSuccessCallback(payload));
    this.checkAction(this.actions.resetPasswordSuccess, action, () => this.resetPasswordSuccessCallback());
    this.checkAction(this.actions.forgotPasswordSuccess, action, () => this.forgotPasswordSuccessCallback());
    this.checkAction(this.actions.logout, action, ({navigate}) => this.loginCallback(navigate));
  }

  protected changePasswordSuccessCallback(payload: AuthXsStoreChangePasswordSuccessPayload): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.user.change-password.toast.success');
    this.router.navigate(payload.isLoggedIn ? ['/'] : ['/user/signin']);
  }

  protected resetPasswordSuccessCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.user.reset-password.toast.success');
    this.authService.clearRefreshToken();
    this.router.navigate(['/user/signin']);
  }

  protected forgotPasswordSuccessCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.user.forgot-password.toast.success');
    this.authService.clearRefreshToken();
    this.router.navigate(['/user/signin']);
  }

  protected loginCallback(navigate?: boolean): void {
    this.authService.clearRefreshToken();
    this.localStorage.remove(LocalStorageKeys.TENANT_SETTINGS);
    this.localStorage.remove(LocalStorageKeys.LAST_TENANT_DOMAIN);
    this.localStorage.remove(LocalStorageKeys.LAST_TENANT);
    this.localStorage.remove(LocalStorageKeys.SELECTED_DASHBOARD);

    if (navigate) {
      this.router.navigate(['/user/signin']);
    }
  }

  protected errorActionCallback(action: Action, error: string | Error | HttpErrorResponse): void {
    const payload = action as any as BaseXsStoreErrorPayload;

    switch (payload.action.type) {
      case this.actions.loginWithRefresh.type:
        if ((error as HttpErrorResponse).status === 403) {
          this.apiNotificationService.showTranslatedError('core-portal.core.error.backend-permission');
        } else {
          super.errorActionCallback(action, error);
        }

        this.store.dispatch(this.actions.logout());
        break;

      case this.actions.changePassword.type:
        if ((error as HttpErrorResponse).status === 404 || (error as HttpErrorResponse).status === 400) {
          this.apiNotificationService.showTranslatedError('core-portal.user.change-password.toast.404');
        } else {
          super.errorActionCallback(action, error);
        }
        break;

      default:
        super.errorActionCallback(action, error);
        break;
    }
  }
}
