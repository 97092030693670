<ng-container *ngIf="!to.corePortalEditor?.noHTML; else noHTML">
  <ckeditor *ngIf="editor && config; else loading"
            [editor]="editor"
            [config]="config"
            [formControl]="formControl"
            [formlyAttributes]="field"
            (ready)="onReady($event)">
  </ckeditor>
</ng-container>

<ng-template #noHTML>
  <textarea #textAreaElement
            [formControl]="formControl"
            [formlyAttributes]="field"
            [rows]="to.rows"
            class="form-control">
  </textarea>
</ng-template>

<ng-container *ngIf="!to.disabled && !to.corePortalEditor?.noHTML">
  <div *ngIf="$any(to.corePortalEditor?.buttons) as buttons">
    <button *ngFor="let button of buttons"
            class="btn btn-outline-secondary mt-2"
            [class.d-none]="button.show$ ? (button.show$ | async) === false : false"
            (click)="button.click()">
      {{ button.label | translate }}
    </button>
  </div>
</ng-container>

<ng-template #loading>
  <input class="form-control w-100" [value]="'core-portal.core.general.loading' | translate" disabled>
</ng-template>
