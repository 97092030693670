import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faClipboardCheck} from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {faFolder} from '@fortawesome/free-solid-svg-icons/faFolder';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {faTasks} from '@fortawesome/free-solid-svg-icons/faTasks';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {
  AddressInMissionDto,
  ContactSimpleDto,
  CoreSharedIssueLinkUtilityService,
  EditorQuestState,
  IssueSimpleDto,
  LocationInMissionDto,
  MissionByTaskDto,
  MissionByTicketDto,
  MissionDto,
  MissionSimpleDto,
  QuestInMissionDto,
  ResourceInTicketDto,
  ResourceTaskSimpleDto,
  TicketInMissionDto
} from '@nexnox-web/core-shared';
import {isUndefined} from 'lodash';
import {Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faHome} from '@fortawesome/free-solid-svg-icons/faHome';
import {faInbox} from '@fortawesome/free-solid-svg-icons/faInbox';

export interface LocalQuestDto {
  state: EditorQuestState;
  label$: Observable<string>;
  functionId: number;
  functionName: string;
}

@Component({
  selector: 'nexnox-web-missions-mission-status',
  templateUrl: './mission-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionStatusComponent implements OnInit {
  @Input() public model$: Observable<MissionDto> = of(null);
  @Input() public loading: boolean;
  @Input() public loadingQuest: boolean;
  @Input() public readonly: boolean;
  @Input() public quest$: Observable<LocalQuestDto> = of(null);

  @Output() public openQuestSidebar: EventEmitter<LocalQuestDto> = new EventEmitter<LocalQuestDto>();

  public missionStateDefined$: Observable<boolean>;
  public missionState$: Observable<string>;
  public missionSolutionContact$: Observable<ContactSimpleDto>;

  public ticketReference$: Observable<TicketInMissionDto>;
  public taskReference$: Observable<ResourceTaskSimpleDto>;
  public resourceReference$: Observable<ResourceInTicketDto>;
  public resourceReferencePath$: Observable<any[]>;
  public locationReference$: Observable<LocationInMissionDto>;
  public locationAddressesReference$: Observable<AddressInMissionDto[]>;
  public ticketAddress$: Observable<string>;
  public primaryIssue$: Observable<IssueSimpleDto>
  public predecessor$: Observable<MissionSimpleDto>;
  public isComplete$: Observable<boolean>;

  public faEnvelope = faEnvelope;
  public faFolder = faFolder;
  public faTasks = faTasks;
  public faClipboardCheck = faClipboardCheck;
  public faPencilAlt = faPencilAlt;
  public faUser = faUser;
  public faSearch = faSearch;
  public faSpinner = faSpinner;
  public faHome = faHome;
  public faInbox = faInbox;

  constructor(
    private translate: TranslateService,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    this.missionStateDefined$ = this.model$.pipe(
      map(model => !isUndefined(model?.state))
    );

    this.missionState$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => `missions.mission-states.${model.state}`)
    );

    this.isComplete$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.isCompleted ?? false)
    );

    this.missionSolutionContact$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.solutionContact)
    );

    this.ticketReference$ = this.model$.pipe(
      filter((model: MissionByTicketDto) => Boolean(model?.ticket)),
      map((model: MissionByTicketDto) => model.ticket)
    );

    this.taskReference$ = this.model$.pipe(
      filter((model: MissionByTaskDto) => Boolean(model?.task)),
      map((model: MissionByTaskDto) => model.task)
    );

    this.resourceReference$ = this.model$.pipe(
      filter(model => Boolean(model?.resource)),
      map(model => model.resource)
    );

    this.resourceReferencePath$ = this.resourceReference$.pipe(
      filter(resource => Boolean(resource)),
      map(resource => (['/resources', resource.resourceId]))
    );

    this.locationReference$ = this.model$.pipe(
      map(mission => mission?.location ?? null)
    );

    this.locationAddressesReference$ = this.locationReference$.pipe(
      filter(location => Boolean(location?.addresses)),
      map(location => location.addresses)
    );

    this.ticketAddress$ = this.model$.pipe(
      filter(model => Boolean(model?.solution?.threadMailAddress)),
      map(model => model.solution.threadMailAddress)
    );

    this.predecessor$ = this.model$.pipe(
      filter(model => Boolean(model?.precursor)),
      map(model => model.precursor)
    );

    this.quest$ = this.quest$.pipe(
      map(quest => this._mapQuestToLocalDto(quest))
    );

    this.primaryIssue$ = this.model$.pipe(
      map(model => model?.origin?.primaryIssue)
    );
  }

  public getIssueLink(primaryIssue: IssueSimpleDto): Array<string | number> {
    return this.issueUtilityService.getIssueSegments(primaryIssue);
  }

  private _mapQuestToLocalDto(quest: QuestInMissionDto): LocalQuestDto {
    // Initialize
    const localQuest: LocalQuestDto = {
      state: quest?.state ?? 0,
      label$: null,
      functionId: quest?.functionProperty?.functionId,
      functionName: quest?.functionProperty?.name
    }

    // Label
    if (!!quest) {
      localQuest.label$ = this.translate.stream('missions.quest-label-states.' + localQuest.state, localQuest);
    } else {
      localQuest.label$ = this.translate.stream('missions.quest-label-states.0');
    }
    return localQuest;
  }
}
