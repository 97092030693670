import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {ActionButton, CorePortalEntityDetailStandaloneBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, AreaAssignmentDto} from "@nexnox-web/core-shared";
import {contactAreaDetailStore} from "../../store";

@Component({
  selector: 'nexnox-web-master-data-contacts-contact-area-detail',
  templateUrl: './contact-area-detail-standalone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactAreaDetailStandaloneComponent extends CorePortalEntityDetailStandaloneBaseComponent<AreaAssignmentDto> implements OnInit {

  public idParam = "areaAssignmentId";
  public displayKey = 'areaAssignmentId'

  constructor(
    protected injector: Injector,
  ) {
    super(injector, contactAreaDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setTitle('core-portal.master-data.actions.create-contact-area', 'core-portal.master-data.actions.edit-contact-area');
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getStandaloneActionButtons(
      'core-portal.master-data.actions.create-contact-area',
      AppPermissions.CreateAreaAssignment,
      'core-portal.master-data.actions.save-contact-area',
      AppPermissions.UpdateAreaAssignment
    )
  }
}
