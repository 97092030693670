import { ChangeDetectionStrategy, Component, Inject, Injector, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CorePortalEntityEditBaseComponent, CorePortalFormlyNgSelectTyping, CorePortalPermissionService } from '@nexnox-web/core-portal';
import {
  AppPermissions,
  AttachmentForTechDto,
  AttachmentTypeForTech,
  CombineOperator,
  ContactDto,
  CoreSharedModalService,
  CoreSharedSortableListItem,
  CoreSharedTextConverterService,
  FileAttachmentForTechDto,
  Filter,
  FilterKind,
  FilterOperators,
  FilterTypes,
  MailRelationTypes,
  MissionType,
  NoteMailBoxDto,
  NoteMailDto,
  NoteType,
  RenderedTemplateDto,
  SolutionAttachmentDto,
  SolutionDto,
  SolutionItemDto,
  SolutionMemberDto,
  SolutionMemberType,
  SystemDirections,
  TemplateContextType
} from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isEqual, isNumber, isString } from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import { CorePortalFeatureMasterDataContactService } from '@nexnox-web/core-portal/features/master-data/features/contacts';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import {
  TECH_PORTAL_TEXT_TEMPLATE_CONTEXT_CONFIG,
  TechPortalFeatureTextTemplateApplySidebarComponent,
  TechPortalTextTemplateContextConfig
} from '@nexnox-web/tech-portal/features/templates';
import { TechPortalMissionService, TechPortalTicketService } from '@nexnox-web/tech-portal-lib';
import { TechPortalFeatureSolutionService } from '../../store';

const noteTypeOptions = [
  { value: NoteType.Text, label: 'solutions.solution-item-types.0' },
  { value: NoteType.Mail, label: 'solutions.solution-item-types.1' },
  { value: NoteType.Caller, label: 'solutions.solution-item-types.2' },
  { value: NoteType.ChatMessage, label: 'solutions.solution-item-types.9' }
];

const noteDirectionOptions = [
  { value: SystemDirections.In, label: 'solutions.solution-item-directions.1' },
  { value: SystemDirections.Out, label: 'solutions.solution-item-directions.2' }
];

@Component({
  selector: 'nexnox-web-solutions-solution-item-edit',
  templateUrl: './solution-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureSolutionItemEditComponent extends CorePortalEntityEditBaseComponent<SolutionItemDto> implements OnInit {
  @Input() public relationId: number;
  @Input() public relationIdProperty: string;
  @Input() public solution$: Observable<SolutionDto>;
  @Input() public solutionMembers: SolutionMemberDto[];
  @Input() public type: SolutionMemberType;
  @Input() public hasChat: boolean;

  @ViewChild('mailOptionTemplate', { static: true }) public mailOptionTemplate: TemplateRef<any>;

  @ViewChild('textTemplateApplySidebar', { static: true })
  public textTemplateApplySidebar: TechPortalFeatureTextTemplateApplySidebarComponent;

  public solutionAttachments$: Observable<SolutionAttachmentDto[]>;
  public solutionAttachmentItems$: Observable<CoreSharedSortableListItem[]>;
  public attachments$: Observable<AttachmentForTechDto[]>;

  public noteTypes = NoteType;

  constructor(
    protected injector: Injector,
    private contactService: CorePortalFeatureMasterDataContactService,
    private translate: TranslateService,
    private permissionService: CorePortalPermissionService,
    private modalService: CoreSharedModalService,
    private ticketService: TechPortalTicketService,
    private missionService: TechPortalMissionService,
    private solutionService: TechPortalFeatureSolutionService,
    private textConverterService: CoreSharedTextConverterService,
    @Inject(TECH_PORTAL_TEXT_TEMPLATE_CONTEXT_CONFIG) private textTemplateConfig: TechPortalTextTemplateContextConfig
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  public static mapAttachment(attachment: AttachmentForTechDto, index: number, member?: SolutionMemberDto): CoreSharedSortableListItem {
    let newMember;

    if (member) {
      let link;

      switch (member.type) {
        case SolutionMemberType.Ticket:
          link = `/tickets/${member.id}`;
          break;
        case SolutionMemberType.Mission:
          link = `/missions/${member.id}`;
          break;
      }

      newMember = { ...member, link };
    }

    switch (attachment.type) {
      case AttachmentTypeForTech.File: {
        const fileAttachment: FileAttachmentForTechDto = attachment as FileAttachmentForTechDto;
        return {
          title: fileAttachment.file?.name,
          position: index,
          deletable: true,
          getExternalData: () => ({ ...fileAttachment, member: newMember })
        };
      }
      default:
        return {
          title: attachment.attachmentId.toString(),
          position: index,
          deletable: true,
          getExternalData: () => ({ ...attachment, member: newMember })
        };
    }
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    super.ngOnInit();

    this.solutionAttachments$ = this.solution$.pipe(
      map(solution => solution.attachments)
    );

    this.solutionAttachmentItems$ = this.solution$.pipe(
      filter(solution => Boolean(solution)),
      distinctUntilChanged((a, b) => a.solutionId === b.solutionId),
      switchMap(solution => this.solutionService.getOne<SolutionDto>(solution.solutionId).pipe(
        map(newSolution => newSolution.attachments.map((x, index) =>
          TechPortalFeatureSolutionItemEditComponent.mapAttachment(x.attachment, index, x.member)))
      ))
    );

    this.attachments$ = this.modelSubject.asObservable().pipe(
      map(model => model?.note?.attachments ?? [])
    );
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[]): void {
    this.onModelChange({
      ...(this.model ?? {}),
      note: {
        ...(this.model?.note ?? {}),
        attachments
      }
    });
  }

  public onTextTemplateApplied(template: RenderedTemplateDto): void {
    this.setModel({
      ...this.model,
      note: {
        ...(this.model?.note ?? {}),
        description: template.content
      }
    });
    setTimeout(() => this.onModelChange(this.model));
  }

  public getSanitizedModel(): SolutionItemDto {
    if (this.model?.note?.type === NoteType.Mail) {
      return {
        ...this.model,
        note: {
          ...this.model.note,
          to: this.mapNoteMailBoxes(((this.model.note as NoteMailDto).to as any) ?? [], MailRelationTypes.To),
          cc: this.mapNoteMailBoxes(((this.model.note as NoteMailDto).cc as any) ?? [], MailRelationTypes.Cc),
          direction: SystemDirections.Out
        } as NoteMailDto
      };
    }

    return super.getSanitizedModel();
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'note.type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: NoteType.Text,
        templateOptions: {
          corePortalTranslated: {
            label: 'solutions.fields.solution-item-type',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              notAllowed: 'core-portal.core.validation.not-allowed'
            }
          },
          corePortalNgSelect: {
            items: noteTypeOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        validators: {
          notAllowed: ctrl => this.creating && ctrl.value === NoteType.ChatMessage ? this.hasChat : true
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged()
          ), (value) => {
            const description = this.model?.note?.description ?? '';
            if (value === NoteType.ChatMessage) {
              this.setModel({
                ...this.model,
                note: {
                  ...(this.model?.note ?? {}),
                  description: this.textConverterService.convertHTMLtoText(description)
                }
              });
              setTimeout(() => this.onModelChange(this.model));
            }
          })
        }
      },
      this.getMailFieldConfig('note.to', 'core-shared.shared.fields.receiver', true),
      this.getMailFieldConfig('note.cc', 'core-shared.shared.fields.cc', false),
      {
        key: 'note.description',
        type: 'core-portal-editor',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.description',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalEditor: {
            noHTML: false,
            language: this.translate.currentLang,
            buttons: [{
              label: 'core-portal.settings.actions.templates.apply-text-template',
              show$: this.permissionService.hasPermission$(AppPermissions.ReadTemplate),
              click: () => {
                const memberSubject = new BehaviorSubject<SolutionMemberDto>(null);
                const refresh$ = memberSubject.asObservable().pipe(
                  distinctUntilChanged((a, b) => isEqual(a, b)),
                  pairwise(),
                  filter(([old, current]) => !isEqual(
                    [old?.type, old?.missionType],
                    [current?.type, current?.missionType]
                  ))
                );

                this.textTemplateApplySidebar.onShow({
                  prependFields: this.getTextTemplatePrependFields(memberSubject, refresh$),
                  refresh$,
                  defaultFilters$: memberSubject.asObservable().pipe(
                    distinctUntilChanged((a, b) => isEqual(a, b)),
                    map(member => {
                      let memberContextType: TemplateContextType;

                      switch (member?.type) {
                        case SolutionMemberType.Ticket:
                          memberContextType = TemplateContextType.NoteForTicket;
                          break;
                        case SolutionMemberType.Mission:
                          switch (member.missionType) {
                            case MissionType.Ticket:
                              memberContextType = TemplateContextType.NoteForMissionByTicket;
                              break;
                            case MissionType.Task:
                              memberContextType = TemplateContextType.NoteForMissionByTask;
                              break;
                            case MissionType.Inspection:
                              memberContextType = TemplateContextType.NoteForMissionByInspection;
                              break;
                            default:
                              memberContextType = TemplateContextType.NoteForMission;
                              break;
                          }
                          break;
                        default:
                          memberContextType = TemplateContextType.NoteBase;
                          break;
                      }

                      return [{
                        type: FilterTypes.Grouped,
                        combinedAs: CombineOperator.Or,
                        kind: FilterKind.Grouped,
                        children: [
                          {
                            property: 'context',
                            type: FilterTypes.DataTransferObject,
                            operator: FilterOperators.Equal,
                            value: memberContextType.toString()
                          },
                          ...(this.textTemplateConfig[memberContextType] ? (this.textTemplateConfig[memberContextType].parents ?? [])
                            .map(parent => ({
                              property: 'context',
                              type: FilterTypes.DataTransferObject,
                              operator: FilterOperators.Equal,
                              value: parent.toString()
                            })) : [])
                        ]
                      }] as Filter[];
                    })
                  )
                });
              }
            }]
          },
          rows: 3,
          type: 'text'
        },
        hideExpression: () => !this.model || !isNumber(this.model.note?.type),
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.corePortalEditor.noHTML': () => this.model?.note?.type === NoteType.ChatMessage
        }
      },
      {
        key: 'note.direction',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: noteDirectionOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'solutions.fields.solution-item-direction',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalNgSelect: {
            items: noteDirectionOptions,
            translate: true
          }
        },
        hideExpression: () => !this.model || this.model.note?.type !== NoteType.Caller,
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'note.phonenumber',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.phone',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              phone: 'core-portal.core.validation.phone'
            }
          },
          type: 'tel'
        },
        hideExpression: () => !this.model || this.model.note?.type !== NoteType.Caller,
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        validators: {
          phone: ctrl => !isString(ctrl.value) || !Validators.pattern(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/)(ctrl)
        }
      }
    ];
  }

  protected setModel(model: SolutionItemDto): void {
    super.setModel({ ...model, [this.relationIdProperty]: this.relationId });
  }

  private mapNoteMailBoxes(contacts: ContactDto[], relation: MailRelationTypes): NoteMailBoxDto[] {
    return contacts.map(contact => ({
      mailBox: { address: contact.emailAddress, displayName: contact.displayName },
      relation
    })) as NoteMailBoxDto[];
  }

  /* istanbul ignore next */
  private getMailFieldConfig(key: string, translation: string, required: boolean): FormlyFieldConfig {
    return {
      key,
      type: 'core-portal-entity-select',
      wrappers: ['core-portal-translated'],
      className: 'col-md-12',
      defaultValue: null,
      templateOptions: {
        showError: true,
        corePortalTranslated: {
          label: translation,
          validationMessages: {
            required: 'core-portal.core.validation.required',
            email: 'core-portal.core.validation.email'
          }
        },
        entityService: this.contactService,
        idKey: 'contactId',
        displayKey: 'displayName',
        wholeObject: true,
        multiple: true,
        addTagFn: (term: string) => ({ emailAddress: term, displayName: term }),
        selectLabelTemplate: this.mailOptionTemplate,
        selectOptionTemplate: this.mailOptionTemplate,
        showAll: true
      },
      expressionProperties: {
        'templateOptions.required': () => required ? !this.readonly : false,
        'templateOptions.disabled': () => this.readonly
      },
      validators: {
        email: ctrl => ctrl.value?.length ? ctrl.value.every(
          (contact: ContactDto) => Boolean(contact.emailAddress) && !Validators.email(new FormControl(contact.emailAddress))
        ) : true
      },
      hideExpression: () => !this.model || this.model.note?.type !== NoteType.Mail
    };
  }

  /* istanbul ignore next */
  private getTextTemplatePrependFields(memberSubject: BehaviorSubject<SolutionMemberDto>, refresh$: Observable<any>): FormlyFieldConfig[] {
    return [
      { key: 'id' },
      {
        key: 'solutionMember',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 px-0',
        defaultValue: this.solutionMembers?.length ? this.solutionMembers[0] : null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.solution-member',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalNgSelect: {
            items: (this.solutionMembers ?? []).map(solutionMember => ({
              label: solutionMember.title,
              value: solutionMember
            })),
            compareFn: (a: SolutionMemberDto, b: SolutionMemberDto) => isEqual([a?.id, a?.type], [b?.id, b?.type]),
            noClear: true,
            groupByFn: (value: SolutionMemberDto) => value.type,
            groupValueFn: (_, children: SolutionMemberDto[]): { label: string } => ({
              label: `solutions.solution-members.${children[0].type}`
            })
          } as CorePortalFormlyNgSelectTyping,
          required: true
        },
        hooks: {
          onInit: field => {
            this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), (member: SolutionMemberDto) => {
              memberSubject.next(member);
              field.form.controls.id?.setValue(member?.id);
            });

            this.subscribe(refresh$, () => field.form.controls.template?.setValue(null));
          }
        }
      }
    ];
  }
}
