import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  EmptyAction,
  EntityXsStore,
  EntityXsStoreActions,
  EntityXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import {ResourceDto, ResourceState} from '@nexnox-web/core-shared';
import {createAction, props} from '@ngrx/store';
import {cloneDeep} from 'lodash';
import {immerOn} from 'ngrx-immer/store';
import {
  ResourceDetailXsStoreChangeStatePayload,
  ResourceDetailXsStorePutInSafePayload,
  ResourceDetailXsStorePutInSafeSuccessPayload,
  ResourceDetailXsStoreRemoveFromSafePayload
} from "./resource-detail-xs-store.payload";

export interface ResourceDetailXsStoreState extends EntityXsStoreState<ResourceDto> {
}

export interface ResourceDetailXsStoreActions extends EntityXsStoreActions<ResourceDto> {
  create: EmptyAction;
  createSuccess: PropsAction<{ entity: ResourceDto, model: ResourceDto }>;

  changeState: PropsAction<ResourceDetailXsStoreChangeStatePayload>;
  changeStateSuccess: PropsAction<ResourceDetailXsStoreChangeStatePayload>;

  putInSafe: PropsAction<ResourceDetailXsStorePutInSafePayload>;
  putInSafeSuccess: PropsAction<ResourceDetailXsStorePutInSafeSuccessPayload>;

  removeFromSafe: PropsAction<ResourceDetailXsStoreRemoveFromSafePayload>;
  removeFromSafeSuccess: EmptyAction;
}

export class ResourceDetailXsStore extends EntityXsStore<ResourceDto> {
  public actions: ResourceDetailXsStoreActions;

  protected createActions(label: string): ResourceDetailXsStoreActions {
    return {
      ...super.createActions(label),

      create: createAction(BaseXsStore.getType(label, 'Create')),
      createSuccess: createAction(
        BaseXsStore.getType(label, 'Create success'),
        props<{ entity: ResourceDto, model: ResourceDto }>()
      ),

      changeState: createAction(
        BaseXsStore.getType(label, 'Change resource state'),
        props<ResourceDetailXsStoreChangeStatePayload>()
      ),
      changeStateSuccess: createAction(
        BaseXsStore.getType(label, 'Change resource state success'),
        props<ResourceDetailXsStoreChangeStatePayload>()
      ),

      putInSafe: createAction(
        BaseXsStore.getType(label, 'Put in safe'),
        props<ResourceDetailXsStorePutInSafePayload>()
      ),
      putInSafeSuccess: createAction(
        BaseXsStore.getType(label, 'Put in safe success'),
        props<ResourceDetailXsStorePutInSafeSuccessPayload>()
      ),

      removeFromSafe: createAction(
        BaseXsStore.getType(label, 'Remove from safe'),
        props<ResourceDetailXsStoreRemoveFromSafePayload>()
      ),
      removeFromSafeSuccess: createAction(
        BaseXsStore.getType(label, 'Remove from safe success')
      )
    };
  }

  protected createReducerArray(
    initialState: ResourceDetailXsStoreState
  ): BaseXsStoreReducerTypes<ResourceDetailXsStoreState, ResourceDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.create, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.createSuccess, (draft, {entity, model}) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
        draft.loaded = true;
      }),
      immerOn(this.actions.changeState, draft => {
        draft.entityData = {
          loading: {
            changeState: true
          }
        };
      }),
      immerOn(this.actions.changeStateSuccess, (draft, {state, location, isKeepingLocation}) => {
        const model = draft.model;
        model.currentState = state;

        if (state === ResourceState.Operation) {
          model.location = location;
        } else if (!isKeepingLocation) {
          model.location = null;
        }

        draft.entity = cloneDeep(model);
        draft.model = cloneDeep(model);
        draft.entityData = {
          loading: {
            changeState: false
          }
        };
      }),
      immerOn(this.actions.putInSafe, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.putInSafeSuccess, (draft, {contacts}) => {
        const model = draft.model;
        model.safe = {contacts}
        draft.entity = cloneDeep(model);
        draft.model = cloneDeep(model);
        draft.loading = false;
      }),
      immerOn(this.actions.removeFromSafe, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.removeFromSafeSuccess, (draft) => {
        const model = draft.model;
        model.safe = null;
        draft.entity = cloneDeep(model);
        draft.model = cloneDeep(model);
        draft.loading = false;
      })
    ];
  }
}
