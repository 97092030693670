import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {faList} from '@fortawesome/free-solid-svg-icons/faList';
import {BehaviorSubject, interval, Observable, of} from 'rxjs';
import {
	ApiNotificationService,
	CoreSharedModalService,
	Paging, ResourceDto,
	ResourceListDto,
	ResourceType,
	UnsubscribeHelper,
	ViewChildrenHelper
} from '@nexnox-web/core-shared';
import {
	CorePortalTenantRouter,
	HierarchicalTreeViewComponent,
	HierarchicalTreeViewItem,
	HierarchicalTreeViewItemComponent
} from '@nexnox-web/core-portal';
import {select, Store} from '@ngrx/store';
import {
	resourceListStore,
	resourcesGuiStore,
	resourceUIEntitiesStore,
	ResourceUIEntitiesXsStoreEntity
} from '../../store';
import {filter, map, mergeMap, startWith, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {ResourcesComponent} from '../resources/resources.component';
import {Router} from '@angular/router';
import {faFolder} from '@fortawesome/free-solid-svg-icons/faFolder';
import {faFolderPlus} from '@fortawesome/free-solid-svg-icons/faFolderPlus';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";

@Component({
	selector: 'nexnox-web-resources-resource-list',
	templateUrl: './resource-list.component.html',
	styleUrls: ['./resource-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceListComponent extends UnsubscribeHelper implements OnInit {
	@Input() public parent: ResourcesComponent;

	@ViewChild('treeViewComponent') public treeViewComponent: HierarchicalTreeViewComponent;

	public resources$: Observable<Array<ResourceUIEntitiesXsStoreEntity & ResourceDto>>;
	public items$: Observable<HierarchicalTreeViewItem[]>;
	public paging$: Observable<Paging>;
	public loading$: Observable<boolean>;
	public nextLoading$: Observable<boolean>;
	public preloading$: Observable<boolean>;
	public guiLoading$: Observable<boolean>;

	public listLoading$: Observable<boolean>;

	public faFolder = faFolder;
	public faFolderPlus = faFolderPlus;

	public get selectedItem(): HierarchicalTreeViewItem {
		return this._selectedItem;
	}

	public preloadSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public canSelectItemFn: () => Promise<void>;

	public faList = faList;

	private _selectedItem: HierarchicalTreeViewItem;

	constructor(
		private store: Store<any>,
		private actions$: Actions,
		private modalService: CoreSharedModalService,
		private router: Router,
		private tenantRouter: CorePortalTenantRouter,
		private apiNotificationService: ApiNotificationService
	) {
		super();

		this.canSelectItemFn = () => this.onCanSelectItem();
	}

	public ngOnInit(): void {
		this.resources$ = this.store.pipe(
			select(resourceListStore.selectors.selectIds),
			mergeMap(ids => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectMerged(ids))))
		);

		this.loading$ = this.store.pipe(
			select(resourceListStore.selectors.selectLoading)
		);

		this.nextLoading$ = this.store.pipe(
			select(resourceListStore.selectors.selectAppendLoading)
		);

		this.preloading$ = this.store.pipe(
			select(resourceListStore.selectors.selectPreloading)
		);

		this.guiLoading$ = this.store.pipe(
			select(resourcesGuiStore.selectors.selectListLoading)
		);

		this.listLoading$ = this.loading$.pipe(
			mergeMap(loading => this.preloading$.pipe(
				mergeMap(preloading => this.guiLoading$.pipe(
					map(guiLoading => loading || preloading || guiLoading)
				))
			)),
			startWith(true)
		);

		this.paging$ = this.store.pipe(select(resourceListStore.selectors.selectPaging));

		/* istanbul ignore next */
		this.items$ = this.resources$.pipe(
			map(resources => resources.map(resource => ({
				id: resource.resourceId,
				parentId: resource.parentId ?? undefined,
				name: `${resource.inventoryNumber ? `${resource.inventoryNumber}: ` : ''}${resource.name}`,
				icon: this._getIcon(resource?.type),
				hasChildren: (resource as ResourceListDto).hasChildren !== false,
				getChildren: () => this.mapChildren(resource),
				getChildrenLoading: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectLoading(resource.resourceId))),
				getNextLoading: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectAppendLoading(resource.resourceId))),
				getPaging: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectPaging(resource.resourceId)))
			})))
		);

		/* istanbul ignore next */
		this.subscribe(this.actions$.pipe(ofType(resourceListStore.actions.preloadSuccess)), ({ids}) => {
			this.preloadSubject.next(true);
			this.subscribe(interval(500).pipe(
				mergeMap(() => this.treeViewComponent.componentsLoaded$.pipe(
					filter(loaded => loaded)
				)),
				filter(() => !this.treeViewComponent.loading),
				take(1)
			), () => {
				this.treeViewComponent.selectFromIds(ids, true).then(() => {
					this.preloadSubject.next(false);
				}).catch(() => {
					this.preloadSubject.next(false);
					this.apiNotificationService.showTranslatedError('core-portal.core.error.unknown');
					this.tenantRouter.navigate(['/resources'], {module: 'inventory'});
				});
			});
		});
	}

	public onSelectItem(itemComponent: HierarchicalTreeViewItemComponent): void {
		this._selectedItem = itemComponent.item;
		this.store.dispatch(resourcesGuiStore.actions.selectById({resourceId: itemComponent.item.id, from: 'list'}));
	}

	public onExpandItem(itemComponent: HierarchicalTreeViewItemComponent): void {
		this.store.dispatch(resourceUIEntitiesStore.actions.getChildRoot({parentId: itemComponent.item.id}));
	}

	public onMoreItem(itemComponent: HierarchicalTreeViewItemComponent): void {
		this.store.dispatch(resourceUIEntitiesStore.actions.getChildNext({parentId: itemComponent.item.id}));
	}

	public onMore(): void {
		this.store.dispatch(resourceListStore.actions.appendPage());
	}

	/* istanbul ignore next */
	public onCanSelectItem(): Promise<void> {
		const showModal = (resolve, reject): void => {
			this.modalService.showConfirmationModal(
				'core-shared.shared.unsaved-changes.title',
				'core-shared.shared.unsaved-changes.text',
				'warning',
				'core-portal.core.general.yes'
			).then(() => resolve()).catch(() => reject());
		};

		return new Promise<void>(async (resolve, reject) => {
			const hasUnsavedChanges: boolean = await ViewChildrenHelper.untilViewChildLoaded$<this>(this, 'parent').pipe(
				mergeMap(() => this.parent.hasUnsavedChanges().pipe(take(1)))
			).toPromise();

			if (!hasUnsavedChanges) {
				resolve();
			} else {
				showModal(resolve, reject);
			}
		});
	}

	/* istanbul ignore next */
	private mapChildren(resource: ResourceUIEntitiesXsStoreEntity & ResourceListDto): Observable<HierarchicalTreeViewItem[]> {
		if (resource.hasChildren) {
			return this.store.pipe(select(resourceUIEntitiesStore.selectors.selectMergedChildren(resource.resourceId))).pipe(
				map(mergedChildren => mergedChildren.map(mergedChild => ({
					id: mergedChild.resourceId,
					parentId: mergedChild.parentId ?? undefined,
					name: `${mergedChild.inventoryNumber ? `${mergedChild.inventoryNumber}: ` : ''}${mergedChild.name}`,
					icon: this._getIcon(mergedChild?.type),
					hasChildren: (mergedChild as ResourceListDto).hasChildren !== false,
					getChildren: () => this.mapChildren(mergedChild),
					getChildrenLoading: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectLoading(mergedChild.resourceId))),
					getNextLoading: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectAppendLoading(mergedChild.resourceId))),
					getPaging: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectPaging(mergedChild.resourceId)))
				})))
			);
		}

		return of([]);
	}

	private _getIcon(type: ResourceType): IconDefinition {

		let definition: IconDefinition;

		switch (type) {
			case ResourceType.Group:
				definition = this.faFolder;
				break;
			case ResourceType.Device:
				definition = null;
				break;
			case ResourceType.VirtualGroup:
				definition = this.faFolderPlus;
				break;
			case ResourceType.Virtual:
				definition = null;
				break;
			default:
				definition = null;
				break;
		}

		return definition;
	}
}
