import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, WinterhalterServiceCallIssueDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {WinterhalterIssueService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {selectIssuesState} from "../../issues.selectors";

export interface WinterhalterIssueDetailStoreState extends EntityXsStoreState<WinterhalterServiceCallIssueDto> {
}

/* istanbul ignore next */
export const winterhalterIssueDetailStore = new EntityXsStore<WinterhalterServiceCallIssueDto>({
  actionLabel: 'Tech Portal - Issues - Winterhalter Issue Detail',
  stateSelector: createSelector(selectIssuesState, state => state.winterhalterIssueDetail),
  serviceType: WinterhalterIssueService,
  entityType: AppEntityType.Issue,
  stereotyped: true
});

export function winterhalterIssueDetailStoreReducer(state: WinterhalterIssueDetailStoreState, action: Action): any {
  return winterhalterIssueDetailStore.reducer(state, action);
}

@Injectable()
export class WinterhalterIssueDetailStoreEffects extends winterhalterIssueDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
