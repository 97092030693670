import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ActionButton, CorePortalEntityDetailStandaloneBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, ResourceManufacturerDto, ResourceModelDto} from "@nexnox-web/core-shared";
import {modelDetailStore} from "./../../store";
import {BehaviorSubject, firstValueFrom, of} from "rxjs";
import {MergeModelSidebarComponent, MoveModelSidebarComponent} from "../../sidebars";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons/faEllipsisV";


@Component({
  selector: 'nexnox-web-settings-model-detail-standalone',
  templateUrl: './model-detail-standalone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelDetailStandaloneComponent extends CorePortalEntityDetailStandaloneBaseComponent<ResourceModelDto> implements OnInit {
  @ViewChild('moveModelSidebar', {static: true}) public moveModelSidebar: MoveModelSidebarComponent;
  @ViewChild('mergeModelSidebar', {static: true}) public mergeModelSidebar: MergeModelSidebarComponent;

  public title = 'core-portal.settings.subtitles.model-detail';
  public modelToMergeOrMoveSubject: BehaviorSubject<ResourceModelDto> = new BehaviorSubject<ResourceModelDto>({});

  public idParam = "resourceModelId";
  public displayKey = 'name';

  constructor(
    protected injector: Injector,
  ) {
    super(injector, modelDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setTitle('core-portal.settings.actions.create-model', 'core-portal.settings.actions.edit-model');
  }

  public mergeModel(newModel: ResourceModelDto): void {
    if (this.modelToMergeOrMoveSubject.getValue()) {
      this.isDeactivateUnsavedChangesModal = true;
      this.store.dispatch(modelDetailStore.actions.merge({
        model: this.modelToMergeOrMoveSubject.getValue(),
        newModelId: newModel.resourceModelId,
        returnPath: this.getReturnToListPath()
      }));
    }
  }

  public moveModel(newManufacturer: ResourceManufacturerDto): void {
    if (this.modelToMergeOrMoveSubject.getValue()) {
      this.isDeactivateUnsavedChangesModal = true;

      this.store.dispatch(modelDetailStore.actions.move({
        model: this.modelToMergeOrMoveSubject.getValue(),
        newManufacturerId: newManufacturer.resourceManufacturerId,
        returnPath: this.getReturnToListPath()
      }));
    }
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getStandaloneActionButtons(
        'core-portal.settings.actions.create-model',
        AppPermissions.CreateResourceModel,
        'core-portal.settings.actions.edit-model',
        AppPermissions.UpdateResourceModel
      ),
      {
        label: '',
        type: 'dropdown',
        class: 'btn-outline-secondary',
        icon: faEllipsisV,
        tooltip: 'core-portal.core.general.more',
        alignRight: true,
        noArrow: true,
        buttons: of([
          {
            label: 'core-portal.settings.actions.merge-model',
            type: 'button',
            permission: AppPermissions.MergeResourceModel,
            isLoading: () => this.loading$,
            callback: () => this.onMergeAction(),
            shouldShow: () => of(this.canDelete)
          },
          {
            label: 'core-portal.settings.actions.move-model',
            type: 'button',
            permission: AppPermissions.MergeResourceModel,
            isLoading: () => this.loading$,
            callback: () => this.onMoveAction(),
            shouldShow: () => of(this.canDelete)
          },
          {
            label: 'core-portal.settings.actions.delete-model',
            type: 'button',
            permission: AppPermissions.DeleteResourceModel,
            isLoading: () => this.loading$,
            callback: (_, button: ActionButton) => this.onDeleteAction(button, 'core-portal.settings.descriptions.delete-model', this.getReturnToListPath()),
            shouldShow: () => of(this.canDelete)
          }
        ]),
        isLoading: () => this.loading$,
        shouldShow: () => this.readonly$
      }
    ]
  }

  private async onMoveAction(): Promise<void> {
    this.modelToMergeOrMoveSubject.next(await firstValueFrom(this.store.select(modelDetailStore.selectors.selectEntity)));
    this.moveModelSidebar.onShow();
  }

  private async onMergeAction(): Promise<void> {
    this.modelToMergeOrMoveSubject.next(await firstValueFrom(this.store.select(modelDetailStore.selectors.selectEntity)));
    this.mergeModelSidebar.onShow();
  }
}
