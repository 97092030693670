import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {faEdit} from '@fortawesome/free-solid-svg-icons/faEdit';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {
  ActionButton,
  CorePortalEntityCreatePresetService,
  CorePortalEntityDetailBaseComponent
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  LocationDto,
  ResourceDto,
  ResourceState,
  ResourceType,
  SafeDto
} from '@nexnox-web/core-shared';
import {select} from '@ngrx/store';
import {isEmpty} from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  firstValueFrom,
  lastValueFrom,
  merge,
  Observable,
  of
} from 'rxjs';
import {distinctUntilChanged, map, mergeMap, pairwise, startWith, take} from 'rxjs/operators';
import {resourceDetailStore, resourceListStore, resourcesGuiStore} from '../../store/stores';
import {ResourceDetailXsStore} from '../../store/stores/resource-detail/resource-detail.xs-store';
import {EventListSidebarComponent, ResourceChangeStateSidebarComponent, SafeSidebarComponent} from "../../sidebars";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faBoxes} from '@fortawesome/free-solid-svg-icons/faBoxes';
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import {resourceStateColoredEnumOptions} from "../../models";
import {TranslateService} from "@ngx-translate/core";
import {
  InheritanceManufacturerModelService,
  InheritsQuickAccessPreviewService,
  InheritsSuggestionsPreviewService,
  ResourceInventoryNumberPreviewService,
  ResourcePreviewService
} from "../../store";

@Component({
  selector: 'nexnox-web-resources-resource-detail',
  templateUrl: './resource-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceDetailComponent extends CorePortalEntityDetailBaseComponent<ResourceDto> implements OnInit {
  @ViewChild('changeStateSidebar', {static: true}) public changeStateSidebar: ResourceChangeStateSidebarComponent;
  @ViewChild('resourceSafeSidebar', {static: true}) public resourceSafeSidebar: SafeSidebarComponent;
  @ViewChild('resourceEventListSidebar', {static: true}) public resourceEventListSidebar: EventListSidebarComponent;

  public title = 'resources.subtitles.resource';

  public isResourceSelected$: Observable<boolean>;
  public isGuiLoading$: Observable<boolean>;
  public isStereotypeLoading$: Observable<boolean>;
  public isDetailLoaded$: Observable<boolean>;
  public isPreloading$: Observable<boolean>;
  public creating$: Observable<boolean>;
  public safeContacts$: Observable<string>;
  public changeStateLoading$: Observable<boolean>;
  public resourceId$: Observable<string | number>;

  public modelSubject: BehaviorSubject<ResourceDto> = new BehaviorSubject(null);

  public resourceTypes = ResourceType;

  public faExclamationTriangle = faExclamationTriangle;
  public faSync = faSync;
  public faPencil = faPencilAlt;
  public faSpinner = faSpinner;
  public faBoxes = faBoxes;

  protected idParam = 'resourceId';
  protected displayKey = 'name';
  protected entityStore: ResourceDetailXsStore;

  private creatingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected injector: Injector,
    public entityCreatePresetService: CorePortalEntityCreatePresetService,
    private translateService: TranslateService
  ) {
    super(injector, resourceDetailStore);

    this.subscribe(this.model$, (model) => this.modelSubject.next(model));
  }

  public ngOnInit(): void {
    this.isResourceSelected$ = this.store.pipe(
      select(resourcesGuiStore.selectors.selectIsSelected)
    );

    this.resourceId$ = this.entity$.pipe(
      map(entity => entity?.resourceId)
    );

    this.isGuiLoading$ = merge(
      this.store.pipe(select(resourcesGuiStore.selectors.selectDetailLoading)),
      this.store.pipe(select(resourcesGuiStore.selectors.selectListLoading))
    );

    this.isStereotypeLoading$ = this.store.pipe(
      select(this.entityStore.selectors.selectStereotypesLoading)
    );

    this.isDetailLoaded$ = this.store.pipe(
      select(resourcesGuiStore.selectors.selectDetailLoaded)
    );

    this.isPreloading$ = this.store.pipe(
      select(resourceListStore.selectors.selectPreloading)
    );

    this.creating$ = this.creatingSubject.asObservable();

    super.ngOnInitSimple();

    this.monitors.add([
      {
        name: 'inventoryNumberPreview',
        keys: ['stereotypeId'],
        service: ResourceInventoryNumberPreviewService,
        payload: {
          stereotypes$: this.stereotypes$,
          entityType: AppEntityType.Resource,
          entityStore: resourceDetailStore
        }
      },
      {
        name: 'resourcePreview',
        keys: ['stereotypeId', 'parent'],
        service: ResourcePreviewService,
        payload: {
          stereotypes$: this.stereotypes$,
          entityType: AppEntityType.Resource,
          entityStore: resourceDetailStore
        }
      },
      {
        name: 'inheritanceManufacturerModelPreview',
        keys: ['inheritsManufacturer', 'inheritsModel'],
        service: InheritanceManufacturerModelService,
        payload: {
          entityType: AppEntityType.Resource,
        }
      },
      {
        name: 'inheritsSuggestionsPreview',
        keys: ['inheritsSuggestions'],
        service: InheritsSuggestionsPreviewService,
        payload: {
          entityType: AppEntityType.Resource,
        }
      },
      {
        name: 'inheritsQuickAccessConfigurationPreview',
        keys: ['inheritsQuickAccessConfiguration'],
        service: InheritsQuickAccessPreviewService,
        payload: {
          entityType: AppEntityType.Resource,
        }
      }
    ]);


    this.safeContacts$ = this.model$.pipe(
      map((resource) => (resource?.safe?.contacts ?? []).map(contact => contact.displayName).join(', '))
    );

    this.changeStateLoading$ = this.store.pipe(
      select(this.entityStore.selectors.selectEntityDataLoading, {key: 'changeState'}),
      startWith(false)
    )

    this.getActionButtons().then(actionButtons => this.actionBarService.setActions(actionButtons));
  }

  public async onRefresh(): Promise<void> {
    // Reload entity
    const entity = await this.selectEntity();
    this.store.dispatch(this.entityStore.actions.get({id: entity.resourceId}));

    // Refresh resource list after entity reload
    this.loading$.pipe(
      filter((loading) => !loading),
      take(1)
    ).subscribe(() => this.store.dispatch(resourcesGuiStore.actions.selectById({
      resourceId: entity.resourceId,
      from: 'detail'
    })));
  }

  public onPutInSafe(safe: SafeDto): void {
    this.store.dispatch(resourceDetailStore.actions.putInSafe({contacts: safe.contacts}));
  }

  public onRemoveFromSafe(resourceId: number): void {
    this.store.dispatch(resourceDetailStore.actions.removeFromSafe({resourceId}));
  }

  public async openChangeStateSidebar(state: ResourceState): Promise<void> {
    const entity: ResourceDto = await this.selectEntity();
    if (entity.currentState !== state) {
      this.changeStateSidebar.onShow(state, entity);
    }
  }

  public async onChangeState(event: {
    state: ResourceState,
    location: LocationDto | undefined,
    isKeepingLocation: boolean | undefined
  }): Promise<void> {
    const {state, location, isKeepingLocation} = event;
    return this.store.dispatch(resourceDetailStore.actions.changeState({
      state,
      location,
      isKeepingLocation
    }));

  }

  protected async selectEntity(): Promise<ResourceDto> {
    return await lastValueFrom(this.store.pipe(select(this.entityStore.selectors.selectEntity), take(1)));
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      {
        label: 'core-shared.shared.actions.edit-mode',
        type: 'button',
        class: 'btn-outline-primary',
        icon: faEdit,
        permission: AppPermissions.UpdateResource,
        shouldShow: () => this.entity$.pipe(
          mergeMap(entity => this.readonly$.asObservable().pipe(
            map(readonly => readonly && Boolean(entity) && !isEmpty(entity))
          ))
        ),
        isDisabled: () => this.entity$.pipe(map(entity => Boolean(entity) && !isEmpty(entity) && entity.type === ResourceType.Virtual)),
        callback: () => this.onEditAction()
      },
      {
        label: 'core-shared.shared.actions.view-mode',
        type: 'button',
        class: 'btn-outline-primary',
        icon: faEye,
        shouldShow: () => this.readonly$.asObservable().pipe(
          map(readonly => !readonly)
        ),
        callback: () => this.onCancelAction()
      },
      {
        label: 'resources.actions.save-resource',
        type: 'button',
        class: 'btn-primary',
        permission: AppPermissions.UpdateResource,
        icon: faSave,
        shouldShow: () => this.readonly$.asObservable().pipe(
          mergeMap(readonly => this.creatingSubject.asObservable().pipe(
            map(creating => !readonly && !creating)
          ))
        ),
        isDisabled: () => this.shouldDisablePrimaryAction(),
        isLoading: () => this.store.pipe(
          select(resourceDetailStore.selectors.selectLoading)
        ),
        callback: () => this.onSaveAction()
      },
      {
        label: 'resources.actions.create-resource',
        type: 'button',
        class: 'btn-outline-primary',
        permission: AppPermissions.CreateResource,
        icon: faPlus,
        shouldShow: () => this.readonly$.asObservable().pipe(
          mergeMap(readonly => this.entity$.pipe(
            mergeMap(entity => this.creatingSubject.asObservable().pipe(
              map(creating => readonly && (!entity || isEmpty(entity)) && !creating)
            ))
          ))
        ),
        callback: () => {
          this.setCreateMode();
          window.location.hash = '#create'
        }
      },
      {
        label: 'resources.actions.create-resource',
        type: 'button',
        class: 'btn-primary',
        permission: AppPermissions.CreateResource,
        icon: faSave,
        shouldShow: () => this.readonly$.asObservable().pipe(
          mergeMap(readonly => this.creatingSubject.asObservable().pipe(
            map(creating => !readonly && creating)
          ))
        ),
        isDisabled: () => this.shouldDisablePrimaryAction(),
        isLoading: () => this.store.pipe(
          select(this.entityStore.selectors.selectLoading)
        ),
        callback: () => {
          this.isDeactivateUnsavedChangesModal = true;
          this.store.dispatch(this.entityStore.actions.create())
        }
      },
      {
        label: 'resources.actions.create-resource-from-resource',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateResource,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity) && (entity?.type === ResourceType.Device || entity?.type === ResourceType.Group))
        ),
        callback: async () => {
          const entity = await firstValueFrom(this.entity$.pipe(take(1)));

          if (entity) {
            // Prepare preset
            this.entityCreatePresetService.setPreset('TechPortalFeatureFeatureResourceEditComponent', {
              parent: entity,
              stereotypeId: entity.stereotypeId,
              inheritsKnowledge: true,
              inheritsManufacturer: true,
              inheritsModel: true,
              inheritsProcesses: true,
              inheritsQuickAccessConfiguration: true,
              inheritsSuggestions: true
            });
            // Bypass monitors
            this.bypassMonitorsSubject.next(true);
            // Clear model
            this.store.dispatch(resourceDetailStore.actions.clear());
            // Change to create
            await this.tenantRouter.navigate(['/resources'], {fragment: 'create', module: 'inventory'});
            // Monitor previews will be triggered after loading$ set bypass to false
            setTimeout(() => this.bypassMonitorsSubject.pipe(
              debounceTime(1000),
              filter(bypass => !bypass),
              take(1)
            ).subscribe(() =>
              // Trigger all monitors
              this.monitors.trigger('inventoryNumberPreview').then(
                () => this.monitors.trigger('resourcePreview').then(
                  () => this.monitors.trigger('inheritanceManufacturerModelPreview').then()
                ))
            ));
          }
        }
      },
      {
        label: 'resources.actions.create-ticket-from-resource',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateTicket,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity) && (entity?.type === ResourceType.Device || entity?.type === ResourceType.Virtual))
        ),
        callback: async () => {
          const entity = await firstValueFrom(this.entity$.pipe(take(1)));

          if (entity) {
            this.entityCreatePresetService.setPreset('TechPortalFeatureTicketEditComponent', {
              resource: entity,
              location: entity.location
            });
            await this.tenantRouter.navigate(['/tickets'], {module: 'communication', fragment: 'create'});
          }
        }
      },
      {
        label$: this.model$.pipe(
          map(resource => {
            const label = this.translateService.instant('resources.actions.change-resource-state');
            const state = resourceStateColoredEnumOptions.find(s => s.value === (resource?.currentState ?? ResourceState.Operation));
            return `${label}: ${state?.label ? this.translateService.instant(state.label) : ''}`;
          })
        ),
        type: 'dropdown',
        icon: faExchangeAlt,
        class: 'btn-outline-primary',
        noTranslate: true,
        isLoading: () => this.changeStateLoading$,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && entity.type === ResourceType.Device)
        ),
        buttons: of(resourceStateColoredEnumOptions.map(resourceState => ({
          label: resourceState.label,
          type: 'button',
          callback: async () => this.openChangeStateSidebar(resourceState.value)
        } as ActionButton))),
        permission: AppPermissions.UpdateResource
      },

      {
        label: null,
        type: 'dropdown',
        class: 'btn-outline-secondary',
        icon: faEllipsisV,
        tooltip: 'core-portal.core.general.more',
        alignRight: true,
        noArrow: true,
        buttons: of([
          {
            label: 'resources.subtitles.resource-events',
            type: 'button',
            permission: AppPermissions.ReadResourceEvent,
            shouldShow: () => this.entity$.pipe(
              map(entity => Boolean(entity?.resourceId))
            ),
            callback: () => this.resourceEventListSidebar.onShow()
          },
          {
            label: 'resources.actions.put-in-safe',
            type: 'button',
            permission: AppPermissions.CreateSafe,
            shouldShow: () => this.entity$.pipe(
              map(entity => !Boolean(entity?.safe) && Boolean(entity?.resourceId) && entity.type === ResourceType.Device)
            ),
            callback: () => this.resourceSafeSidebar.onShow()
          },
          {
            label: 'resources.actions.remove-from-safe',
            type: 'button',
            permission: AppPermissions.DeleteSafe,
            shouldShow: () => this.entity$.pipe(
              map(entity => Boolean(entity?.safe) && Boolean(entity?.resourceId) && entity.type === ResourceType.Device)
            ),
            callback: () => this.resourceSafeSidebar.onShow(true)
          },
          {
            label: 'resources.actions.delete-resource',
            type: 'button',
            permission: AppPermissions.DeleteResource,
            shouldShow: () => this.entity$.pipe(
              map(entity => Boolean(entity) && !isEmpty(entity) && entity.type !== ResourceType.Virtual)
            ),
            callback: async (_, button: ActionButton) => {
              const entity = await firstValueFrom(this.entity$.pipe(take(1)));

              if (entity) {
                this.id = entity.resourceId;
                this.onDeleteAction(
                  button,
                  'resources.descriptions.delete-resource',
                  this.tenantRouter.createCommands(['resources'], {module: 'inventory'})
                );
              }
            }
          }
        ]),
        isLoading: () => this.loading$
      }
    ];
  }

  protected async onCancelAction(): Promise<void> {
    const cancel = (): void => {
      this.isDeactivateUnsavedChangesModal = true;
      window.location.hash = '';
      this.setViewMode();
    };

    const hasUnsavedChanges = await firstValueFrom(this.hasUnsavedChanges().pipe(take(1)));

    if (hasUnsavedChanges) {
      this.canDeactivate().then(answer => answer ? cancel() : undefined)
    } else {
      cancel();
    }
  }

  protected async onSaveAction(): Promise<void> {
    const id = await firstValueFrom(this.store.pipe(
      select(this.entityStore.selectors.selectEntity),
      map(entity => entity?.resourceId),
      take(1)
    ));
    this.isDeactivateUnsavedChangesModal = true;
    this.bypassMonitorsSubject.next(true);
    this.store.dispatch(this.entityStore.actions.save({id, parentIds: this.parentIds}));
  }

  protected override subscribeToMonitorBypass(): void {
    // Bypass monitors on loading and readonly and other conditions for resource detail
    this.subscribe(
      combineLatest(
        [this.readonly$.asObservable(), this.loading$, this.isPreloading$, this.isGuiLoading$, this.isStereotypeLoading$]
      ).pipe(
        map(([readonly, loading, preloading, guiLoading, stereotypeLoading]) => loading || preloading || guiLoading || stereotypeLoading || readonly),
        distinctUntilChanged()
      ),
      (bypass) => this.bypassMonitorsSubject.next(bypass)
    );
  }

  /* istanbul ignore next */
  protected subscribeToFragment(): void {

    this.subscribe(this.route.fragment.pipe(startWith(null), pairwise()), async ([prevFragment, currFragment]) => {

      const hasUnsavedChanges = await firstValueFrom(this.hasUnsavedChanges().pipe(take(1)));

      if ((prevFragment === 'create' || prevFragment === 'edit') && hasUnsavedChanges && !(await this.canDeactivate())) {
        window.location.hash = prevFragment;
        return;
      }

      switch (currFragment) {
        case 'edit':
          this.setEditMode();
          break;
        case 'create':
          this.setCreateMode();
          break;
        case null:
          this.setViewMode();
          break;
        default:
          window.location.hash = '';
          this.setViewMode();
          break;
      }

      this.isDeactivateUnsavedChangesModal = false;
    });
  }

  /* istanbul ignore next */
  protected setCreateMode(): void {
    this.creatingSubject.next(true);
    this.readonly$.next(false);
  }

  /* istanbul ignore next */
  protected setEditMode(): void {
    super.setEditMode();
    this.creatingSubject.next(false);
  }

  /* istanbul ignore next */
  protected setViewMode(): void {
    super.setViewMode();
    this.creatingSubject.next(false);
  }
}
