<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-issues-winterhalter-issue-edit #modelComponent
                                             [title]="title"
                                             [id]="id"
                                             [model]="model$ | async"
                                             [readonly]="true"
                                             [loading]="loading$ | async"
                                             [stereotypes$]="stereotypes$"
                                             [stereotyped]="true"
                                             (modelChange)="onModelChange($event)">
  </nexnox-web-issues-winterhalter-issue-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="issue"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
</nexnox-web-entity-detail>

