<nexnox-web-tabs [internal]="true"
                 [isTransparent]="true"
                 startTab="functions">
  <!-- Functions -->
  <ng-template nexnoxWebTabTemplate
               tabId="functions"
               [heading]="'orga-portal.infos.subtitles.info-progress-functions' | translate">
    <nexnox-web-infos-info-progress-function-list [isEmbedded]="true"
                                                  [canCreate]="false"
                                                  [parentIds]="parentIds"
                                                  [getEntityOnModeChange]="false"
                                                  (openSidebar)="openSidebar($event)">
    </nexnox-web-infos-info-progress-function-list>
  </ng-template>

  <!-- Locations -->
  <ng-template nexnoxWebTabTemplate
               tabId="locations"
               [heading]="'orga-portal.infos.subtitles.info-progress-locations' | translate">
    <nexnox-web-infos-info-progress-location-list [isEmbedded]="true"
                                                  [canCreate]="false"
                                                  [parentIds]="parentIds"
                                                  [getEntityOnModeChange]="false"
                                                  (openSidebar)="openSidebar($event)">
    </nexnox-web-infos-info-progress-location-list>
  </ng-template>

  <!-- Contacts -->
  <ng-template nexnoxWebTabTemplate
               tabId="contact"
               [heading]="'orga-portal.infos.subtitles.info-progress-contacts' | translate">
    <nexnox-web-infos-info-progress-contact-list [isRemoveOfExpectedColumn]="true"
                                                 [canCreate]="false"
                                                 [parentIds]="parentIds"
                                                 [getEntityOnModeChange]="false">
    </nexnox-web-infos-info-progress-contact-list>
  </ng-template>

</nexnox-web-tabs>

<nexnox-web-info-progress-sidebar #progressSidebarComponent
                                  [parentIds]="parentIds">
</nexnox-web-info-progress-sidebar>
