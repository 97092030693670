<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            detailLink="/resources"
                            module="inventory"
                            [isEmbedded]="true"
                            [title]="parentResource ? ('resources.subtitles.resource-list-children' | translate:{ parent: parentResource.name }) : ((title | translate) ?? ('resources.subtitles.resource-list-all' | translate))"
                            class="h-100 w-100"
                            [allowMargin]="allowMargin"
                            [disableSavedFilters]="true"
                            [disableSavedSortObject]="true">
</nexnox-web-entity-overview>
