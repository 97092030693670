import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {MessageIssueDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';

@Component({
  selector: 'nexnox-web-issues-message-issue-edit',
  templateUrl: './message-issue-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageIssueEditComponent extends CorePortalEntityEditBaseComponent<MessageIssueDto> {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      ...this.getStereotypeFields(false)
    ];
  }
}
