import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  FilterOperators,
  FilterTypes,
  Mappers,
  ResourceDto
} from '@nexnox-web/core-shared';
import {resourceDetailListStore} from '../../store/stores';

@Component({
	selector: 'nexnox-web-resources-resource-detail-list',
	templateUrl: './resource-detail-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureResourceDetailListComponent extends CorePortalEntityOverviewBaseComponent<ResourceDto> implements OnInit {
	@Input() public parentResource: ResourceDto;
	@Input() public componentId = 'ResourceDetailListComponent';
	@Input() public allowMargin: boolean;

	public title = 'resources.subtitles.resource-list-all';
	public idProperty = 'resourceId';
	public displayProperty = 'name';
	public pageOperation = ControllerOperationId.ResourceControllerList;
	public enableViews = true;
	public datatableConfigName = 'ResourceDefault';

	public shouldClear = true;

	constructor(
		protected injector: Injector
	) {
		super(injector, resourceDetailListStore, Mappers.ResourceListDto.serializedName, AppEntityType.Resource);
	}

	public ngOnInit(): void {
		this.defaultFilter = [{
			property: 'isArchived',
			operator: FilterOperators.Equal,
			type: FilterTypes.DataTransferObject,
			value: 'false'
		}];

		if (this.parentResource) {
			this.defaultFilter.push({
				type: FilterTypes.DataTransferObject,
				operator: FilterOperators.Equal,
				property: 'parentId',
				value: this.parentResource.resourceId?.toString()
			});
		}

		super.ngOnInit();
	}

	/* istanbul ignore next */
	public getRowActionButtons(): DatatableActionButton[] {
		return this.getDefaultRowActionButtons(
			'resources.actions.edit-resource',
			(resource: ResourceDto) => resource?.resourceId ? `/resources/${resource.resourceId}` : null,
			[AppPermissions.UpdateResource],
			{
				module: 'inventory'
			}
		);
	}

	public getDeleteEntityModel(): DeleteEntityModel {
		return {
			deletePermission: AppPermissions.DeleteResource,
			confirmKey: 'resources.actions.delete-resource',
			descriptionKey: 'resources.descriptions.delete-resource',
			titleKey: 'resources.actions.delete-resource'
		};
	}

	protected subscribeToFragment(): void {
		this.subscribe(this.route.fragment, fragment => {
			switch (fragment) {
				case 'create':
					this.setCreateMode();
					break;
				case 'edit':
					this.setEditMode();
					break;
				case null:
					this.setViewMode();
					break;
				default:
					window.location.hash = '';
					this.setViewMode();
					break;
			}
			this.isDeactivateUnsavedChangesModal = false;
		});
	}
}
