<p-panel styleClass="custom-property-panel">
  <ng-template pTemplate="header">
    <div class="d-flex flex-row flex-wrap w-100">
      <div class="d-flex align-items-center flex-wrap position-relative">
        <p-badge *ngIf="!readonly"
                 [value]="('core-shared.shared.custom-property-types.' + customProperty.type) | translate"
                 styleClass="custom-property-panel__header__float-badge p-badge--top bg-primary whitespace-nowrap">
        </p-badge>

        <ng-container *ngIf="readonly">
          <p-badge [value]="('core-shared.shared.custom-property-types.' + customProperty.type) | translate"
                   styleClass="d-flex bg-primary mr-2 align-middle">
          </p-badge>
        </ng-container>
      </div>

      <div class="d-flex flex-grow-1 align-items-center mw-100">
        <nexnox-web-cardbox-actions [actions]="headerActions"
                                    [useNew]="true"
                                    [hasFlexContent]="true"
                                    [readonly]="readonly"
                                    class="w-100">
          <div class="w-100" [style.min-width.px]="50">
            <span *ngIf="readonly; else nameEditTemplate"
                  [title]="customProperty.name"
                  class="text-ellipsis">
              {{ customProperty.name }}
            </span>

            <ng-template #nameEditTemplate>
              <input #nameEditInput
                     pInputText
                     type="text"
                     class="form-control"
                     [required]="true"
                     [style.height.px]="29"
                     [ngModel]="customProperty.name"
                     (ngModelChange)="onNameChange($event)">
            </ng-template>
          </div>
        </nexnox-web-cardbox-actions>
      </div>
    </div>
  </ng-template>

  <form [formGroup]="form">
    <formly-form class="row"
                 [form]="form"
                 [fields]="fields"
                 [model]="customProperty"
                 (modelChange)="onCustomPropertyChange($event)">
    </formly-form>
  </form>
</p-panel>
