import {ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {faFileExport} from '@fortawesome/free-solid-svg-icons/faFileExport';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {
  ActionButton,
  authStore,
  CorePortalAttachmentsComponent,
  CorePortalContactService,
  CorePortalEntityDetailBaseComponent,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {
  AddressSimpleDto,
  AppEntityType,
  AppPermissions,
  AttachmentForTechDto,
  ContactSimpleDto,
  EditorQuestDto,
  FollowUpMissionDto,
  FunctionDto,
  IAssignContactDto,
  IAssignResourcDto,
  LabelDescriptorType,
  LabelDto,
  LabelSimpleDto,
  LinkDto,
  LinkedElementType,
  MissionByInspectionDto,
  MissionDto,
  MissionSimpleDto,
  MissionState,
  MissionType,
  QuestInMissionDto,
  ResourceInMissionDto,
  SuggestedEditorsDto
} from '@nexnox-web/core-shared';
import {TechPortalExportByTemplateModalComponent, TechPortalLinksComponent} from '@nexnox-web/tech-portal-lib';
import {
  documentContextTypeEnumOptions,
  TechPortalFeatureDocumentTemplateService
} from '@nexnox-web/tech-portal/features/templates';
import {select} from '@ngrx/store';
import {isEqual, isNull, isUndefined} from 'lodash';
import {firstValueFrom, Observable, of} from 'rxjs';
import {combineLatestWith, distinctUntilChanged, filter, map, startWith, switchMap, take, tap} from 'rxjs/operators';
import {TechPortalFeatureMissionAssignModalComponent} from '../../modals';
import {missionStateLifecycle, techPortalFeatureMissionStateEnumOptions} from '../../models';
import {
  LocalEditorQuestDto,
  missionDetailStore,
  MissionDetailXsStore,
  missionEditorQuestStore
} from '../../store/stores';
import {ILocalMissionDto, LocalQuestDto} from "../../components";
import {EditorQuestSidebarComponent, EditorSuggestionsSidebarComponent} from "../../sidebars";
import {faPlayCircle, faStamp} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'nexnox-web-missions-mission-detail',
  templateUrl: './mission-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionDetailComponent extends CorePortalEntityDetailBaseComponent<ILocalMissionDto> implements OnInit, OnDestroy {
  @ViewChild('linksComponent') public linksComponent: TechPortalLinksComponent;
  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('solutionAttachmentsComponent') public solutionAttachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('editorSuggestionsSidebar', {static: true}) public editorSuggestionsSidebar: EditorSuggestionsSidebarComponent;
  @ViewChild('editorQuestSidebar', {static: true}) public editorQuestSidebar: EditorQuestSidebarComponent;

  public title = 'missions.subtitles.mission';
  public resourceId: number | string;

  public readSolutionPermission$: Observable<boolean>;
  public displayMissionReceiptsPermission$: Observable<boolean>;
  public readAuditPermission$: Observable<boolean>;
  public displayLabelPermission$: Observable<boolean>;

  public attachments$: Observable<AttachmentForTechDto[]>;
  public solutionAttachments$: Observable<AttachmentForTechDto[]>;
  public attachmentsString$: Observable<string>;
  public links$: Observable<LinkDto[]>;
  public isClosed$: Observable<boolean>;
  public isCompleted$: Observable<boolean>;
  public allowedAddresses$: Observable<AddressSimpleDto[]>;
  public missionType$: Observable<MissionType>;
  public hasPredecessor$: Observable<boolean>;
  public successors$: Observable<MissionSimpleDto[]>;
  public resource$: Observable<ResourceInMissionDto>;
  public solutionContact$: Observable<ContactSimpleDto>;
  public editors$: Observable<SuggestedEditorsDto>;
  public quest$: Observable<EditorQuestDto | LocalEditorQuestDto | QuestInMissionDto>;
  public loadingQuest$: Observable<boolean>;
  public loadingAssignContact$: Observable<boolean>;
  public loadingAssignRessource$: Observable<boolean>;
  public model: ILocalMissionDto;

  public faExclamationTriangle = faExclamationTriangle;

  public linkedElementTypes = LinkedElementType;
  public missionTypes = MissionType;
  public entityTypes = AppEntityType;

  public labelType = LabelDescriptorType.Mission;
  public labels$: Observable<LabelSimpleDto[]>;
  public tenantId$: Observable<number>;
  public isLabelingSince$: Observable<boolean>;

  protected idParam = 'missionId';
  protected displayKey = 'title';
  protected entityStore: MissionDetailXsStore;

  constructor(
    protected injector: Injector,
    private documentTemplateService: TechPortalFeatureDocumentTemplateService,
    private permissionService: CorePortalPermissionService,
    private contactService: CorePortalContactService
  ) {
    super(injector, missionDetailStore);

    this.readSolutionPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadSolution);
    this.displayMissionReceiptsPermission$ = this.permissionService.hasPermission$(AppPermissions.DisplayMissionReceipts);
    this.readAuditPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadAudit);
    this.displayLabelPermission$ = this.permissionService.hasPermission$(AppPermissions.DisplayLabels);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    super.ngOnInit();

    this.labels$ = this.model$.pipe(
      map(model => model?.labels ?? [])
    )

    this.tenantId$ = this.store.pipe(
      select(authStore.selectors.selectTenantId)
    );

    this.isLabelingSince$ = this.model$.pipe(
      map(model => !!model.isLabelingSince)
    )

    // Initialize quest property
    this.quest$ = this.model$.pipe(
      map(model => model?.quest),
      combineLatestWith(this.store.pipe(select(missionEditorQuestStore.selectors.selectModel), map(model => model?.quest))),
      map(([questInMission, questFromStore]) => questFromStore || isNull(questFromStore) ? questFromStore : questInMission),
      distinctUntilChanged((previous, current) => isEqual(previous, current)),
      // Map function to functionProperty
      map((quest) => {
        if (!!quest && !!(quest as any).function) {
          quest = {...quest};
          quest['functionProperty'] = quest['function'];
          delete quest['function'];
        }
        return quest;
      })
    )
    ;

    this.attachments$ = this.model$.pipe(
      map(model => model?.attachments ?? [])
    );

    this.solutionAttachments$ = this.model$.pipe(
      map(model => model?.solution?.attachments ?? [])
    );

    this.attachmentsString$ = this.model$.pipe(
      map(model => {
        const attachments = model?.attachments ?? [];
        const solutionAttachments = model?.solution?.attachments ?? [];
        const sum = (attachments?.length ?? 0) + (solutionAttachments?.length ?? 0);

        if (!sum) return '';
        return ` (${sum})`;
      })
    );

    this.links$ = this.model$.pipe(
      map(model => model?.links ?? [])
    );

    // Completed is now considered as Closed
    this.isClosed$ = this.model$.pipe(
      filter(model => Boolean(model) && !isUndefined(model.state)),
      map(model => model.isCompleted === true),
      switchMap(isClosed => this.permissionService.hasPermission$(AppPermissions.UpdateClosedMission).pipe(
        map(hasPermission => isClosed && !hasPermission)
      ))
    );

    // Indicates completed flag of mission and removes #edit fragment if completed
    this.isCompleted$ = this.model$.pipe(
      map(model => model.isCompleted === true),
      tap(isCompleted => isCompleted ? window.location.hash = '' : null)
    );

    this.allowedAddresses$ = this.model$.pipe(
      map(model => model?.location?.addresses ?? [])
    );

    this.missionType$ = this.model$.pipe(
      map(model => model?.type)
    );

    this.hasPredecessor$ = this.model$.pipe(
      map(model => Boolean(model?.precursor))
    );

    this.successors$ = this.model$.pipe(
      map(model => model?.successors ?? [])
    );

    this.solutionContact$ = this.model$.pipe(
      map(model => model?.solutionContact)
    )

    this.resource$ = this.model$.pipe(
      map(model => model?.resource)
    );

    this.editors$ = this.store.pipe(
      select(missionEditorQuestStore.selectors.selectModel),
      map(model => model?.editors)
    );

    this.loadingQuest$ = this.store.pipe(select(missionEditorQuestStore.selectors.selectLoading));

    this.loadingAssignContact$ = this.store.pipe(
      select(this.entityStore.selectors.selectEntityDataLoading, {key: 'changeContact'}),
      startWith(false)
    )

    this.loadingAssignRessource$ = this.store.pipe(
      select(this.entityStore.selectors.selectEntityDataLoading, {key: 'changeResource'}),
      startWith(false)
    )

    this.subscribe(this.readonly$.asObservable(), () => {
      this.linksComponent?.onReset();
      this.attachmentsComponent?.onReset();
      this.solutionAttachmentsComponent?.onReset();
    });

    this.subscribe(this.resource$, (resource) => this.resourceId = resource?.resourceId);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(missionEditorQuestStore.actions.clear());
  }

  public onMissionStateChange(state: MissionState, isCompleted?: boolean): void {
    if (state) {
      this.store.dispatch(this.entityStore.actions.changeState({state}));
    } else if (isCompleted !== null) {
      isCompleted ? this.onMissionResume() : this.onMissionComplete();
    }
  }

  public onMissionComplete(): void {
    this.store.dispatch(this.entityStore.actions.complete());
  }

  public onMissionResume(): void {
    this.store.dispatch(this.entityStore.actions.resume());
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[], model: MissionDto): void {
    this.onModelChange({...model, attachments});
  }

  public onLinksChange(links: LinkDto[], model: MissionDto): void {
    this.onModelChange({...model, links});
  }

  public onLabelsChange(labels: LabelDto[], model: MissionDto): void {
    this.onModelChange({...model, labels});
  }

  public onAddSuccessors(successors: FollowUpMissionDto[]): void {
    this.store.dispatch(this.entityStore.actions.addFollowUps({missions: successors}));
  }

  public onAssignToAction(): void {
    this.modalService.showModal(TechPortalFeatureMissionAssignModalComponent, instance => {
      instance.service = this.contactService;
    })
      .then(({value: {contact}}) => this.store.dispatch(this.entityStore.actions.assignContact({contact})))
      .catch(() => null);
  }

  public onOpenSuggestionsSidebar(): void {
    this.store.dispatch(missionEditorQuestStore.actions.getSuggestions({resourceId: this.resourceId}));
    this.editorSuggestionsSidebar.onShow();
  }

  public onOpenEditorQuestSidebar(quest: LocalQuestDto): void {
    if (!quest.state) {
      this.onOpenSuggestionsSidebar();
    } else {
      this.store.dispatch(missionEditorQuestStore.actions.getQuest({missionId: this.id}))
      this.editorQuestSidebar.onShow();
    }
  }

  public onStartEditorQuest(functionGroup: FunctionDto): void {
    this.store.dispatch(missionEditorQuestStore.actions.startQuest({
      missionId: this.id,
      functionId: functionGroup.functionId
    }))
  }

  public onCancelEditorQuest(quest: EditorQuestDto): void {
    this.store.dispatch(missionEditorQuestStore.actions.cancelQuest({
      missionId: this.id
    }));
  }

  public onRestartEditorQuest(quest: EditorQuestDto): void {
    this.store.dispatch(missionEditorQuestStore.actions.cancelQuest({
      missionId: this.id
    }));
    this.onOpenSuggestionsSidebar();
  }

  public onDeclineContact(contact: ContactSimpleDto): void {
    this.store.dispatch(missionEditorQuestStore.actions.declineContact({
      missionId: this.id,
      contactId: contact.contactId
    }));
  }

  public onAcceptContact(contact: ContactSimpleDto): void {
    this.store.dispatch(missionEditorQuestStore.actions.acceptContact({
      missionId: this.id,
      contactId: contact.contactId
    }));
    this.store.dispatch(this.entityStore.actions.updateSolutionContactOffline({contact}));
  }

  public onCommissionContact(contact: ContactSimpleDto): void {
    this.store.dispatch(missionEditorQuestStore.actions.commissionContact({
      missionId: this.id,
      contactId: contact.contactId
    }));
  }

  public onAssignResource(payload: IAssignResourcDto): void {
    this.store.dispatch(missionDetailStore.actions.assignResource({
      missionId: this.id,
      resource: payload.resource,
      isUpdateRelations: payload.isUpdateRelations
    }))
  }

  public onAssignContact(payload: IAssignContactDto): void {
    if (payload.isAssignMe) {
      this.store.dispatch(this.entityStore.actions.assignContactToMe({}));
    } else {
      this.store.dispatch(this.entityStore.actions.assignContact({contact: payload.contact}));
    }
  }

  public onUnassignContact(): void {
    this.store.dispatch(this.entityStore.actions.unassignContact({}))
  }

  public convertToInspection(model: ILocalMissionDto): MissionByInspectionDto {
    return model as MissionByInspectionDto;
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    const actionButtons = [
      ...this.getDefaultActionButtons(
        'missions.actions.edit-mission',
        'missions.actions.save-mission',
        'missions.actions.delete-mission',
        'missions.descriptions.delete-mission',
        AppPermissions.UpdateMission,
        AppPermissions.DeleteMission,
        ['/missions'],
        'inventory'
      ),
      {
        label$: this.model$.pipe(
          map(model => {
            switch (model.state) {
              case MissionState.Created:
                return 'missions.mission-states-change.1';
              case MissionState.Canceled:
              case MissionState.Done:
                return model.isCompleted
                  ? 'missions.actions.resume-mission'
                  : 'missions.actions.complete-mission';
              case MissionState.Instructed:
              default:
                return 'missions.mission-states-change.4';
            }
          })
        ),
        type: this.model$.pipe(map(model => (
          model.state === MissionState.Done || model.state === MissionState.Canceled
            ? (model.isCompleted ? 'button' : 'dropdown') : 'dropdown'
        ))),
        icon: this.model$.pipe(map(model => (
          model.state === MissionState.Done || model.state === MissionState.Canceled
            ? (model.isCompleted ? faPlayCircle : faStamp) : faExchangeAlt
        ))),
        class: 'btn-outline-primary',
        permission: AppPermissions.UpdateMission,
        callback: () => {
          firstValueFrom(this.model$.pipe(take(1)))
            .then((model: any) => {
              if (model.state !== MissionState.Done && model.state !== MissionState.Canceled) {
                this.onMissionStateChange(missionStateLifecycle[model.state ?? MissionState.Done][0]);
              } else {
                model.isCompleted ? this.onMissionResume() : this.onMissionComplete();
              }
            })
            .catch(() => null);
        },
        shouldShow: () => this.model$.pipe(
          switchMap(model => {
            if (isUndefined(model?.state)) {
              return of(false);
            }

            if (model.state === MissionState.Canceled || model.state === MissionState.Done) {
              return this.permissionService.hasPermission$(AppPermissions.UpdateClosedMission).pipe(
                map(hasPermission => hasPermission)
              );
            }

            return of(true);
          })
        ),
        isLoading: () => this.store.pipe(
          select(this.entityStore.selectors.selectLoading)
        ),
        isDisabled: () => this.model$.pipe(
          switchMap(model => this.readonly$.asObservable().pipe(
            switchMap(readonly => this.hasModelChanged().pipe(
              map(hasModelChanged => {
                return !model || (!readonly && hasModelChanged);
              })
            ))
          ))
        ),
        isSplitDisabled: () => this.model$.pipe(
          switchMap(model => this.permissionService.hasPermission$(AppPermissions.UpdateClosedMission).pipe(
            map(canUpdateClosedMission =>
              model.state === MissionState.Canceled || model.state === MissionState.Done ? !canUpdateClosedMission : false)
          ))
        ),
        buttons: this.model$.pipe(
          switchMap(model => {
            const states = missionStateLifecycle[model.state ?? 0].map(state =>
              model.isCompleted ? null : techPortalFeatureMissionStateEnumOptions[state]
            ).filter(state => state !== null);
            if ((model.state === MissionState.Done || model.state === MissionState.Canceled)) {
              states.unshift({label: !model.isCompleted ? 'missions.actions.complete-mission' : null, value: null});
            }
            return of(states);
          }),
          map(states => states.map(state => ({
            label: state.value ? `missions.mission-states-change.${state.value}` : state.label,
            type: 'button',
            callback: () => this.onMissionStateChange(state.value, state.label === null)
          })))
        )
      },
      {
        label: 'missions.mission-states-change.activate-and-send-mail',
        type: 'button',
        icon: faPaperPlane,
        class: 'btn-outline-primary',
        permission: AppPermissions.UpdateMission,
        isLoading: () => this.store.pipe(
          select(this.entityStore.selectors.selectLoading)
        ),
        shouldShow: () => this.model$.pipe(
          map(model => model?.state === MissionState.Created)
        ),
        callback: () => this.store.dispatch(this.entityStore.actions.changeState({
          state: MissionState.Instructed,
          sendMail: true
        }))
      },
      {
        label: 'missions.mission-states-change.send-mail',
        type: 'button',
        icon: faPaperPlane,
        class: 'btn-outline-primary',
        permission: AppPermissions.UpdateMission,
        isLoading: () => this.store.pipe(
          select(this.entityStore.selectors.selectEntityDataLoading, {key: 'sendMail'}),
          switchMap(sendMailLoading => this.store.pipe(
            select(this.entityStore.selectors.selectLoading),
            map(loading => loading || sendMailLoading)
          ))
        ),
        shouldShow: () => this.model$.pipe(
          map(model => model?.state === MissionState.Instructed)
        ),
        callback: () => this.store.dispatch(this.entityStore.actions.sendMail())
      },

      {
        label: 'missions.actions.export-mission',
        type: 'button',
        icon: faFileExport,
        class: 'btn-outline-secondary',
        permission: AppPermissions.ReadDocumentTemplate,
        isLoading: () => this.store.pipe(
          select(this.entityStore.selectors.selectEntityDataLoading, {key: 'export'}),
          switchMap(exportLoading => this.store.pipe(
            select(this.entityStore.selectors.selectLoading),
            map(loading => loading || exportLoading)
          ))
        ),
        callback: () => this.store.dispatch(this.entityStore.actions.export({})),
        splitCallback: () => this.modalService.showModal(TechPortalExportByTemplateModalComponent, async instance => {
          instance.service = this.documentTemplateService;

          switch (await firstValueFrom(this.missionType$.pipe(take(1)))) {
            case MissionType.Ticket:
              instance.contextTypes = [
                documentContextTypeEnumOptions[1],
                documentContextTypeEnumOptions[2],
                documentContextTypeEnumOptions[3],
                documentContextTypeEnumOptions[6],
                documentContextTypeEnumOptions[7]
              ];
              break;
            case MissionType.Inspection:
              instance.contextTypes = [
                documentContextTypeEnumOptions[1],
                documentContextTypeEnumOptions[2],
                documentContextTypeEnumOptions[4],
                documentContextTypeEnumOptions[6],
                documentContextTypeEnumOptions[8]
              ];
              break;
            case MissionType.Task:
              instance.contextTypes = [
                documentContextTypeEnumOptions[1],
                documentContextTypeEnumOptions[2],
                documentContextTypeEnumOptions[5],
                documentContextTypeEnumOptions[2],
                documentContextTypeEnumOptions[6],
                documentContextTypeEnumOptions[9]
              ];
              break;
            default:
              instance.contextTypes = [
                documentContextTypeEnumOptions[1],
                documentContextTypeEnumOptions[2]
              ];
          }
        })
          .then(({value: {template}}) => this.store.dispatch(this.entityStore.actions.export({
            templateId: template.documentTemplateId
          })))
          .catch(() => null)
      },

      /* Create Ticket from Mission */
      {
        label: 'missions.actions.create-ticket',
        type: 'button',
        icon: faPlus,
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateTicket,
        isLoading: () => this.loading$,
        callback: () => this.router.navigate(['create-ticket'], {relativeTo: this.route})
      }
    ];

    // Modify edit btn disabled function
    const edit = actionButtons.findIndex(btn => btn.label === 'missions.actions.edit-mission');
    actionButtons[edit].isDisabled = () => this.isCompleted$;

    // Return
    return  actionButtons as ActionButton[];
  }
}
