import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FieldType, FormlyTemplateOptions } from '@ngx-formly/core';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import {
  CustomDropDownDefaultValueDto,
  CustomPropertyType,
  NexnoxWebFaIconString,
  PropertyRating,
  ResizedEvent
} from '@nexnox-web/core-shared';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { map, startWith, take, withLatestFrom } from 'rxjs/operators';
import { cloneDeep, isEqual, isUndefined, orderBy } from 'lodash';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';

export interface FormlyCustomPropertyDropdownTyping {
  isRateable?: boolean;
}

interface FormlyCustomPropertyDropdownTemplateOptions extends FormlyTemplateOptions {
  corePortalCustomPropertyDropdown?: FormlyCustomPropertyDropdownTyping;
}

@Component({
  selector: 'nexnox-web-settings-stereotypes-formly-custom-property-dropdown',
  templateUrl: './formly-custom-property-dropdown.component.html',
  styleUrls: ['./formly-custom-property-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyCustomPropertyDropdownComponent extends FieldType implements OnInit, OnDestroy {
  @ViewChild('addItemInput') public addItemInput: ElementRef;

  @ViewChildren('inputContainer') public inputContainers: QueryList<ElementRef<HTMLSpanElement>>;
  @ViewChildren('buttonsContainer') public buttonsContainers: QueryList<ElementRef<HTMLDivElement>>;

  public to: FormlyCustomPropertyDropdownTemplateOptions;

  public items$: Observable<CustomDropDownDefaultValueDto[]>;
  public itemsDropdownActions$: Observable<MenuItem[][]>;

  public menuButtonsVisibleSubject: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([]);

  public addModel: string = null;

  public trackItemsBy: any;
  public getRatingChangeFn: any;

  public ratingTypes = PropertyRating;

  public faPlus = faPlus;
  public faTrashAlt = faTrashAlt;
  public faArrowUp = faArrowUp;
  public faArrowDown = faArrowDown;
  public faCheck = faCheck;
  public faTimes = faTimes;
  public faCircle = faCircle;
  public faEllipsisV = faEllipsisV;

  private formStateSubscription: Subscription;

  /* istanbul ignore next */
  constructor(
    private translate: TranslateService
  ) {
    super();

    this.trackItemsBy = (index: number, item: CustomDropDownDefaultValueDto) => item.position;
    this.getRatingChangeFn = (item: CustomDropDownDefaultValueDto, rating: PropertyRating) => () => this.onRatingChange(item, rating);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    this.items$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      map(items => orderBy(items, ['position']))
    );

    this.itemsDropdownActions$ = merge(
      this.items$,
      this.translate.onLangChange.pipe(startWith(this.translate.currentLang))
    ).pipe(
      withLatestFrom(this.items$),
      map(([_, items]) => items.map((item, index) => ([
        ...(!item.isSelected ? [{
          label: this.translate.instant('core-shared.shared.actions.select'),
          icon: NexnoxWebFaIconString.transformIcon(faCheck),
          command: () => this.onSelectItem(item)
        }] as MenuItem[] : []),
        ...(item.isSelected ? [{
          label: this.translate.instant('core-shared.shared.actions.deselect'),
          icon: NexnoxWebFaIconString.transformIcon(faTimes),
          command: () => this.onDeselectItem(item)
        }] as MenuItem[] : []),

        ...(this.to.corePortalCustomPropertyDropdown?.isRateable ? [{
          label: this.translate.instant('orga-portal.forms.fields.rating'),
          icon: NexnoxWebFaIconString.transformIcon(faCircle),
          styleClass: `rating-menu p-menuitem__rating-icon--${item.rating === PropertyRating.Ok ? 'success' : 'danger'}`,
          items: [
            {
              label: this.translate.instant('orga-portal.forms.field-ratings.0'),
              icon: NexnoxWebFaIconString.transformIcon(faCircle),
              styleClass: 'p-menuitem__rating-icon--success',
              disabled: item.rating === PropertyRating.Ok,
              command: this.getRatingChangeFn(item, PropertyRating.Ok)
            },
            {
              label: this.translate.instant('orga-portal.forms.field-ratings.2'),
              icon: NexnoxWebFaIconString.transformIcon(faCircle),
              styleClass: 'p-menuitem__rating-icon--danger',
              disabled: item.rating === PropertyRating.Error,
              command: this.getRatingChangeFn(item, PropertyRating.Error)
            }
          ]
        }] as MenuItem[] : []),

        {
          label: this.translate.instant('core-shared.shared.actions.move-up'),
          icon: NexnoxWebFaIconString.transformIcon(faArrowUp),
          disabled: index === 0,
          command: () => this.onMoveItemUp(item)
        },
        {
          label: this.translate.instant('core-shared.shared.actions.move-down'),
          icon: NexnoxWebFaIconString.transformIcon(faArrowDown),
          disabled: index === items.length - 1,
          command: () => this.onMoveItemDown(item)
        },
        {
          label: this.translate.instant('core-shared.shared.actions.remove'),
          icon: NexnoxWebFaIconString.transformIcon(faTrashAlt),
          command: () => this.onRemoveItem(item)
        }
      ])))
    );

    this.formStateSubscription = this.formControl.statusChanges.pipe(
      startWith(this.formControl.status)
    ).subscribe(async () => {
      const items = await this.items$.pipe(take(1)).toPromise();

      for (let i = 0; i < items?.length; i++) {
        const inputContainers = this.inputContainers.toArray();

        if (inputContainers[i]) {
          setTimeout(() => this.onContainerResized({
            element: {
              nativeElement: {
                offsetTop: inputContainers[i].nativeElement.offsetTop
              }
            }
          } as ResizedEvent, i));
        }
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.formStateSubscription && !this.formStateSubscription.closed) this.formStateSubscription.unsubscribe();
  }

  public onValueChange(item: CustomDropDownDefaultValueDto, value: string): void {
    const currentValue: CustomDropDownDefaultValueDto[] = this.formControl.value;
    const itemIndex = this.getItemIndex(currentValue, item);

    currentValue[itemIndex].value = value;
    this.setValue(currentValue);
  }

  public onSelectItem(item: CustomDropDownDefaultValueDto): void {
    const currentFormValue: CustomDropDownDefaultValueDto[] = this.formControl.value;
    const itemIndex = this.getItemIndex(currentFormValue, item);
    const currentSelectedIndex: number = currentFormValue.findIndex(x => x.isSelected);

    currentFormValue[itemIndex].isSelected = true;

    if (currentFormValue[currentSelectedIndex]) {
      currentFormValue[currentSelectedIndex].isSelected = false;
    }

    this.setValue(currentFormValue);
  }

  public onDeselectItem(item: CustomDropDownDefaultValueDto): void {
    const currentFormValue: CustomDropDownDefaultValueDto[] = this.formControl.value;
    const itemIndex = this.getItemIndex(currentFormValue, item);

    currentFormValue[itemIndex].isSelected = false;

    this.setValue(currentFormValue);
  }

  public onRatingChange(item: CustomDropDownDefaultValueDto, rating: PropertyRating): void {
    const currentFormValue: CustomDropDownDefaultValueDto[] = this.formControl.value;
    const itemIndex = this.getItemIndex(currentFormValue, item);

    currentFormValue[itemIndex].rating = rating;

    this.setValue(currentFormValue);
  }

  public onMoveItemUp(item: CustomDropDownDefaultValueDto): void {
    const currentFormValue: CustomDropDownDefaultValueDto[] = this.formControl.value;
    const itemIndex = this.getItemIndex(currentFormValue, item);
    const nextItemIndex = this.getNextItemIndex(item, false);

    const temp = currentFormValue[nextItemIndex].position;
    currentFormValue[nextItemIndex].position = currentFormValue[itemIndex].position;
    currentFormValue[itemIndex].position = temp;

    this.setValue(currentFormValue);
  }

  public onMoveItemDown(item: CustomDropDownDefaultValueDto): void {
    const currentFormValue: CustomDropDownDefaultValueDto[] = this.formControl.value;
    const itemIndex = this.getItemIndex(currentFormValue, item);
    const nextItemIndex = this.getNextItemIndex(item);

    const temp = currentFormValue[nextItemIndex].position;
    currentFormValue[nextItemIndex].position = currentFormValue[itemIndex].position;
    currentFormValue[itemIndex].position = temp;

    this.setValue(currentFormValue);
  }

  public onRemoveItem(item: CustomDropDownDefaultValueDto): void {
    const currentFormValue: CustomDropDownDefaultValueDto[] = this.formControl.value;
    const itemIndex = this.getItemIndex(currentFormValue, item);

    currentFormValue.splice(itemIndex, 1);

    this.setValue(currentFormValue);
  }

  public onAddItem(): void {
    if (!this.addModel) return;
    const currentFormValue: CustomDropDownDefaultValueDto[] = this.formControl.value ?? [];

    currentFormValue.push({
      value: this.addModel,
      isSelected: false,
      position: currentFormValue.length,
      type: CustomPropertyType.Dropdown,
      rating: PropertyRating.Ok
    } as CustomDropDownDefaultValueDto);

    this.addModel = null;
    this.setValue(currentFormValue);
    this.addItemInput?.nativeElement?.focus();
  }

  public onContainerResized(resizedEvent: ResizedEvent, index: number): void {
    const buttonsContainers = this.buttonsContainers.toArray();
    if (!isUndefined(buttonsContainers[index])) {
      const containerTop = resizedEvent.element.nativeElement.offsetTop;
      const buttonsTop = buttonsContainers[index].nativeElement.offsetTop;
      const visible = Math.abs(buttonsTop - containerTop) > 6;

      const menuButtonsVisible = cloneDeep(this.menuButtonsVisibleSubject.getValue());
      menuButtonsVisible[index] = visible;
      if (!isEqual(menuButtonsVisible, this.menuButtonsVisibleSubject.getValue())) this.menuButtonsVisibleSubject.next(menuButtonsVisible);
    }
  }

  private getItemIndex(currentFormValue: CustomDropDownDefaultValueDto[], item: CustomDropDownDefaultValueDto): number {
    return currentFormValue.findIndex(x => x.position === item.position);
  }

  private getNextItemIndex(item: CustomDropDownDefaultValueDto, next: boolean = true): number {
    const currentFormValue: CustomDropDownDefaultValueDto[] = this.formControl.value;
    return currentFormValue.findIndex(x => x.position === (next ? item.position + 1 : item.position - 1));
  }

  private setValue(value: CustomDropDownDefaultValueDto[]): void {
    this.formControl.setValue(value);
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();
  }
}
