import { ResourcesGuiXsStore, ResourcesGuiXsStoreActions, ResourcesGuiXsStoreState } from './resources-gui.xs-store';
import { selectResourcesState } from '../../resources.selectors';
import { Action, createSelector } from '@ngrx/store';
import { Injectable, Injector } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TechPortalFeatureResourceService } from '../../services';
import { of } from 'rxjs';

export interface ResourcesGuiStoreState extends ResourcesGuiXsStoreState {
}

export const resourcesGuiStore = new ResourcesGuiXsStore({
  actionLabel: 'Core Portal - Resources - Resources GUI',
  stateSelector: createSelector(selectResourcesState, state => state.resourcesGui)
});

export function resourcesGuiStoreReducer(state: ResourcesGuiStoreState, action: Action): any {
  return resourcesGuiStore.reducer(state, action);
}

@Injectable()
export class ResourcesGuiStoreEffects extends resourcesGuiStore.effects {
  public selectById$: any;

  public actions: ResourcesGuiXsStoreActions;

  constructor(
    protected injector: Injector,
    private service: TechPortalFeatureResourceService
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.selectById$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.selectById),
      switchMap(({ resourceId, from }) => {
        if (resourceId === null) {
          return of(this.actions.selectByIdSuccess({ resource: null }));
        }

        return this.service.getOne(resourceId).pipe(
          map(resource => this.actions.selectByIdSuccess({ resource, from })),
          catchError(error => of(this.actions.error({ error, action: this.actions.selectById })))
        );
      })
    ));
  }
}
