import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {resourcesGuiStore} from './resources-gui.store';
import {filter, map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {resourceListStore} from '../resource-list/resource-list.store';
import {resourceDetailStore} from '../resource-detail/resource-detail.store';
import {Router} from '@angular/router';
import {resourceDetailListStore} from '../resource-detail-list/resource-detail-list.store';
import {CorePortalTenantRouter} from '@nexnox-web/core-portal';

@Injectable()
export class ResourcesGuiBridgeEffects {
  public selectByIdError$ = createEffect(() => this.actions$.pipe(
    ofType(resourcesGuiStore.actions.error),
    filter(({ action }) => action.type === resourcesGuiStore.actions.selectById.type),
    map(() => {
      this.tenantRouter.navigate([`/resources`], { module: 'inventory' });
      return resourcesGuiStore.actions.selectByIdSuccess({ resource: null });
    })
  ));

  public selectById$ = createEffect(() => this.actions$.pipe(
    ofType(resourcesGuiStore.actions.selectByIdSuccess),
    filter(({ from }) => Boolean(from)),
    tap(({ resource }) => this.tenantRouter.navigate(['/resources', resource.resourceId], { module: 'inventory' }))
  ), { dispatch: false });

  public listSelectById$ = createEffect(() => this.actions$.pipe(
    ofType(resourcesGuiStore.actions.selectByIdSuccess),
    filter(({ from }) => from !== 'list'),
    tap(({ resource }) => {
      if (resource) {
        this.store.dispatch(resourceListStore.actions.preload({ resource }));
      } else {
        this.store.dispatch(resourceListStore.actions.getPage());
      }
    })
  ), { dispatch: false });

  public detailSelectById$ = createEffect(() => this.actions$.pipe(
    ofType(resourcesGuiStore.actions.selectByIdSuccess),
    tap(({ resource }) => {
      if (resource) {
        this.store.dispatch(resourceDetailStore.actions.getStereotypes({}));
        this.store.dispatch(resourceDetailStore.actions.getSuccess({ entity: resource, model: resource }));
      } else {
        this.store.dispatch(resourceDetailStore.actions.clear());
        this.store.dispatch(resourceDetailStore.actions.getStereotypes({ excludeArchived: true }));
      }
    })
  ), { dispatch: false });

  public detailListDelete$ = createEffect(() => this.actions$.pipe(
    ofType(resourceDetailListStore.actions.deleteOneSuccess),
    tap(({ id }) => this.store.dispatch(resourceListStore.actions.deleteOneSuccess({ id })))
  ), { dispatch: false });

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private tenantRouter: CorePortalTenantRouter
  ) {
  }
}
