<p-sidebar #sidebarComponent
           position="right"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           appendTo="body"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <h4>{{ 'core-shared.shared.datatable-settings.title' | translate }}</h4>

  <div class="d-flex flex-grow-1 flex-column h-100 mt-1 overflow-auto px-1">
    <div class="d-flex flex-column">
      <div class="form-group w-100">
        <label for="page-size-input"
               class="col-form-label pl-0">
          {{ 'core-shared.shared.table.subtitles.datatable-views.page-size' | translate }}
        </label>

        <input id="page-size-input"
               type="number"
               class="form-control"
               [disabled]="(loading$ | async) || disableDatatableViews"
               [ngModel]="pageSizeSubject | async"
               (ngModelChange)="pageSizeSubject.next($event)"
               required>
      </div>

      <div *ngIf="enableViews" class="form-group w-100">
        <label for="view-select"
               class="col-form-label pl-0">
          {{ 'core-shared.shared.table.subtitles.datatable-views.datatable-view' | translate }}
        </label>

        <div id="view-select" class="d-flex flex-column h-fit-content">
          <nexnox-web-entity-select class="w-100"
                                    [model]="selectedDatatableView$ | async"
                                    [options]="datatableViewSelectOptions"
                                    [disabled]="disableDatatableViews || disableDatatableViewSelect || (loading$ | async)"
                                    [addTagFn]="onSaveCurrentConfigAsNewFn"
                                    (modelChange)="onSelectedDatatableViewChange($event)">
          </nexnox-web-entity-select>

          <div class="d-flex flex-row mt-2">
            <button *ngIf="(selectedDatatableView$ | async)?.dataTableId"
                    type="button"
                    pButton
                    [label]="'core-shared.shared.datatable-settings.actions.reset' | translate"
                    [icon]="(loading$ | async) ? ((faSpinner | nexnoxWebFaIconStringPipe) + ' fa-spin') : (faSync | nexnoxWebFaIconStringPipe)"
                    [disabled]="loading$ | async"
                    class="p-button-text p-button-secondary mr-2"
                    (click)="onReset()">
            </button>

            <button
              *ngIf="(selectedDatatableView$ | async)?.dataTableId && !disableDatatableViews && !disableDatatableViewSelect"
              type="button"
              pButton
              [icon]="(loading$ | async) ? ((faSpinner | nexnoxWebFaIconStringPipe) + ' fa-spin') : (faTrashAlt | nexnoxWebFaIconStringPipe)"
              [label]="'core-shared.shared.datatable-settings.actions.delete-view' | translate"
              class="p-button-text p-button-danger"
              [disabled]="loading$ | async"
              (click)="onDeleteCurrentConfig()">
            </button>
          </div>
        </div>
      </div>
    </div>

    <nexnox-web-tabs #tabsComponent
                     [isTransparent]="true"
                     [internal]="true"
                     startTab="columns"
                     class="w-100">
      <ng-template nexnoxWebTabTemplate
                   tabId="columns"
                   [heading]="'core-shared.shared.datatable-settings.tabs.columns' | translate"
                   [hasError]="(columnsValid$ | async) === false">
        <nexnox-web-sortable-list [items]="columnsItems$ | async"
                                  [sortable]="true"
                                  [hideItemName]="true"
                                  [disabled]="(loading$ | async) || disableDatatableViews"
                                  (itemsChange)="onColumnsChange($event)">
          <!-- Columns -->
          <ng-template nexnoxWebSortableListItemTemplate let-listItem="item">
            <ng-select [items]="availableColumns$ | async"
                       [clearable]="false"
                       [searchable]="true"
                       [searchFn]="searchColumnFn"
                       [disabled]="loading$ | async"
                       groupBy="option"
                       [ngModel]="listItem.identifier"
                       bindValue="identifier"
                       class="d-flex ng-form-select flex-grow-1"
                       [class.is-invalid]="listItem.option === undefined"
                       (ngModelChange)="onColumnChange(listItem, $event)">
              <ng-template ng-label-tmp let-item="item">
                <span *ngIf="item.option === undefined; else labelTemplate"></span>

                <ng-template #labelTemplate>
                  <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate">
                    {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
                  </span>
                </ng-template>

                <ng-template #stereotypeTemplate>
                  <span>{{ item.name }}</span>
                </ng-template>
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-searchTerm="searchTerm">
                <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate"
                      [title]="'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate"
                      [innerHTML]="'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate | nexnoxWebHighlight:searchTerm">
                </span>

                <ng-template #stereotypeTemplate>
                  <span [title]="item.name" [innerHTML]="item.name | nexnoxWebHighlight:searchTerm"></span>
                </ng-template>
              </ng-template>

              <ng-template ng-optgroup-tmp let-item="item">
                {{ 'core-shared.shared.column-types.' + item.option | translate }}
              </ng-template>
            </ng-select>
          </ng-template>
        </nexnox-web-sortable-list>

        <button type="button"
                pButton
                [icon]="faPlus | nexnoxWebFaIconStringPipe"
                [label]="'core-shared.shared.datatable-settings.actions.add-column' | translate"
                [disabled]="loading$ | async"
                class="p-button-text p-button-secondary mt-2"
                (click)="onAddColumn()">
        </button>
      </ng-template>

      <!-- Sorting -->
      <ng-template nexnoxWebTabTemplate
                   tabId="sorting"
                   [heading]="'core-shared.shared.datatable-settings.tabs.sorting' | translate"
                   [hasError]="(sortingValid$ | async) === false">
        <div *ngIf="sortObject$ | async as sortObject; else noSortingTemplate"
             class="d-flex flex-row">
          <ng-select [items]="sortableColumns$ | async"
                     [clearable]="false"
                     [searchable]="false"
                     [disabled]="loading$ | async"
                     groupBy="option"
                     [ngModel]="sortObject.sortField"
                     bindValue="identifier"
                     class="d-flex ng-form-select flex-grow-1"
                     [class.is-invalid]="!sortObject.sortField"
                     (ngModelChange)="onSortFieldChanged($event)">
            <ng-template ng-label-tmp let-item="item">
              <span *ngIf="item.option === undefined; else labelTemplate"></span>

              <ng-template #labelTemplate>
                <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate">
                  {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
                </span>
              </ng-template>

              <ng-template #stereotypeTemplate>
                <span>{{ item.name }}</span>
              </ng-template>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate"
                    [title]="'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate">
                {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
              </span>

              <ng-template #stereotypeTemplate>
                <span [title]="item.name">{{ item.name }}</span>
              </ng-template>
            </ng-template>

            <ng-template ng-optgroup-tmp let-item="item">
              {{ 'core-shared.shared.column-types.' + item.option | translate }}
            </ng-template>
          </ng-select>

          <button type="button"
                  pButton
                  [icon]="(sortObject.sort === sortOrders.Ascending ? faSortAmountUpAlt : faSortAmountDown) | nexnoxWebFaIconStringPipe"
                  [title]="'core-shared.shared.datatable-settings.actions.change-sort' | translate"
                  [disabled]="loading$ | async"
                  class="p-button-text p-button-secondary ml-2"
                  (click)="onSortOrderChanged()">
          </button>

          <button type="button"
                  pButton
                  [icon]="faTimes | nexnoxWebFaIconStringPipe"
                  [title]="'core-shared.shared.table.tooltip.remove' | translate"
                  [disabled]="loading$ | async"
                  class="p-button-text p-button-secondary ml-2"
                  (click)="onRemoveSortObject()">
          </button>
        </div>

        <ng-template #noSortingTemplate>
          <div>{{ 'core-shared.shared.table.empty' | translate }}</div>
        </ng-template>

        <button type="button"
                pButton
                [icon]="faPlus | nexnoxWebFaIconStringPipe"
                [label]="'core-shared.shared.datatable-settings.actions.add-column' | translate"
                [disabled]="!!(sortObject$ | async) || (loading$ | async)"
                class="p-button-text p-button-secondary mt-2"
                (click)="onAddSortObject()">
        </button>
      </ng-template>

      <!-- Filters -->
      <ng-template nexnoxWebTabTemplate
                   tabId="filters"
                   [heading]="'core-shared.shared.datatable-settings.tabs.filters' | translate"
                   [hasError]="(filtersValid$ | async) === false">
        <span *ngIf="!(filters$ | async)?.length">{{ 'core-shared.shared.table.empty' | translate }}</span>

        <p-tree [value]="filterTreeItems$ | async"
                [trackBy]="filterTrackByFn"
                styleClass="p-2 custom-tree"
                [class.is-empty]="!(filters$ | async)?.length">
          <ng-template pTemplate="default" let-node>
            <div class="d-flex flex-column ml-2">
              <div class="d-flex flex-row">
                <ng-select *ngIf="!node.data.isGrouped"
                           [items]="allColumns$ | async"
                           [clearable]="false"
                           [searchable]="true"
                           [searchFn]="searchFilterFn"
                           [disabled]="loading$ | async"
                           groupBy="option"
                           [ngModel]="node.data.property"
                           bindValue="identifier"
                           class="d-flex ng-form-select flex-grow-1"
                           [class.is-invalid]="!node.data.hasProperty"
                           (ngModelChange)="onFilterChanged(node.data.parent, node.data.index, { property: $event, value: null })">
                  <ng-template ng-label-tmp let-item="item">
                    <span *ngIf="item.option === undefined; else labelTemplate"></span>

                    <ng-template #labelTemplate>
                    <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate">
                      {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
                    </span>
                    </ng-template>

                    <ng-template #stereotypeTemplate>
                      <span>{{ item.name }}</span>
                    </ng-template>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                  <span *ngIf="item.option !== columnOptions.STEREOTYPE; else stereotypeTemplate"
                        [title]="'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate">
                    {{ 'core-shared.shared.fields.' + (item.name | nexnoxWebDashCase) | translate }}
                  </span>

                    <ng-template #stereotypeTemplate>
                      <span [title]="item.name">{{ item.name }}</span>
                    </ng-template>
                  </ng-template>

                  <ng-template ng-optgroup-tmp let-item="item">
                    {{ 'core-shared.shared.column-types.' + item.option | translate }}
                  </ng-template>
                </ng-select>

                <ng-select *ngIf="node.data.isGrouped"
                           [items]="combinedAsItems"
                           [clearable]="false"
                           [searchable]="false"
                           [disabled]="loading$ | async"
                           [ngModel]="node.data.combinedAs"
                           bindValue="value"
                           class="d-flex ng-form-select flex-grow-1"
                           [class.is-invalid]="!node.data.hasCombinedAs || !node.data.hasChildren"
                           (ngModelChange)="onFilterChanged(node.data.parent, node.data.index, { combinedAs: $event })">
                  <ng-template ng-label-tmp let-item="item">
                    <span [title]="item.label | translate">{{ item.label | translate }}</span>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <span [title]="item.label | translate">{{ item.label | translate }}</span>
                  </ng-template>
                </ng-select>

                <div class="d-flex ml-2">
                  <button *ngIf="!node.data.isGrouped && node.data.canAdd"
                          type="button"
                          pButton
                          [icon]="faObjectGroup | nexnoxWebFaIconStringPipe"
                          [title]="'core-shared.shared.datatable-settings.actions.make-group' | translate"
                          [disabled]="loading$ | async"
                          class="p-button-text p-button-secondary"
                          (click)="onFilterChanged(node.data.parent, node.data.index, { type: filterTypes.Grouped, combinedAs: combinedAs.Or, property: undefined, value: undefined, operator: undefined, children: [node.data.filter] })">
                  </button>

                  <button type="button"
                          pButton
                          [icon]="faTimes | nexnoxWebFaIconStringPipe"
                          [title]="'core-shared.shared.table.tooltip.remove' | translate"
                          [disabled]="loading$ | async"
                          class="p-button-text p-button-secondary"
                          [class.ml-2]="!node.data.isGrouped && node.data.canAdd"
                          (click)="onRemoveFilter(node.data.filter)">
                  </button>
                </div>
              </div>

              <div *ngIf="!node.data.isGrouped" class="d-flex flex-row mt-2">
                <nexnox-web-entity-datatable-filter *ngIf="node.data.filterColumn"
                                                    [clearable]="false"
                                                    [showAll]="true"
                                                    [allowRelative]="true"
                                                    [isDatatableSettings]="true"
                                                    [filter]="node.data.filter"
                                                    [disabled]="loading$ | async"
                                                    [column]="node.data.filterColumn"
                                                    [showError]="!node.data.hasValue"
                                                    class="w-100"
                                                    [templates]="templates"
                                                    (searchBy)="filterChangeSubject.next($any({ parentFilter: node.data.parent, index: node.data.index, changes: $event }))">
                </nexnox-web-entity-datatable-filter>
              </div>
            </div>
          </ng-template>

          <ng-template pTemplate="add" let-node>
            <button type="button"
                    pButton
                    class="p-button-text p-button-secondary"
                    [label]="'core-shared.shared.datatable-settings.actions.add-filter' | translate"
                    [icon]="faPlus | nexnoxWebFaIconStringPipe"
                    (click)="onAddFilter(node.data)">
            </button>
          </ng-template>
        </p-tree>
      </ng-template>
    </nexnox-web-tabs>
  </div>

  <div class="d-flex flex-shrink-0 justify-content-end pt-3">
    <button type="button"
            pButton
            [label]="'core-shared.shared.datatable-settings.actions.apply' | translate"
            [icon]="(loading$ | async) ? ((faSpinner | nexnoxWebFaIconStringPipe) + ' fa-spin') : (faCheck | nexnoxWebFaIconStringPipe)"
            class="p-button-outlined p-button-primary ml-2"
            [disabled]="(valid$ | async) === false || (loading$ | async)"
            (click)="onApply()">
    </button>

    <button
      *ngIf="(selectedDatatableView$ | async)?.dataTableId && !disableDatatableViews && !disableDatatableViewSelect"
      type="button"
      pButton
      [label]="'core-shared.shared.datatable-settings.actions.save-and-apply' | translate"
      [icon]="(loading$ | async) ? ((faSpinner | nexnoxWebFaIconStringPipe) + ' fa-spin') : (faSave | nexnoxWebFaIconStringPipe)"
      class="p-button-primary ml-2"
      [disabled]="(valid$ | async) === false || (loading$ | async)"
      (click)="onSaveAndApply()">
    </button>
  </div>
</p-sidebar>
