import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HierarchicalTreeViewComponent, HierarchicalTreeViewItemComponent } from './components';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GhostHierarchicalTreeViewChildrenComponent, GhostHierarchicalTreeViewComponent } from './ghosts';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const exportedComponents = [
  HierarchicalTreeViewComponent,
  HierarchicalTreeViewItemComponent
];

const exportedGhosts = [
  GhostHierarchicalTreeViewComponent,
  GhostHierarchicalTreeViewChildrenComponent
];

@NgModule({
  declarations: [
    ...exportedComponents,
    ...exportedGhosts
  ],
  imports: [
    CommonModule,
    CollapseModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    FontAwesomeModule
  ],
  exports: [
    ...exportedComponents,
    ...exportedGhosts
  ]
})
export class CorePortalHierarchicalTreeViewModule {
}
