<nexnox-web-cardbox [title]="title"
                    [headerActions]="headerActions"
                    [withAccent]="!isSkeleton"
                    [loading]="loading"
                    paddingClasses="px-2"
                    ghostPaddingClasses="px-3 pb-3">
  <!-- Add Article -->
  <div *ngIf="!readonly" class="mb-3">
    <form [formGroup]="addArticleForm">
      <formly-form class="row w-100"
                   [form]="addArticleForm"
                   [model]="addArticleModelSubject | async"
                   [fields]="addArticlesFields"
                   (modelChange)="addArticleModelSubject.next($event)">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button type="button"
              pButton
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.actions.add' | translate"
              class="p-button-secondary p-button-outlined mr-2"
              [label]="'missions.actions.mission-report-add-article' | translate"
              [disabled]="addArticleForm.invalid"
              (click)="onAddArticle()">
      </button>
    </div>
  </div>

  <!-- Articles -->
  <div [dndDropzone]="['mission-report-edit-group']"
       [dndDisableIf]="readonly"
       [dndDisableDropIf]="readonly"
       [dndEffectAllowed]="!readonly ? 'move' : 'none'"
       class="pt-2 px-2 pb-3"
       (dndDrop)="onDropGroup($event)">
    <span *ngIf="!(missionReportGroups$ | async).length">
      {{ 'core-shared.shared.table.empty' | translate }}
    </span>

    <div dndPlaceholderRef class="placeholder mb-4"></div>

    <div *ngFor="let group of (missionReportGroups$ | async); trackBy: trackGroupsByFn; let isLast = isLast"
         [dndDraggable]="group"
         dndType="mission-report-edit-group"
         [dndDisableIf]="readonly"
         [dndDisableDragIf]="readonly"
         [dndEffectAllowed]="!readonly ? 'move' : 'none'"
         class="group-draggable"
         [class.mb-4]="!isLast">
      <div class="d-flex row align-items-center w-100 mb-2">
        <div *ngIf="!readonly"
             dndHandle
             class="d-flex flex-shrink-0 align-items-center justify-content-center item-handle mr-2">
          <fa-icon [icon]="faGripVertical"></fa-icon>
        </div>

        <h5 class="col-12 col-sm-2 mb-0">{{ group.name }}</h5>

        <div class="col row d-flex align-items-center px-0">
          <div class="col-6 pl-4 pr-0">{{ 'core-shared.shared.fields.count' | translate }}</div>

          <div class="col-6 px-0">{{ 'core-shared.shared.fields.single-price' | translate }}</div>
        </div>

        <span *ngIf="!isSkeleton" class="d-flex col-3 flex-shrink-0 align-items-center justify-content-end">
          <span>{{ 'missions.subtitles.mission-report-group-total' | translate }}: {{ group.total | nexnoxWebLocaleStringPipe:2 }} €</span>
        </span>
      </div>

      <nexnox-web-sortable-list [items]="group.usedArticles"
                                [sortable]="true"
                                dropType="mission-report-edit-article-{{ group.name }}"
                                [editable]="false"
                                [disabled]="readonly"
                                [hideItemName]="true"
                                [notTitle]="true"
                                (itemsChange)="onArticlesChange(group, $event)">
        <ng-template nexnoxWebSortableListItemTemplate let-item="item" let-position="position">
          <div class="row no-gutters w-100 align-items-center">
            <div class="col-12 col-sm-2 flex-nowrap overflow-hidden text-ellipsis">
              <p-toggleButton #toggleNoteButton
                              styleClass="p-button-sm p-button-text p-button-icon-only p-togglebutton-plain"
                              [class.d-none]="isSkeleton"
                              [ngModel]="item.model?.note"
                              [onIcon]="faCaretUp | nexnoxWebFaIconStringPipe"
                              [offIcon]="faCaretDown | nexnoxWebFaIconStringPipe">
              </p-toggleButton>

              <span class="col-12 col-sm-2"
                    [title]="item.model?.article?.name">
                {{ item.model?.article?.name }}
              </span>
            </div>

            <ng-container *ngIf="missionReportForms && missionReportForms[group.name] && missionReportForms[group.name][position]">
              <form [formGroup]="missionReportForms[group.name][position]"
                    class="col">
                <formly-form class="row w-100 align-items-center"
                             [form]="missionReportForms[group.name][position]"
                             [model]="item.model"
                             [fields]="item.fields"
                             (modelChange)="onArticleChange(group, position, $event)">
                </formly-form>
              </form>
            </ng-container>

            <span *ngIf="!isSkeleton" class="d-flex justify-content-end align-items-center col-3 flex-shrink-0">
              {{ item.model.total | nexnoxWebLocaleStringPipe:2 }} €
            </span>
          </div>

          <div *ngIf="!isSkeleton && toggleNoteButton.checked"
               class="row w-100 align-items-center">
            <div class="col-12 pt-2 px-0">
              <textarea pInputTextarea
                        class="p-inputtext-sm w-100"
                        [placeholder]="'core-shared.shared.fields.note' | translate"
                        [rows]="2"
                        [readOnly]="readonly"
                        [ngModel]="item.model?.note"
                        (ngModelChange)="onChangeArticleNote(group, position, $event, item.model)">
              </textarea>
            </div>
          </div>
        </ng-template>
      </nexnox-web-sortable-list>
    </div>

    <ng-container *ngIf="!isSkeleton && (missionReportGroups$ | async).length">
      <hr>
      <div class="d-flex justify-content-end w-100">
        <b>{{ 'missions.subtitles.mission-report-total' | translate }}: <u>{{ missionReportTotal$ | async }} €</u></b>
      </div>
    </ng-container>
  </div>
</nexnox-web-cardbox>

<nexnox-web-entity-edit *ngIf="!isSkeleton"
                        [entityEditBaseComponent]="this"
                        [isTitleEditable]="false"
                        [useTitle]="false"
                        [customTitle]="'core-portal.settings.title' | translate"
                        [hideAccent]="true"
                        [disableActions]="true">
</nexnox-web-entity-edit>
