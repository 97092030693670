<div *ngIf="item"
     class="tree-view-item"
     [class.has-children]="item.hasChildren">
  <div #treeViewItemElement class="d-flex tree-view-item-element"
       [class.is-selected]="isSelected | async"
       [class.is-disabled]="disabled"
       [title]="item.name"
       (click)="!disabled ? onClick() : null">
    <div class="tree-view-item-content" [style.padding-left]="(nestLevel * 1.25) + 'rem'">
      <div class="tree-view-item-state-icon" [class.opacity-0]="!item.hasChildren">
        <em *ngIf="(itemChildrenLoading$ | async) === false" class="tree-view-item-expand-icon"
            [class.ion-ios-arrow-forward]="(isExpanded | async) === false"
            [class.ion-ios-arrow-down]="(isExpanded | async)">
        </em>

        <fa-icon *ngIf="itemChildrenLoading$ | async"
                 [icon]="faSpinner"
                 [spin]="true"
                 [fixedWidth]="true"
                 [classes]="['tree-view-item-loading-icon']">
        </fa-icon>
      </div>


      <div class="tree-view-item-text">
        <fa-icon *ngIf="item.icon" [icon]="item.icon" [size]="'sm'" class="mr-2"></fa-icon>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>

  <div class="tree-view-item-children"
       [class.expanded]="isExpanded | async">
    <ng-container *ngIf="item.hasChildren">
      <div *ngIf="(itemChildrenLoading$ | async) === false" class="tree-view-item-children-inner-container">
        <ng-container *ngFor="let child of itemChildren$ | async; trackBy: trackItemsById">
          <nexnox-web-hierarchical-tree-view-item #hierarchicalTreeViewItemComponent
                                                  [item]="child"
                                                  [nestLevel]="nestLevel + 1"
                                                  [parent]="this"
                                                  [itemSelectedEvent]="itemSelectedEvent"
                                                  [boundingElement]="boundingElement"
                                                  [canSelectItem]="canSelectItem"
                                                  (selectItem)="onChildSelectedItem($event)"
                                                  (expandItem)="onChildExpandedItem($event)"
                                                  (moreItem)="onChildMoreItem($event)">
          </nexnox-web-hierarchical-tree-view-item>
        </ng-container>

        <ng-container *ngIf="item.getPaging && item.getPaging() | async as paging">
          <ng-container *ngIf="paging.pageNumber < paging.totalPages">
            <div *ngIf="(itemChildrenNextLoading$ | async) === false; else loadingTemplate"
                 class="more-btn"
                 [style.padding-left]="getMoreButtonPaddingLeft(nestLevel)"
                 (click)="onMore()">
              <fa-icon [icon]="faPlus" class="d-flex align-items-center"></fa-icon>
              <span class="ml-2">{{ 'core-shared.shared.pagination.more' | translate }}</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #loadingTemplate>
      <nexnox-web-ghost-hierarchical-tree-view-children [nestLevel]="nestLevel + 1">
      </nexnox-web-ghost-hierarchical-tree-view-children>
    </ng-template>
  </div>
</div>
