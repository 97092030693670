import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, IssueContentType, IssueTemplateListDto, Mappers} from '@nexnox-web/core-shared';
import {issueTemplateListStore} from '../../store/stores';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {BehaviorSubject, of} from "rxjs";
import {delay, map, mergeMap} from "rxjs/operators";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {issueContentTypeEnumOptions} from "../../models";

@Component({
  selector: 'nexnox-web-settings-issue-template-list',
  templateUrl: './issue-template-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssueTemplateListComponent extends CorePortalEntityOverviewBaseComponent<IssueTemplateListDto> implements OnInit {
  public title = 'core-portal.settings.issue-template.titles.list';
  public createTitle = 'core-portal.settings.issue-template.actions.create';
  public idProperty = 'issueTemplateId';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsIssueDefault';
  public componentId = 'IssueListComponent';

  public createIssueContentType: BehaviorSubject<IssueContentType> = new BehaviorSubject<IssueContentType>(null);

  constructor(
    protected injector: Injector
  ) {
    super(injector, issueTemplateListStore, Mappers.IssueTemplateListDto.serializedName, AppEntityType.IssueTemplate);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    // Resets multi create button
    // Redirects to list when #create mode but content type is null
    this.subscribe(this.isCreateVisible$,
        isCreate => isCreate ? this.createIssueContentType.getValue() === null ? window.location.hash = '' : undefined : this.createIssueContentType.next(null)
    )
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.settings.issue-template.actions.edit',
      (row: IssueTemplateListDto) => `issues/${row.issueTemplateId}`,
      [AppPermissions.UpdateIssueTemplate],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.issue-template.actions.delete',
      descriptionKey: 'core-portal.settings.issue-template.descriptions.delete',
      confirmKey: 'core-portal.settings.issue-template.actions.delete',
      deletePermission: AppPermissions.DeleteIssueTemplate
    };
  }

  public async getActionButtons(): Promise<ActionButton[]> {

    return [
      // Cancel button
      {
        label: 'core-portal.core.general.cancel',
        type: 'button',
        class: 'btn-outline-secondary',
        icon: faTimesCircle,
        shouldShow: () => this.isCreateVisible$,
        callback: () => this.onCancelAction()
      },
      // Multi create button / dropdown
      {
        icon: faPlus,
        class: 'btn-primary',
        label$: this.createIssueContentType.asObservable().pipe(
          map(issueContentType => {
            switch (issueContentType) {
              case IssueContentType.Message:
                return 'core-portal.settings.issue-template.actions.create-message-template';
              case IssueContentType.Mission:
                return 'core-portal.settings.issue-template.actions.create-mission-template';
              case IssueContentType.Ticket:
                return 'core-portal.settings.issue-template.actions.create-ticket-template';
              case IssueContentType.WinterhalterServiceCall:
                return 'core-portal.settings.issue-template.actions.create-winterhalter-template';
              default:
                return 'core-portal.settings.issue-template.actions.create';
            }
          })
        ),
        isLoading: () => this.loading$,
        isDisabled: () => this.isCreateVisible$.asObservable().pipe(
          delay(0),
          mergeMap(isCreateVisible => this.createModel$.asObservable().pipe(
            map(() => isCreateVisible && (!this.editComponent || !this.editComponent.isModelValid()))
          ))
        ),
        permission: AppPermissions.CreateIssueTemplate,
        callback: this.createIssueContentType.getValue !== null ? () => this.onCreate() : null,
        type: this.createIssueContentType.asObservable().pipe(
          map(issueContentType => issueContentType === null ? 'dropdown' : 'button')
        ),
        buttons: of(issueContentTypeEnumOptions.map(i => ({
          label: i.createLabel,
          type: 'button',
          permission: i.permission,
          shouldShow: () => this.permissionService.hasPermissions$([i.permission]),
          callback: () => this._setCreateModeWithType(i.value)
        }) as ActionButton))
      },
    ]
  }

  private _setCreateModeWithType(type: IssueContentType): void {
    this.createIssueContentType.next(type);
    setTimeout(() => this.onCreateAction());
  }
}
