import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import {AppPermissions, DocumentTemplateDto, ExportType} from '@nexnox-web/core-shared';
import { documentTemplateDetailStore, DocumentTemplateDetailXsStore } from '../../../store';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { map, startWith, take } from 'rxjs/operators';
import { select } from '@ngrx/store';
import {TemplateUsageTypes} from "@nexnox-web/tech-portal/features/templates/src/lib/models";

@Component({
  selector: 'nexnox-web-document-template-detail',
  templateUrl: './document-template-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTemplateDetailComponent extends CorePortalEntityDetailBaseComponent<DocumentTemplateDto> {
  public title = 'core-portal.settings.subtitles.templates.document-template-detail';
  public templateUsageType: TemplateUsageTypes;

  protected idParam = 'documentTemplateId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, documentTemplateDetailStore);
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons(
        'core-portal.settings.actions.templates.edit-document-template',
        'core-portal.settings.actions.templates.save-document-template',
        'core-portal.settings.actions.templates.delete-document-template',
        'core-portal.settings.descriptions.templates.delete-document-template',
        AppPermissions.UpdateDocumentTemplate,
        AppPermissions.DeleteDocumentTemplate,
        undefined,
        undefined,
        undefined,
        ExportType.DOCUMENT_TEMPLATE
      ),
      {
        label: 'core-portal.settings.actions.templates.preview-document-template',
        icon: faEye,
        type: 'button',
        class: 'btn-outline-primary',
        isDisabled: () => this.isModelValid$.pipe(
          map(valid => !valid)
        ),
        isLoading: () => this.store.pipe(
          select(documentTemplateDetailStore.selectors.selectEntityDataLoading, { key: 'preview' })
        ),
        callback: () => this.store.dispatch(documentTemplateDetailStore.actions.preview())
      }
    ];
  }
}
