import {FieldType, FormlyTemplateOptions} from '@ngx-formly/core';
import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {CoreSharedImageService} from '@nexnox-web/core-shared';
import {ImageUploadAdapter} from './image-upload-adapter';
import {CORE_STORE_TENANT_ID_SELECTOR} from '@nexnox-web/core-store';
import {MemoizedSelector, select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {filter, map, startWith, take} from 'rxjs/operators';
import {CKEditor5} from '@ckeditor/ckeditor5-angular';

import '@nexnox/ckeditor5-build-classic/build/translations/de';
import '@nexnox/ckeditor5-build-classic/build/translations/en-gb';

export interface CorePortalFormlyEditorTyping {
  noHTML?: boolean;
  language?: string;
  insertContent$?: Observable<string>;
  focus$?: Observable<boolean>;
  buttons?: any[]; // todo: find the used button typing
}

interface FormlyEditorTemplateOptions extends FormlyTemplateOptions {
  corePortalEditor: CorePortalFormlyEditorTyping;
}

@Component({
  selector: 'nexnox-web-formly-editor',
  templateUrl: './formly-editor.component.html',
  styleUrls: ['./formly-editor.component.scss']
})
export class FormlyEditorComponent extends FieldType implements OnInit, OnDestroy {

  @ViewChild('textAreaElement') public textArea: ElementRef;

  public readonly to: FormlyEditorTemplateOptions;

  public editor: CKEditor5.EditorConstructor;
  public config: CKEditor5.Config;

  private tenantIdSubscription: Subscription;

  private insertContentSubscription: Subscription;
  private focusSubscription: Subscription;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private imageService: CoreSharedImageService,
    @Inject(CORE_STORE_TENANT_ID_SELECTOR) private tenantIdSelector: MemoizedSelector<any, number>,
    private store: Store<any>
  ) {
    super();
  }

  public ngOnInit(): void {
    import('@nexnox/ckeditor5-build-classic').then(async classicEditor => {
      this.editor = classicEditor.default;
      await this.initialize();
      this.changeDetector.detectChanges();
    });
    this.subscribeToInsertContent();
  }

  /* istanbul ignore next */
  public ngOnDestroy(): void {
    if (this.tenantIdSubscription && !this.tenantIdSubscription.closed) {
      this.tenantIdSubscription.unsubscribe();
    }

    if (this.insertContentSubscription && !this.insertContentSubscription.closed) {
      this.insertContentSubscription.unsubscribe();
    }

    if (this.focusSubscription && !this.focusSubscription.closed) {
      this.focusSubscription.unsubscribe();
    }
  }

  /* istanbul ignore next */
  public onReady(editor: CKEditor5.Editor): void {

    this.configurePlugins(editor);

    // insertContent$
    this.subscribeToInsertContent(editor);

    // focus$
    if (this.to.corePortalEditor?.focus$) {
      // Unsubscribe here if previously subscribed
      if (this.focusSubscription?.unsubscribe) {
        this.focusSubscription.unsubscribe();
      }
      // Logic
      this.focusSubscription = this.to.corePortalEditor.focus$.subscribe(() => {
        editor.editing.view.focus();
      });
    }
  }

  private async initialize(): Promise<void> {
    let language: string;
    if (this.to.corePortalEditor?.language) {
      language = this.to.corePortalEditor.language;
    } else {
      language = await this.translate.onLangChange.asObservable().pipe(
        map(event => event.lang),
        startWith(this.translate.currentLang),
        filter(lang => Boolean(lang)),
        take(1)
      ).toPromise();
    }

    let editorLanguage: string;
    switch (language) {
      case 'en-US':
        editorLanguage = 'en-gb';
        break;
      default:
        editorLanguage = 'de';
        break;
    }

    this.config = {language: editorLanguage};
  }

  private subscribeToInsertContent(editor?: CKEditor5.Editor): void {
    if (this.to.corePortalEditor?.insertContent$) {
      if (this.insertContentSubscription?.unsubscribe) {
        this.insertContentSubscription.unsubscribe();
      }
      this.insertContentSubscription = this.to.corePortalEditor.insertContent$.subscribe((content) => this.insertContent(content, editor));
    }
  }

  private insertContent(content: string, editor?: CKEditor5.Editor): void {
    if (editor) {
      // Insert content at cursor (ckeditor)
      editor.model.change(writer => writer.insertText(content, editor.model.document.selection.getFirstPosition()));
    }
    if (this.textArea) {
      // Insert content at cursor (text area no-html)
      const start = this.textArea?.nativeElement?.selectionStart ?? 0;
      const end = this.textArea?.nativeElement?.selectionEnd ?? 0;
      const value = this.formControl.value ?? '';
      const newValue = `${value.substring(0, start)}${content}${value.substring(end, value.lenght)}`;
      this.formControl.setValue(newValue.trim());
    }
  }

  /* istanbul ignore next */
  private configurePlugins(editor: CKEditor5.Editor): void {
    this.tenantIdSubscription = this.store.pipe(select(this.tenantIdSelector)).subscribe(tenantId => {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) =>
        new ImageUploadAdapter(loader, this.imageService, tenantId);
    });
  }
}
