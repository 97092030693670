import {
	pathsDetailStore,
	PathsDetailStoreState,
	pathsListStore,
	PathsListStoreState
} from './stores';

export interface PathsState {
	pathsList: PathsListStoreState;
	pathsDetail: PathsDetailStoreState;
}

export const getInitialPathsState = (): PathsState => ({
	pathsList: pathsListStore.getInitialState(),
	pathsDetail: pathsDetailStore.getInitialState()
});
