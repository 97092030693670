import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { AppPermissions, CustomPropertyDto, CustomPropertyType } from '@nexnox-web/core-shared';
import { cloneDeep } from 'lodash';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { Observable, of } from 'rxjs';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { CorePortalCardboxAction } from '@nexnox-web/core-portal';
import { CustomPropertyTemplates } from './custom-property.templates';
import { BindObservable } from 'bind-observable';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nexnox-web-settings-stereotypes-custom-property-edit',
  templateUrl: './custom-property-edit.component.html',
  styleUrls: ['./custom-property-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPropertyEditComponent implements OnInit {
  @Input() public customProperty: CustomPropertyDto;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @Input() @BindObservable() public isRateable: boolean;
  public isRateable$!: Observable<boolean>;

  @Input() @BindObservable() public isFirst: boolean;
  public isFirst$!: Observable<boolean>;

  @Input() @BindObservable() public isLast: boolean;
  public isLast$!: Observable<boolean>;

  @Output() public modelChange: EventEmitter<CustomPropertyDto> = new EventEmitter<CustomPropertyDto>();
  @Output() public moveUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() public moveDown: EventEmitter<void> = new EventEmitter<void>();
  @Output() public delete: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('nameEditInput') public nameEditInput: ElementRef<HTMLInputElement>;

  public form: FormGroup;
  public fields: FormlyFieldConfig[];

  /* istanbul ignore next */
  public headerActions: CorePortalCardboxAction[] = [
    {
      icon: faArrowUp,
      class: 'p-button-secondary',
      tooltip: 'core-shared.shared.actions.move-up',
      permission: AppPermissions.UpdateCustomPropertySet,
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      isDisabled: () => this.isFirst$,
      callback: () => this.moveUp.emit()
    },
    {
      icon: faArrowDown,
      class: 'p-button-secondary',
      tooltip: 'core-shared.shared.actions.move-down',
      permission: AppPermissions.UpdateCustomPropertySet,
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      isDisabled: () => this.isLast$,
      callback: () => this.moveDown.emit()
    },
    {
      icon: faTrashAlt,
      class: 'p-button-danger',
      tooltip: 'core-portal.settings.actions.delete-custom-property',
      permission: AppPermissions.DeleteCustomProperty,
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      callback: () => this.delete.emit()
    }
  ];

  private customPropertyTemplates: CustomPropertyTemplates;

  constructor(private translate: TranslateService) {}

  public ngOnInit(): void {
    this.customPropertyTemplates = new CustomPropertyTemplates(
      () => this.readonly,
      () => this.isRateable,
      this.isRateable$,
      this.translate.currentLang
    );
    this.form = new FormGroup({});
    this.fields = this.createForm();
  }

  public onNameChange(name: string): void {
    this.modelChange.emit({ ...this.customProperty, name });
  }

  public onCustomPropertyChange(model: CustomPropertyDto): void {
    this.modelChange.emit(cloneDeep(model));
  }

  public isModelValid(): boolean {
    return Boolean(this.nameEditInput?.nativeElement?.checkValidity()) && this.form.valid;
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    if (!this.customProperty) {
      return [];
    }

    switch (this.customProperty.type) {
      case CustomPropertyType.Text:
        return this.customPropertyTemplates.text();
      case CustomPropertyType.Numeric:
        return this.customPropertyTemplates.numeric();
      case CustomPropertyType.Multiline:
        return this.customPropertyTemplates.multilineText();
      case CustomPropertyType.Email:
        return this.customPropertyTemplates.email();
      case CustomPropertyType.Checkbox:
        return this.customPropertyTemplates.checkbox();
      case CustomPropertyType.Date:
        return this.customPropertyTemplates.date();
      case CustomPropertyType.TimeOfDay:
        return this.customPropertyTemplates.time();
      case CustomPropertyType.Dropdown:
        return this.customPropertyTemplates.dropdown();
      case CustomPropertyType.Phone:
        return this.customPropertyTemplates.phone();
      case CustomPropertyType.Signature:
        return this.customPropertyTemplates.signature();
      case CustomPropertyType.Picture:
        return this.customPropertyTemplates.image();
      case CustomPropertyType.Info:
        return this.customPropertyTemplates.info();
      default:
        return this.customPropertyTemplates.default();
    }
  }
}
