import {NgModule} from '@angular/core';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {
  MessageIssueDetailComponent,
  MissionIssueDetailComponent,
  TechPortalFeatureIssueListComponent,
  TicketIssueDetailComponent,
  WinterhalterIssueDetailComponent
} from './containers';
import {issuesServices} from './store';
import {TechPortalModule} from '@nexnox-web/tech-portal-lib';
import {
  MessageIssueEditComponent,
  MissionIssueEditComponent,
  TicketIssueEditComponent,
  WinterhalterIssueEditComponent
} from "./components";

@NgModule({
  declarations: [
    TechPortalFeatureIssueListComponent,
    MessageIssueDetailComponent,
    MessageIssueEditComponent,
    MissionIssueDetailComponent,
    MissionIssueEditComponent,
    TicketIssueEditComponent,
    TicketIssueDetailComponent,
    WinterhalterIssueEditComponent,
    WinterhalterIssueDetailComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    TechPortalModule,
  ],
  exports: [
    TechPortalFeatureIssueListComponent
  ],
  providers: [
    ...issuesServices
  ]
})
export class IssuesDeclarationsModule {
}
