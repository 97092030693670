import {
  ContextCloak,
  CoreSharedApiBaseService,
  FilterDto,
  FilterOperations,
  PageableRequest,
  Product,
  SortObject
} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";

@Injectable()
export class InfoProgressContactService extends CoreSharedApiBaseService {

  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'contact', ['v2/infoprogress']);
  }

}

