import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {CorePortalComponentConfigGuard, CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {
  MessageIssueDetailComponent,
  MissionIssueDetailComponent,
  TechPortalFeatureIssueListComponent,
  TicketIssueDetailComponent,
  WinterhalterIssueDetailComponent
} from "./containers";

export const TECH_PORTAL_ISSUES_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: TechPortalFeatureIssueListComponent,
    data: {
      permissions: [AppPermissions.ReadIssue],
      requiredComponentConfig: ['allowList']
    }
  },
  {
    path: 'message/:issueId',
    component: MessageIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadIssue, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'issue',
      text: 'core-portal.core.header.breadcrumbs.issues.issue-detail'
    }
  },
  {
    path: 'mission/:issueId',
    component: MissionIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadIssue, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'issue',
      text: 'core-portal.core.header.breadcrumbs.issues.issue-detail'
    }
  },
  {
    path: 'ticket/:issueId',
    component: TicketIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadIssue, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'issue',
      text: 'core-portal.core.header.breadcrumbs.issues.issue-detail'
    }
  },
  {
    path: 'winterhalter/:issueId',
    component: WinterhalterIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadIssue, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'issue',
      text: 'core-portal.core.header.breadcrumbs.issues.issue-detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_ISSUES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class IssuesRoutingModule {
}
