<nexnox-web-cardbox *ngIf="withCardbox; else attachmentsTemplate"
                    [title]="cardboxTitle | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">
  <ng-container *ngTemplateOutlet="attachmentsTemplate"></ng-container>
</nexnox-web-cardbox>

<ng-template #attachmentsTemplate>
  <nexnox-web-sortable-list [items]="items$ | async"
                            [disabled]="readonly || (attachmentsLoading$ | async)"
                            [sortable]="false"
                            [hideItemName]="true"
                            (itemsChange)="onItemsChange($event)">
    <ng-template nexnoxWebSortableListItemTemplate let-item="item">
      <ng-container [ngTemplateOutlet]="attachmentItemTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </ng-template>
  </nexnox-web-sortable-list>

  <ng-container *ngIf="!readonly">
    <div class="row align-items-center">
     
      <div class="col-md mt-3 pl-0 pr-0 pr-md-2">
        <div class="custom-file">
          <input #fileInput
                 type="file"
                 class="form-control-file custom-file-input"
                 id="fileInput"
                 accept=".pdf,.png,.jpg,.jpeg"
                 [disabled]="readonly || (loadingSubject | async) || (attachmentsLoading$ | async)"
                 (change)="onUploadFile($any($event.target).files)">
          <label class="custom-file-label" for="fileInput">
            <ng-container *ngIf="(selectedFileSubject | async) === null && (selectedFileAttachmentSubject | async) === null; else (selectedFileAttachmentSubject | async) ? selectedFileAttachmentTemplate : selectedFileTemplate">
              {{ 'core-shared.shared.actions.select-file' | translate }}...
            </ng-container>

            <ng-template #selectedFileAttachmentTemplate>
              {{ (selectedFileAttachmentSubject | async).file?.name }}
            </ng-template>

            <ng-template #selectedFileTemplate>
              {{ (selectedFileSubject | async).name }}
            </ng-template>
          </label>
        </div>
      </div>

      <div *ngIf="canAddExisting" class="col-md-3 mt-3 pl-0 pl-md-2 pr-0 pr-md-2">
        <button pButton
                type="button"
                [label]="'tech-portal.actions.add-existing-attachment' | translate"
                class="p-button-secondary p-button-outlined w-100"
                [disabled]="loading || (isInProgress$ | async) || (attachmentsLoading$ | async)"
                (click)="onAddExisting()">
        </button>
      </div>
    </div>

    <div *ngIf="progressSubject | async as progress" class="progress mt-3">
      <div class="progress-bar bg-primary" [style.width.%]="progress * 100">{{ progress | percent }}</div>
    </div>
  </ng-container>
</ng-template>

<ng-template #attachmentItemTemplate let-item="item">
  <div *ngIf="item?.file" class="d-flex w-100 flex-wrap">
    <div class="d-flex flex-grow-1 align-items-center">
      <div class="attachment-thumbnail">
        <fa-icon *ngIf="!isImage(item.file)" [icon]="getFileImage(item.file)" [fixedWidth]="true" size="lg"></fa-icon>
        <p-image *ngIf="isImage(item.file)" [src]="item.file.uri" [alt]="item.file.name" ></p-image>
      </div>
      <a [href]="item.file.uri" target="_blank">{{ item.file.name }}</a>&nbsp;
      ({{ item.file.size | filesize }})
    </div>

    <div class="d-md-flex align-items-center d-none">
      <span>
        <span *ngIf="item.member">
          <span>{{ 'tech-portal.descriptions.in' | translate }} </span>
          <a [nexnoxWebTenantRouterLink]="item.member.link"
             [module]="item.member.module"
             target="'_blank'">
            {{ item.member.title }}
          </a>
        </span>

        <span>
          {{ 'core-shared.shared.time-phrases.at-date' | translate:{
            date: item.file.creationDateTime | nexnoxWebBsDate:'L LT' | async
          } }}
        </span>
      </span>
    </div>
  </div>
</ng-template>
