export {
  TechPortalFeatureResourceDetailListComponent
} from './lib/containers';
export {
  TechPortalFeatureResourceTicketService,
  TechPortalFeatureResourceMissionService,
  TechPortalFeatureResourceService,
  TechPortalFeatureVirtualResourceService,
  resourceEntitiesStore,
  resourceUIEntitiesStore,
  resourceListStore,
  resourcesGuiStore
} from './lib/store';
export {
  resourceTypeEnumOptions
} from './lib/models'
export * from './lib/resources.module';
