import {
  BaseXsStore, BaseXsStoreReducerTypes,
  EmptyAction, EntityXsStore,
  EntityXsStoreActions,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreState, PropsAction
} from '@nexnox-web/core-store';
import {AppEntityType, OccurredConsumptionListDto, ResourceDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createAction, createSelector, props, select} from '@ngrx/store';
import { selectResourcesState } from '../../resources.selectors';
import { TechPortalFeatureResourceOccurredConsumptionsService } from '../../services';
import { Injectable, Injector } from '@angular/core';
import { cloneDeep } from 'lodash';
import {
  ResourceDetailXsStoreActions, ResourceDetailXsStoreState
} from "@nexnox-web/tech-portal/features/resources/src/lib/store/stores/resource-detail/resource-detail.xs-store";
import {authStore, CorePortalTenantRouter} from "@nexnox-web/core-portal";
import {
  CorePortalFeatureInventoryNumberPatternService
} from "@nexnox-web/core-portal/features/settings/features/inventory-numbers";
import {createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {resourceDetailStore} from "@nexnox-web/tech-portal/features/resources/src/lib/store";
import {immerOn} from "ngrx-immer/store";

export interface LocalOccurredConsumptionListDto extends OccurredConsumptionListDto {
  consumptionCodeId?: number;
  displayName?: string;
  externalId?: string;
}

export interface ResourceOccurredConsumptionsStoreState extends PagedEntitiesXsStoreState<OccurredConsumptionListDto, LocalOccurredConsumptionListDto> {
}

/* istanbul ignore next */
export const resourceOccurredConsumptionsStore = new PagedEntitiesXsStore<OccurredConsumptionListDto, LocalOccurredConsumptionListDto>({
  actionLabel: 'Tech Portal - Resources - Resource Occurred Consumptions',
  stateSelector: createSelector(selectResourcesState, state => state.resourceOccurredConsumptions),
  entityType: AppEntityType.None,
  serviceType: TechPortalFeatureResourceOccurredConsumptionsService,
  selectId: consumption => consumption.entity?.consumption?.consumptionCodeId ?? consumption.model?.consumption?.consumptionCodeId,
  prepareModel: (entity: OccurredConsumptionListDto, model: LocalOccurredConsumptionListDto, base: (entity: OccurredConsumptionListDto, model: LocalOccurredConsumptionListDto) => LocalOccurredConsumptionListDto) => {
    const newModel = cloneDeep(base(entity, model));

    newModel.consumptionCodeId = newModel?.consumption?.consumptionCodeId;
    newModel.displayName = newModel?.consumption?.displayName;
    newModel.externalId = newModel?.consumption?.externalId;

    return newModel;
  }
});
