import {NgModule} from '@angular/core';
import {CoreSharedModule, CoreSharedSortableListModule} from 'libs/core-shared/src';
import {CorePortalEntityModule, CorePortalRouterOverridesModule} from 'libs/core-portal/src';
import {OrgaPortalModule} from 'libs/orga-portal/src';
import {pathsServices} from './store';
import {PathsArticlesEditComponent, PathsEditComponent} from './components';
import {PathsDetailComponent, PathsListComponent} from './containers';

@NgModule({
  declarations: [
    PathsEditComponent,
    PathsArticlesEditComponent,

    PathsListComponent,
    PathsDetailComponent
  ],
    imports: [
        CoreSharedModule,
        CorePortalEntityModule,
        CoreSharedSortableListModule,

        OrgaPortalModule,
        CorePortalRouterOverridesModule
    ],
  exports: [
    PathsArticlesEditComponent
  ],
  providers: [
    ...pathsServices
  ]
})
export class PathsDeclarationsModule {
}
