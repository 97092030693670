import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreSelectors,
  PagedEntitiesXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import { ResourceListXsStorePreloadPayload, ResourceListXsStorePreloadSuccessPayload } from './resource-list-xs-store.payload';
import { createAction, createSelector, MemoizedSelector, props } from '@ngrx/store';
import { ResourceSimpleListDto } from '@nexnox-web/core-shared';
import { immerOn } from 'ngrx-immer/store';

export interface ResourceListXsStoreState extends PagedEntitiesXsStoreState<ResourceSimpleListDto> {
  preloading: boolean;
}

export interface ResourceListXsStoreActions extends PagedEntitiesXsStoreActions<ResourceSimpleListDto> {
  preload: PropsAction<ResourceListXsStorePreloadPayload>;
  preloadSuccess: PropsAction<ResourceListXsStorePreloadSuccessPayload>;
}

export interface ResourceListXsStoreSelectors extends PagedEntitiesXsStoreSelectors<ResourceSimpleListDto> {
  selectPreloading: MemoizedSelector<ResourceListXsStoreState, boolean>;
}

export class ResourceListXsStore extends PagedEntitiesXsStore<ResourceSimpleListDto, ResourceSimpleListDto, ResourceListXsStoreState> {
  public actions: ResourceListXsStoreActions;
  public selectors: ResourceListXsStoreSelectors;

  public getInitialState(): ResourceListXsStoreState {
    return {
      ...super.getInitialState(),

      preloading: false
    };
  }

  protected createActions(label: string): ResourceListXsStoreActions {
    return {
      ...super.createActions(label),

      preload: createAction(
        BaseXsStore.getType(label, 'Preload'),
        props<ResourceListXsStorePreloadPayload>()
      ),
      preloadSuccess: createAction(
        BaseXsStore.getType(label, 'Preload success'),
        props<ResourceListXsStorePreloadSuccessPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: ResourceListXsStoreState
  ): BaseXsStoreReducerTypes<ResourceListXsStoreState, ResourceListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.preload, draft => {
        draft.preloading = true;
      }),
      immerOn(this.actions.preloadSuccess, draft => {
        draft.preloading = false;
      })
    ];
  }

  protected createSelectors(): ResourceListXsStoreSelectors {
    const stateSelector = this.options.stateSelector;

    return {
      ...super.createSelectors(),

      selectPreloading: createSelector(stateSelector, state => state.preloading)
    };
  }
}
