<nexnox-web-modal #modalComponent
                  [title]="'core-portal.settings.subtitles.custom-property-set-create' | translate"
                  [confirmButtonText]="'core-portal.settings.actions.create-custom-property-set' | translate"
                  [cancelButtonText]="'core-portal.core.general.cancel' | translate"
                  [preConfirmFn]="preConfirmFn">
  <div class="row w-100">
    <form [formGroup]="form" class="w-100">
      <formly-form class="row w-100"
                   [form]="form"
                   [(model)]="model"
                   [fields]="fields">
      </formly-form>
    </form>
  </div>
</nexnox-web-modal>
