<ng-container *ngIf="(entityOverviewBaseComponent.isCreateVisible$ | async) && !disableCreate"
              [ngTemplateOutlet]="entityEditTemplate"
              [ngTemplateOutletContext]="{ title: entityOverviewBaseComponent.createTitle | translate }">
</ng-container>

<div class="overflow-hidden min-h-100"
     [class.d-none]="hideIfCreate && (entityOverviewBaseComponent.isCreateVisible$ | async)">
  <nexnox-web-cardbox [title]="title ? title : (entityOverviewBaseComponent.disableTitleTranslation ? entityOverviewBaseComponent.title : (entityOverviewBaseComponent.title | translate))"
                      [noDividerMarginBottom]="true"
                      [loading]="(entityOverviewBaseComponent.hasError$ | async) === false && ((entityOverviewBaseComponent.loaded$ | async) === false || (stereotyped && (entityOverviewBaseComponent.stereotypesLoaded$ | async) === false))"
                      [noMarginBottom]="isEmbedded && !allowMargin"
                      [shadowMarginBottom]="isEmbedded && !allowMargin"
                      [headerActions]="cardBoxHeaderActions ?? []"
                      ghostPaddingClasses="p-3">

    <ng-template *ngIf="appendCardboxTitleTemplate" #appendTitleTemplate>
      <ng-container *ngTemplateOutlet="appendCardboxTitleTemplate"></ng-container>
    </ng-template>

    <nexnox-web-entity-datatable *ngIf="entityOverviewBaseComponent as bc"
                                 #datatableComponent
                                 [entityType]="bc.entityType"
                                 [storeEntities$]="bc.entities$"
                                 [stereotypes$]="bc.stereotypes$"
                                 [paging]="bc.paging$ | async"
                                 [loading]="bc.loading$ | async"
                                 [detailLink]="bc.detailLink ?? detailLink"
                                 [module]="bc.module ?? module"
                                 [idProp]="bc.idProperty"
                                 [displayProp]="bc.displayProperty"
                                 [pageOperation]="bc.pageOperation"
                                 [componentId]="bc.componentId"
                                 [excludedColumns]="bc.excludedColumns"
                                 [defaultColumns]="bc.defaultColumns"
                                 [appendColumns]="appendColumns ?? []"
                                 [actionButtons]="rowActionButtons"
                                 [additionalHeaderActions]="additionalHeaderActions"
                                 [columnTypings]="columnTypings"
                                 [canDelete]="canDelete | async"
                                 [canExport]="canExport"
                                 [entityData]="bc.entityData$ | async"
                                 [enableViews]="bc.enableViews"
                                 [detailFn]="detailFn"
                                 [detailTemplate]="detailTemplate"
                                 [showDetailTemplateExpandButtonFn]="showDetailTemplateExpandButtonFn"
                                 [savedFilters]="!disableSavedFilters ? ((bc.savedFilters$ ?? bc.stateFilters$) | async) : null"
                                 [filtersForExport]="bc.filtersForExport$ ? (bc.filtersForExport$ | async) : undefined"
                                 [savedSortObject]="!disableSavedSortObject ? (bc.stateSortObject$ | async) : null"
                                 [showSettings]="showSettings"
                                 [enableCalendarSubscription]="enableCalendarSubscription"
                                 [hideFilters]="hideFilters"
                                 [disableFilters]="disableFilters"
                                 [disableSettingsViews]="disableSettingsViews"
                                 [disableSettingsViewSelect]="bc.disableSettingsViewSelect || disableSettingsViewSelect"
                                 [disableOptionalSorting]="bc.disableOptionalSorting"
                                 [datatableConfig]="bc.customDatatableConfig ?? datatableConfig"
                                 [descendantId]="bc.descendantId"
                                 (datatableConfigChange)="onDatatableConfigChange($event)"
                                 [templates]="bc.templates"
                                 (loadPage)="onLoadPage($event)"
                                 (delete)="bc.onDelete($event)"
                                 (clickableCellEvent)="onCellClick($event)">
    </nexnox-web-entity-datatable>
  </nexnox-web-cardbox>
</div>

<div *ngIf="entityOverviewBaseComponent.isCreateVisible$ | async" class="pt-1"></div>
