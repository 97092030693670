import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {FilterOperators, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {FilterType} from "../entity-datatable-filter.component";
import {DatatableTableColumn, DatatableTableColumnType} from "../../../models";
import {Observable} from "rxjs";
import {faAnchor, faLayerGroup, faTimes} from '@fortawesome/free-solid-svg-icons';
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {Dictionary} from "@ngrx/entity";
import {isEqual} from "lodash";
import {TranslateService} from "@ngx-translate/core";
import {CorePortalEntitySelectOptions} from "../../../../entity-select/components";
import {booleanItems, directions, IconOption, textFilters} from "./filter-operator-options";


@Component({
  selector: 'nexnox-web-entity-datatable-filter-input',
  templateUrl: './entity-datatable-filter-input.component.html',
  styleUrls: ['./entity-datatable-filter-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityDatatableFilterInputComponent extends UnsubscribeHelper {
  @Input() public filterType: FilterType;
  @Input() public currentDirection: FilterOperators = FilterOperators.Default;
  @Input() public templates: Dictionary<TemplateRef<any>>;
  @Input() public column: DatatableTableColumn;
  @Input() public pathEntitySelectOptions: CorePortalEntitySelectOptions;
  @Input() public referenceEntitySelectOptions: CorePortalEntitySelectOptions;
  @Input() public alternateReferenceEntitySelectOptions: CorePortalEntitySelectOptions;
  @Input() public disabled: boolean;
  @Input() public showError: boolean;
  @Input() public showAll = false;
  @Input() public clearable = true;
  @Input() public allowRelative = false;
  @Input() public isRelative: boolean;
  @Input() public useAlternate: boolean;
  @Input() public isDatatableSettings = false;
  @Input() public currentValue$: Observable<any>;

  @Output() public changeAlternate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public changeFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() public changeRelative: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public changeDirection: EventEmitter<FilterOperators> = new EventEmitter<FilterOperators>();
  @Output() public changePathFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() public changeDateFilter: EventEmitter<string> = new EventEmitter<string>();
  @Output() public changeRelativeDateFilter: EventEmitter<string> = new EventEmitter<string>();

  public columnType = DatatableTableColumnType;
  public currentPathValue$: Observable<any>;

  public bsConfig: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    containerClass: 'datepicker-theme',
    customTodayClass: 'datepicker-today'
  };

  public readonly textFilters: IconOption[] = textFilters;
  public readonly directions: IconOption[] = directions;
  public readonly booleanItems = booleanItems;

  public ngSelectSearchFn: any;
  public ngSelectCompareFn: any;

  public faTimes = faTimes;
  public faAnchor = faAnchor;
  public faLayerGroup = faLayerGroup;

  constructor(
    private translate: TranslateService
  ) {
    super();

    this.ngSelectSearchFn = (term: string, item: any) => this.ngSelectSearch(term, item);
    this.ngSelectCompareFn = (a: any, b: any) => isEqual(a, b) || isEqual(+a.value, +b.value);
  }

  public onChangeAlternate(value: boolean): void {
    this.changeAlternate.emit(value);
  }

  public onChangeFilter(value: any): void {
    this.changeFilter.emit(value);
  }

  public onChangeRelative(value: boolean): void {
    this.changeRelative.emit(value);
  }

  public onChangeDirection(value: FilterOperators): void {
    this.changeDirection.emit(value);
  }

  public onChangePathFilter(value: any): void {
    this.changePathFilter.emit(value);
  }

  public onChangeDateFilter(value: string): void {
    this.changeDateFilter.emit(value);
  }

  public onChangeRelativeDateFilter(value: string): void {
    this.changeRelativeDateFilter.emit(value);
  }

  private ngSelectSearch(term: string, item: any): boolean {
    return this.translate.instant(item.label).toLowerCase().includes(term.toLowerCase());
  }
}
