import {ChangeDetectionStrategy, Component, Inject, Injector, OnInit, Optional} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  CombineOperator,
  ExportType,
  FilterOperators,
  FilterTypes,
  Mappers,
  StereotypeDto
} from '@nexnox-web/core-shared';
import {stereotypeListStore} from '../../store';
import {corePortalSettingsStereotypesEntityTypeOptions} from '../../models';
import {CORE_PORTAL_FEATURE_STEREOTYPES_ALLOWED_ENTITY_TYPES} from '../../tokens';

@Component({
  selector: 'nexnox-web-settings-stereotypes-stereotype-list',
  templateUrl: './stereotype-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StereotypeListComponent extends CorePortalEntityOverviewBaseComponent<StereotypeDto> implements OnInit {
  public title = 'core-portal.settings.subtitles.stereotypes';
  public createTitle = 'core-portal.settings.subtitles.stereotype-create';
  public idProperty = 'stereotypeId';
  public displayProperty = 'name';
  public componentId = 'StereotypeListComponent';
  public datatableConfigName = 'SettingsStereotypeDefault';

  constructor(
    protected injector: Injector,
    @Optional() @Inject(CORE_PORTAL_FEATURE_STEREOTYPES_ALLOWED_ENTITY_TYPES) protected allowedEntityTypes: AppEntityType[]
  ) {
    super(injector, stereotypeListStore, Mappers.StereotypeListDto.serializedName, AppEntityType.Stereotype);
  }

  public ngOnInit(): void {

    this.defaultFilter = [
      ...(this.defaultFilter ?? []),
      {
        property: 'isArchived',
        value: 'false'
      },
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        children: corePortalSettingsStereotypesEntityTypeOptions
          .filter(x => !this.allowedEntityTypes ? true : this.allowedEntityTypes.includes(x.value))
          .map(x => ({
            property: 'entityType',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: x.value.toString()
          }))
      }
    ];

    this.detailLink = 'stereotypes'

    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.edit-stereotype',
        (row: StereotypeDto) => `/stereotypes/${row.stereotypeId}`,
        [AppPermissions.UpdateStereotype],
        {
          module: 'settings'
        }),
      this.exportService.getRowActionExportButton(ExportType.STEREOTYPE, this.idProperty)
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.delete-stereotype',
      descriptionKey: 'core-portal.settings.descriptions.delete-stereotype',
      confirmKey: 'core-portal.settings.actions.delete-stereotype',
      deletePermission: AppPermissions.DeleteStereotype
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.settings.actions.create-stereotype', AppPermissions.CreateStereotype);
  }
}
