<nexnox-web-cardbox
  [title]="'missions.subtitles.mission-state' | translate:{ state: ((missionState$ | async) | translate) }"
  [transparent]="true"
  paddingClasses="px-3"
  [showBadge]="isComplete$ | async"
  [badgeTitle]="'missions.fields.mission-completed' | translate"
  [badgeStyleClasses]="'bg-success'"
  [loading]="(missionStateDefined$ | async) === false || loading"
  [isAccordion]="true"
  [isCollapsed]="false">
  <div class="d-flex flex-column">
    <div *ngIf="ticketReference$ | async as ticketReference"
         class="d-flex flex-column mb-3">
      <label class="form-label">{{ 'tickets.subtitles.ticket-detail' | translate }}</label>

      <div class="d-flex flex-row pl-3">
        <fa-icon [icon]="faEnvelope"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2"></fa-icon>
        <a class="btn-link"
           [nexnoxWebTenantRouterLink]="['/tickets', ticketReference.ticketId]"
           module="communication"
           target="_blank">
          {{ ticketReference.title }}
        </a>
      </div>
    </div>

    <div *ngIf="ticketAddress$ | async as ticketAddress"
         class="d-flex flex-column mb-3">
      <label class="form-label">{{ 'tech-portal.fields.ticket-address' | translate }}</label>

      <div class="d-flex flex-row pl-3">
        <fa-icon [icon]="faEnvelope"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2"></fa-icon>
        <span>{{ ticketAddress }}</span>
      </div>
    </div>

    <div *ngIf="primaryIssue$ | async as primaryIssue"
         class="d-flex flex-column mb-3">
      <label class="form-label">{{ 'issues.subtitles.issue-detail' | translate }}</label>

      <div class="d-flex flex-row pl-3">
        <fa-icon [icon]="faInbox"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2"></fa-icon>
        <a class="btn-link"
           [nexnoxWebTenantRouterLink]="getIssueLink(primaryIssue)"
           module="communication"
           target="_blank">
          <span>{{ primaryIssue.title }}</span>
        </a>
      </div>
    </div>

    <div *ngIf="taskReference$ | async as taskReference"
         class="d-flex flex-column mb-3">
      <label class="form-label">{{ 'resource-tasks.subtitles.resource-task-detail' | translate }}</label>

      <div class="d-flex flex-row pl-3">
        <fa-icon [icon]="faTasks"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2"></fa-icon>
        <a class="btn-link"
           [nexnoxWebTenantRouterLink]="['/resource-tasks', taskReference.resourceTaskId]"
           module="inventory"
           target="_blank">
          {{ taskReference.title }}
        </a>
      </div>
    </div>

    <div *ngIf="quest$ | async as quest">
      <label class="form-label">{{ 'missions.subtitles.mission-quest' | translate }}</label>
      <p>
        <button pButton
                type="button"
                class="p-button-text p-button-secondary"
                [loading]="loadingQuest"
                [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
                [label]="quest.label$ | async"
                [icon]="faSearch | nexnoxWebFaIconStringPipe"
                (click)="openQuestSidebar.emit(quest)">
        </button>
      </p>
    </div>

    <div *ngIf="locationReference$ | async as locationReference"
         class="d-flex flex-column mb-3">
      <label class="form-label">{{ 'core-portal.master-data.subtitles.location-detail' | translate }}</label>

      <div class="d-flex flex-row pl-3">
        <fa-icon [icon]="faHome"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2"></fa-icon>
        <a class="btn-link"
           style="line-height: 1.2rem"
           [nexnoxWebTenantRouterLink]="['/masterdata/locations', locationReference.locationId]"
           module="management"
           target="_blank">
          {{ locationReference.name }}
          <span *ngIf="locationReference.locationNumber">({{ locationReference.locationNumber }})</span>

          <ng-container *ngIf="locationReference.phoneNumber">
            <br>
            <span *ngIf="locationReference.phoneNumber">
              {{ 'core-shared.shared.fields.phone' | translate }}: {{ locationReference.phoneNumber }}
            </span>
          </ng-container>

          <ng-container *ngIf="locationReference.info">
            <br>
            <span class="text-muted">{{ locationReference.info }}</span>
          </ng-container>
        </a>
      </div>
    </div>

    <div *ngIf="predecessor$ | async as predecessor"
         class="d-flex flex-column mb-3">
      <label class="form-label">{{ 'core-shared.shared.fields.predecessor' | translate }}</label>

      <div class="d-flex flex-row pl-3">
        <fa-icon [icon]="faClipboardCheck"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2"></fa-icon>
        <a class="btn-link"
           [nexnoxWebTenantRouterLink]="['/missions', predecessor.missionId]"
           module="inventory"
           target="_blank">
          {{ predecessor.title }}
        </a>
      </div>
    </div>
  </div>
</nexnox-web-cardbox>
