import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DeleteEntityModel} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  FilterOperators,
  FilterTypes,
  Mappers,
  ProductSubscriptionDto
} from '@nexnox-web/core-shared';
import {tenantSubscriptionListStore} from '../../store';
import {Observable} from "rxjs";

@Component({
  selector: 'nexnox-web-service-tenants-tenant-subscription-list',
  templateUrl: './tenant-subscription-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantSubscriptionListComponent extends CorePortalEntityOverviewBaseComponent<ProductSubscriptionDto> implements OnInit {
  @Input() public tenantId: number | string;

  public title = 'core-portal.service.tenants.subscriptions';
  public createTitle = 'core-portal.service.tenants.actions.create-subscription';
  public idProperty = 'productSubscriptionId';
  public displayProperty = null;
  public datatableConfigName = 'ServiceTenantSubscriptionDefault';
  public componentId = 'ServiceTenantSubscriptionListComponent';
  public canCreateProductSubscription$: Observable<boolean>;

  public createSubscriptionHeaderActions = this.getCreateEntityCardboxHeaderActions(
    'core-portal.service.tenants.actions.create-subscription',
    AppPermissions.CreateProductSubscription
  );

  constructor(
    protected injector: Injector
  ) {
    super(injector, tenantSubscriptionListStore, Mappers.ProductSubscriptionListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      property: 'tenantId',
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Equal,
      value: this.tenantId.toString()
    }];

    this.canCreateProductSubscription$ = this.permissionService.hasPermission$(AppPermissions.CreateProductSubscription);

    super.ngOnInit();
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.service.tenants.actions.delete-subscription',
      descriptionKey: 'core-portal.service.tenants.descriptions.delete-subscription',
      deletePermission: AppPermissions.DeleteProductSubscription
    };
  }
}
