<nexnox-web-cardbox [title]="'resources.subtitles.resource-list' | translate"
                    [icon]="faList"
                    [noMarginBottom]="true"
                    [noDividerMarginBottom]="true"
                    [fullWidth]="true"
                    [fullHeight]="true"
                    classes="d-flex flex-column"
                    paddingClasses="overflow-isHidden">

  <div class="resource-scrollbar">
    <nexnox-web-hierarchical-tree-view #treeViewComponent
                                       [treeViewItems]="items$ | async"
                                       [paging]="paging$ | async"
                                       [loading]="listLoading$ | async"
                                       [nextLoading]="nextLoading$ | async"
                                       [canSelectItem]="canSelectItemFn"
                                       [disabled]="preloadSubject | async"
                                       (selectItem)="onSelectItem($event)"
                                       (expandItem)="onExpandItem($event)"
                                       (moreItem)="onMoreItem($event)"
                                       (moreRoot)="onMore()">
    </nexnox-web-hierarchical-tree-view>
  </div>
</nexnox-web-cardbox>
