import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {InfoProgressSidebarComponent} from "@nexnox-web/orga-portal/features/infos/src/lib/sidebars";
import {InfoProgressFunctionListDto, InfoProgressLocationListDto} from "@nexnox-web/core-shared";
import {infoProgressContactListStore} from "@nexnox-web/orga-portal/features/infos/src/lib/store";
import {Store} from "@ngrx/store";

@Component({
  selector: 'nexnox-web-infos-info-progress',
  templateUrl: './info-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoProgressComponent {
  @ViewChild('progressSidebarComponent', {static: true}) public progressSidebarComponent: InfoProgressSidebarComponent;

  @Input() public parentIds: Array<number | string>;

  public contactListTabOpen = false;

  public openSidebar(model: InfoProgressFunctionListDto | InfoProgressLocationListDto): void {
    this.contactListTabOpen = false;
    this.progressSidebarComponent.onShow(model);
  }
}
