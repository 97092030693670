import {ChangeDetectionStrategy, Component, Injector, ViewChild} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ExportType, Mappers, TemplateDto} from '@nexnox-web/core-shared';
import {TechPortalFeatureTextTemplateService, textTemplateListStore} from '../../../store';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {map} from 'rxjs/operators';
import {TechPortalFeatureTextTemplateEditComponent} from '../../../components';
import {of} from 'rxjs';
import {TemplateUsageTypes} from "../../../models";

@Component({
  selector: 'nexnox-web-templates-text-template-list',
  templateUrl: './text-template-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextTemplateListComponent extends CorePortalEntityOverviewBaseComponent<TemplateDto> {
  @ViewChild('editComponent') public editComponent: TechPortalFeatureTextTemplateEditComponent;

  public title = 'core-portal.settings.subtitles.templates.text-template-list';
  public createTitle = 'core-portal.settings.subtitles.templates.text-template-create';
  public idProperty = 'templateId';
  public displayProperty = 'title';
  public datatableConfigName = 'TemplatesTextDefault';
  public componentId = 'TextTemplateListComponent';
  public detailLink = 'templates/text';
  public templateUsageType: TemplateUsageTypes;
  public showDetailComponent = false;

  protected entityService: TechPortalFeatureTextTemplateService;

  constructor(
    protected injector: Injector
  ) {
    super(injector, textTemplateListStore, Mappers.TemplateListDto.serializedName, AppEntityType.None);
  }

  public activateDetail(): void {
    this.showDetailComponent = true;
  }

  public deactivateDetail(): void {
    this.showDetailComponent = false;
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.templates.edit-text-template',
        (row: TemplateDto) => `${this.detailLink}/${row.templateId}`,
        [AppPermissions.UpdateTemplate],
        {
          module: this.module
        }
      ),
      this.exportService.getRowActionExportButton(ExportType.TEXT_TEMPLATE, this.idProperty)
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.templates.delete-text-template',
      descriptionKey: 'core-portal.settings.descriptions.templates.delete-text-template',
      confirmKey: 'core-portal.settings.actions.templates.delete-text-template',
      deletePermission: AppPermissions.DeleteTemplate
    };
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons('core-portal.settings.actions.templates.create-text-template', AppPermissions.CreateTemplate),
      {
        label: 'core-portal.settings.actions.templates.preview-text-template',
        icon: faEye,
        type: 'button',
        class: 'btn-outline-primary',
        shouldShow: () => this.isCreateVisible$.asObservable().pipe(
          map(isCreateVisible => isCreateVisible && this.editComponent?.isModelValid())
        ),
        isLoading: () => this.editComponent?.previewLoadingSubject.asObservable() ?? of(true),
        callback: () => {
          const model = this.createModel$.getValue();
          this.editComponent?.onPreview(model.content, model.context);
        }
      }
    ];
  }
}
