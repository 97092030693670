<p-table [columns]="config?.columns ?? []"
         [value]="data ?? []"
         [tableStyle]="{'width': '100%'}"
         [ngClass]="config?.class ?? ''"
         [scrollable]="true"
         [resizableColumns]="true"
         [loading]="loading"
         [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
         columnResizeMode="expand">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [class.text-right]="col.align === 'right'">
        {{ col.header | translate }}
      </th>
      <th *ngIf="config?.actions?.length > 0" class="text-right">
        {{ 'core-shared.shared.table.actions' | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td *ngFor="let col of columns" class="text-wrap" [class.text-right]="col.align === 'right'">

        <div [ngClass]="col.class ?? ''">
          <ng-container [ngSwitch]="col.type">

            <ng-container *ngSwitchCase="columnType.Text">
              {{  (col.prefix ?? '') + getRowData(rowData, col.key) + (col.suffix ?? '') }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Boolean">
              {{  (col.prefix ?? '') + (getBooleanValue(rowData, col.key) | translate) + (col.suffix ?? '') }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.TextTranslation">
              {{  (col.prefix ?? '') + (getRowData(rowData, col.key) | translate) + (col.suffix ?? '') }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Enum">
              {{  (col.prefix ?? '') + (getEnumTranslation(col?.enumOptions, getRowData(rowData, col.key)) | translate) + (col.suffix ?? '') }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Link">
              <a [href]="getURL(col.link(rowData))" target="_blank">
                {{  (col.prefix ?? '') + getRowData(rowData, col.key) + (col.suffix ?? '') }}
              </a>
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Date">
              {{ getRowData(rowData, col.key) | nexnoxWebBsDate: config?.dateFormat | async }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.Currency">
              {{ getRowData(rowData, col.key) | nexnoxWebLocaleStringPipe:2 }} €
            </ng-container>

            <ng-container *ngSwitchCase="columnType.HumanizedTimeSpan">
              {{ getHumanizedTimeSpan(rowData, col.key) }}
            </ng-container>

            <ng-container *ngSwitchCase="columnType.UsageOfTotal">
              <div class="d-flex">
                <div class="w-75" [class.red-progress]="getUsageOfTotal(rowData, col.key).percent >= 100">
                  <p-progressBar [value]="getUsageOfTotal(rowData, col.key).percent"
                                 [showValue]="false">
                  </p-progressBar>
                </div>
                <div class="w-25 text-right">
                  {{ getUsageOfTotal(rowData, col.key).usage }} / {{ getUsageOfTotal(rowData, col.key).total }}
                </div>
              </div>
            </ng-container>

          </ng-container>
        </div>
      </td>
      <td *ngIf="config.actions?.length > 0" class="text-right">
        <ng-container *ngIf="!(rowData?.isRowLoading ?? false); else loadingTemplate">
          <button *ngFor="let action of config.actions"
                  type="button"
                  pButton
                  class="m-0 ml-2"
                  [class]="action.class ?? 'p-button-secondary p-button-text'"
                  [icon]="action.icon | nexnoxWebFaIconStringPipe"
                  [disabled]="action?.disabled ? action.disabled(rowData) : (action.disabled$ | async) ?? false"
                  [class.d-none]="action?.hidden ? action.hidden(rowData) : (action.hidden$ | async) ?? false"
                  [title]="action.tooltip | translate"
                  [label]="action.label | translate"
                  (click)="action.click(rowData, rowIndex)">
          </button>
        </ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <div class="p-3">
      {{ 'core-shared.shared.table.empty' | translate}}
    </div>
  </ng-template>

  <ng-template #loadingTemplate>
    <button type="button"
            pButton
            class="p-button-secondary p-button-text p-0 m-0 ml-2"
            [icon]="faSpinner | nexnoxWebFaIconStringPipe"
            [loading]="true"
            [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'">
    </button>
  </ng-template>

</p-table>
