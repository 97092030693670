<nexnox-web-cardbox [title]="'core-portal.settings.subtitles.custom-property-sets' | translate"
                    paddingClasses="px-3 pb-3"
                    ghostPaddingClasses="px-3 pb-3"
                    [loading]="loading"
                    [headerActions]="headerActions">
  <nexnox-web-settings-stereotypes-custom-property-set-edit *ngFor="let customPropertySet of (customPropertySets$ | async | nexnoxWebSortCustomPropertySets);
                                                                    let isFirst = first;
                                                                    let isLast = last;
                                                                    trackBy: trackSetsBy"
                                                            #customPropertySetEditComponent
                                                            [customPropertySet]="customPropertySet.customPropertySet"
                                                            [readonly]="readonly"
                                                            [isInherited]="customPropertySet.isInherited"
                                                            [position]="customPropertySet.position"
                                                            [isRateable]="isRateable$ | async"
                                                            [activeTenant$]="activeTenant$"
                                                            [isFirst]="isFirst"
                                                            [isLast]="isLast"
                                                            (modelChange)="onCustomPropertySetChange($event, customPropertySet.position)"
                                                            (moveUp)="onMoveUp(customPropertySet.position)"
                                                            (moveDown)="onMoveDown(customPropertySet.position)"
                                                            (delete)="onDelete(customPropertySet.position)">
  </nexnox-web-settings-stereotypes-custom-property-set-edit>

  <span *ngIf="!(customPropertySets$ | async).length" class="text-muted">
    {{ 'core-portal.settings.descriptions.no-custom-property-sets' | translate }}
  </span>
</nexnox-web-cardbox>
