import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyTableComponent, FormlyTableRowComponent, FormlyValidationMessagesComponent} from './components';
import {CorePortalFormlyRootModule} from './formly-root.module';
import {FormlySignatureModalComponent} from './modals';
import {defaultExtensions, exportedModules, importedModules, sharedModules} from './shared-exports';
import {
  FormlyButtonSelectComponent,
  FormlyColorPickerComponent,
  FormlyDatepickerComponent,
  FormlyDateTimePickerComponent,
  FormlyDividerComponent,
  FormlyDualListBoxComponent,
  FormlyEditorComponent,
  FormlyEntitySelectComponent,
  FormlyExternalIdComponent,
  FormlyFileUploadComponent,
  FormlyGoogleMapComponent,
  FormlyInputGroupInputComponent,
  FormlyLabelActionComponent,
  FormlyMultiToggleComponent,
  FormlyNgSelectComponent,
  FormlyNgSelectCountryComponent,
  FormlyReceiversComponent,
  FormlyReceiversSidebarComponent,
  FormlySignatureComponent,
  FormlySwitchComponent,
  FormlyTimepickerComponent
} from './types';
import {FormlyActionWrapperComponent, FormlyReadonlyComponent, FormlyTranslatedComponent} from './wrappers';
import {TabsModule} from "ngx-bootstrap/tabs";

export const wrappers = [
  { name: 'core-portal-translated', component: FormlyTranslatedComponent },
  { name: 'core-portal-readonly', component: FormlyReadonlyComponent },
  { name: 'core-portal-actions', component: FormlyActionWrapperComponent}
];

export const types = [
  { name: 'core-portal-datepicker', component: FormlyDatepickerComponent },
  { name: 'core-portal-timepicker', component: FormlyTimepickerComponent },
  { name: 'core-portal-date-time-picker', component: FormlyDateTimePickerComponent },
  { name: 'core-portal-entity-select', component: FormlyEntitySelectComponent },
  { name: 'core-portal-dual-list-box', component: FormlyDualListBoxComponent },
  { name: 'core-portal-ng-select', component: FormlyNgSelectComponent },
  { name: 'core-portal-divider', component: FormlyDividerComponent },
  { name: 'core-portal-switch', component: FormlySwitchComponent },
  { name: 'core-portal-ng-select-country', component: FormlyNgSelectCountryComponent },
  { name: 'core-portal-editor', component: FormlyEditorComponent },
  { name: 'core-portal-input-group-input', component: FormlyInputGroupInputComponent },
  { name: 'core-portal-color-picker', component: FormlyColorPickerComponent },
  { name: 'core-portal-file-upload', component: FormlyFileUploadComponent },
  { name: 'core-portal-signature', component: FormlySignatureComponent },
  { name: 'core-portal-multi-toggle', component: FormlyMultiToggleComponent },
  { name: 'core-portal-button-select', component: FormlyButtonSelectComponent },
  { name: 'core-portal-google-map', component: FormlyGoogleMapComponent },
  { name: 'core-portal-external-id', component: FormlyExternalIdComponent },
  { name: 'core-portal-label-action', component: FormlyLabelActionComponent},
  { name: 'core-portal-receivers', component: FormlyReceiversComponent}
];

@NgModule({
  declarations: [
    FormlyTranslatedComponent,
    FormlyReadonlyComponent,
    FormlyActionWrapperComponent,

    FormlyDatepickerComponent,
    FormlyTimepickerComponent,
    FormlyDateTimePickerComponent,
    FormlyEntitySelectComponent,
    FormlyDualListBoxComponent,
    FormlyNgSelectComponent,
    FormlyDividerComponent,
    FormlySwitchComponent,
    FormlyNgSelectCountryComponent,
    FormlyEditorComponent,
    FormlyInputGroupInputComponent,
    FormlyColorPickerComponent,
    FormlyFileUploadComponent,
    FormlySignatureComponent,
    FormlyMultiToggleComponent,
    FormlyButtonSelectComponent,
    FormlyGoogleMapComponent,
    FormlyExternalIdComponent,
    FormlyLabelActionComponent,
    FormlyReceiversComponent,
    FormlyReceiversSidebarComponent,

    FormlyValidationMessagesComponent,

    FormlyTableComponent,
    FormlyTableRowComponent,

    FormlySignatureModalComponent
  ],
  providers: [

  ],
  imports: [
    ...importedModules,
    ...sharedModules,
    FormlyModule.forChild({
      wrappers,
      types,
      extensions: defaultExtensions
    }),
    TabsModule
  ],
    exports: [
        FormlyTableComponent,
        ...sharedModules,
        ...exportedModules,
        FormlyReceiversSidebarComponent
    ]
})
export class CorePortalFormlyModule {
  public static forRoot(): ModuleWithProviders<CorePortalFormlyRootModule> {
    return {
      ngModule: CorePortalFormlyRootModule
    };
  }
}
