import {
  AppPermissions,
  CombineOperator,
  ControllerOperationId,
  CORE_SHARED_ENVIRONMENT,
  CoreSharedSidebarBaseComponent,
  CoreSharedSortableListItem,
  DashCasePipe,
  DataTableColumnDto,
  DataTableColumnType,
  DataTableCustomColumnDto,
  DataTableDto,
  DataTableFilterDto,
  DataTableFilterType,
  DataTableTransferColumnDto,
  Environment,
  Filter,
  FilterOperators,
  FilterTypes,
  isFilterComplex,
  isFilterValid,
  mapDatatableFilterToFilter,
  NexnoxWebFaIconString,
  Orders,
  replaceFilterDeep,
  SortObject
} from '@nexnox-web/core-shared';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  DatatableTableColumn,
  DatatableTableColumnOption,
  DatatableTableColumnType,
  DatatableTableColumnTyping
} from '../../models';
import {BehaviorSubject, firstValueFrom, Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, mergeMap, take} from 'rxjs/operators';
import {cloneDeep, isEqual, isNull, isUndefined, sortBy} from 'lodash';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {CorePortalPermissionService} from '../../../../../services';
import {CorePortalEntitySelectOptions} from '../../../entity-select';
import {EntityDatatableSettingsSidebarStore} from './entity-datatable-settings-sidebar.store';
import {CorePortalDatatableService} from '../../services';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faSortAmountDown} from '@fortawesome/free-solid-svg-icons/faSortAmountDown';
import {faSortAmountUpAlt} from '@fortawesome/free-solid-svg-icons/faSortAmountUpAlt';
import {TranslateService} from '@ngx-translate/core';
import {TreeNode} from 'primeng/api';
import {faFilter} from '@fortawesome/free-solid-svg-icons/faFilter';
import {faMagic} from '@fortawesome/free-solid-svg-icons/faMagic';
import {faObjectGroup} from '@fortawesome/free-solid-svg-icons/faObjectGroup';
import {TabsComponent} from '@nexnox-web/core-portal';
import {Dictionary} from '@ngrx/entity';

export type LocalDatatableTableColumn = DatatableTableColumn & {
  identifier: string,
  disabled: boolean,
  isArchived: boolean
};
export type LocalFilter = Filter & { identifier: string, children?: LocalFilter[] };

export interface CorePortalEntityDatatableSettingsOutput {
  datatableConfig?: DataTableDto;
}

export type FilterChangeEvent = { parentFilter: LocalFilter, index: number, changes: Partial<LocalFilter> };

@Component({
  selector: 'nexnox-web-entity-datatable-settings-sidebar',
  templateUrl: './entity-datatable-settings-sidebar.component.html',
  styleUrls: ['./entity-datatable-settings-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityDatatableSettingsSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @Input() public enableViews = false;
  @Input() public pageOperation: ControllerOperationId = null;
  @Input() public disableDatatableViews = false;
  @Input() public disableDatatableViewSelect = false;
  @Input() public templates: Dictionary<TemplateRef<any>>;

  @Input()
  public set activeColumns(activeColumns: DatatableTableColumn[]) {
    this.activeColumnsSubject.next(activeColumns);
    this.originalActiveColumnsSubject.next(activeColumns);
  }

  @Input()
  public set dataColumns(dataColumns: DatatableTableColumn[]) {
    this.dataColumnsSubject.next(dataColumns);
  }

  @Input()
  public set optionalColumns(optionalColumns: DatatableTableColumn[]) {
    this.optionalColumnsSubject.next(optionalColumns);
  }

  @Input()
  public set stereotypeColumns(stereotypeColumns: DatatableTableColumn[]) {
    this.stereotypeColumnsSubject.next(stereotypeColumns);
  }

  @Input()
  public set sortObject(sortObject: SortObject) {
    this.sortObjectSubject.next(sortObject);
    this.originalSortObjectSubject.next(sortObject);
    this.validateSorting();
  }

  @Input()
  public set filters(filters: Filter[]) {
    this.filtersSubject.next(filters);
    this.originalFiltersSubject.next(filters);
    this.validateFilters();
  }

  @Input()
  public set datatableConfig(datatableConfig: DataTableDto) {
    if (this.enableViews) {
      if (isNull(this.store.getOriginalDatatableView())) {
        this.store.setOriginalDatatableView(cloneDeep(datatableConfig));
      }

      this.store.setSelectedDatatableView(cloneDeep(datatableConfig));
      this.store.updateSelectedDatatableView(this.prepareConfig(this.store.getSelectedDatatableView()));
    }

    this.updateView(datatableConfig);
  }

  @Input() public columnTypings: DatatableTableColumnTyping[] = [];

  @Output() public settingsChange: EventEmitter<CorePortalEntityDatatableSettingsOutput> =
    new EventEmitter<CorePortalEntityDatatableSettingsOutput>();

  @ViewChild('tabsComponent') public tabsComponent: TabsComponent;

  public activeColumns$: Observable<LocalDatatableTableColumn[]>;
  public allColumns$: Observable<LocalDatatableTableColumn[]>;
  public availableColumns$: Observable<LocalDatatableTableColumn[]>;
  public sortableColumns$: Observable<LocalDatatableTableColumn[]>;
  public sortObject$: Observable<SortObject>;
  public filters$: Observable<LocalFilter[]>;

  public editableActiveColumns$: Observable<LocalDatatableTableColumn[]>;
  public columnsItems$: Observable<CoreSharedSortableListItem[]>;
  public filterTreeItems$: Observable<TreeNode[]>;

  public columnsValid$: Observable<boolean>;
  public sortingValid$: Observable<boolean>;
  public filtersValid$: Observable<boolean>;
  public valid$: Observable<boolean>;

  public filterChangeSubject: Subject<FilterChangeEvent> = new Subject<FilterChangeEvent>();
  public pageSizeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public selectedDatatableView$: Observable<DataTableDto>;
  public loading$: Observable<boolean>;
  public loaded$: Observable<boolean>;
  public datatableViewSelectOptions: CorePortalEntitySelectOptions;
  public onSaveCurrentConfigAsNewFn: any;
  public filterTrackByFn: any;
  public readDatatableViewPermission$: Observable<boolean>;

  public searchColumnFn: any;
  public searchFilterFn: any;

  public combinedAsItems = [
    {label: 'core-shared.shared.combined-as-types.0', value: CombineOperator.And},
    {label: 'core-shared.shared.combined-as-types.1', value: CombineOperator.Or}
  ];

  public columnOptions = DatatableTableColumnOption;
  public sortOrders = Orders;
  public filterTypes = FilterTypes;
  public combinedAs = CombineOperator;

  public faPlus = faPlus;
  public faSpinner = faSpinner;
  public faSave = faSave;
  public faCheck = faCheck;
  public faTrashAlt = faTrashAlt;
  public faSync = faSync;
  public faTimes = faTimes;
  public faSortAmountUpAlt = faSortAmountUpAlt;
  public faSortAmountDown = faSortAmountDown;
  public faObjectGroup = faObjectGroup;

  private activeColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);
  private originalActiveColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);
  private dataColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);
  private optionalColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);
  private stereotypeColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);
  private sortObjectSubject: BehaviorSubject<SortObject> = new BehaviorSubject<SortObject>(null);
  private originalSortObjectSubject: BehaviorSubject<SortObject> = new BehaviorSubject<SortObject>(null);
  private filtersSubject: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  private originalFiltersSubject: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);

  private store: EntityDatatableSettingsSidebarStore;

  private editableActiveColumnsSubject: BehaviorSubject<LocalDatatableTableColumn[]> =
    new BehaviorSubject<LocalDatatableTableColumn[]>([]);
  private validSubject: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject<{ [p: string]: boolean }>({});
  private refreshDatatableViewSelectSubject: Subject<void> = new Subject<void>();

  constructor(
    private injector: Injector,
    @Inject(CORE_SHARED_ENVIRONMENT) public environment: Environment,
    private datatableService: CorePortalDatatableService,
    private permissionService: CorePortalPermissionService,
    private translate: TranslateService
  ) {
    super();

    this.onSaveCurrentConfigAsNewFn = (name: string) => this.onSaveCurrentConfigAsNew(name);
    this.filterTrackByFn = (filter: LocalFilter, index: number) => filter.identifier;
    this.searchColumnFn = (term: string, item: LocalDatatableTableColumn): boolean => {
      if (item.option === DatatableTableColumnOption.STEREOTYPE) {
        return item.name.toLowerCase().includes(term.toLowerCase());
      }

      const translated = this.translate.instant(`core-shared.shared.fields.${DashCasePipe.transformString(item.name)}`);
      return translated?.toLowerCase().includes(term.toLowerCase());
    }

    this.searchFilterFn = (term: string, item: LocalDatatableTableColumn): boolean => {
      if (item.option === DatatableTableColumnOption.STEREOTYPE) {
        return item.name.toLowerCase().includes(term.toLowerCase());
      }

      const translated = this.translate.instant(`core-shared.shared.fields.${DashCasePipe.transformString(item.name)}`);
      return translated?.toLowerCase().includes(term.toLowerCase());
    };

    this.setup();
  }

  public ngOnInit(): void {
    if (this.enableViews) {
      this.datatableViewSelectOptions = {
        idKey: 'dataTableId',
        displayKey: 'name',
        wholeObject: true,
        entityService: this.datatableService,
        clearable: true,
        skipGetOne: true,
        refresh$: this.refreshDatatableViewSelectSubject.asObservable(),
        onlyRefreshEntity: true,
        defaultFilters$: of(this.pageOperation).pipe(
          map(pageOperation => [{
            property: 'pageOperation',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: pageOperation.toString()
          }])
        )
      };

      this.selectedDatatableView$ = this.store.selectSelectedDatatableView();
      this.loading$ = this.store.selectLoading();
      this.loaded$ = this.store.selectLoaded();
    }

    this.subscribe(this.filterChangeSubject.asObservable().pipe(
      debounceTime(400)
    ), ({parentFilter, index, changes}) => {
      if (isNull(changes)) {
        changes = {value: null};
      }

      this.onFilterChanged(parentFilter, index, changes);
    });
  }

  public onAddColumn(): void {
    const activeColumns = this.editableActiveColumnsSubject.getValue();
    const newColumn: LocalDatatableTableColumn = {
      identifier: `new-${activeColumns.length}`
    } as LocalDatatableTableColumn;
    activeColumns.push(newColumn);
    this.editableActiveColumnsSubject.next(activeColumns);
    this.validateColumns();
  }

  public onColumnsChange(items: CoreSharedSortableListItem[]): void {
    this.editableActiveColumnsSubject.next(
      items.sort((a, b) => a.position - b.position).map(item => ({...item.getExternalData(), position: item.position}))
    );
    this.validateColumns();
  }

  public onColumnChange(item: LocalDatatableTableColumn, newItem: string): void {
    const activeColumns = this.editableActiveColumnsSubject.getValue();
    const index = activeColumns.findIndex(x => x.identifier === item.identifier);

    firstValueFrom(this.allColumns$.pipe(take(1))).then(allColumns => {
      activeColumns[index] = {...allColumns.find(x => x.identifier === newItem), position: index};
      this.editableActiveColumnsSubject.next(activeColumns);
      this.validateColumns();
    });
  }

  public onSelectedDatatableViewChange(view: DataTableDto, skipGet?: boolean): void {
    this.store.setSelectedDatatableView(cloneDeep(view));

    if (isNull(view)) {
      this.store.setApplied(true);
    } else if (!skipGet && !isEqual(view, this.store.getOriginalDatatableView())) {
      this.store.getOne(
        this.store.getSelectedDatatableView().dataTableId,
        datatableView => this.updateView(datatableView, true)
      );
    }
  }

  public onSaveCurrentConfigAsNew(name: string): Promise<DataTableDto> {
    return new Promise<DataTableDto>(resolve => this.store.createOne(
      this.prepareConfig({name, pageOperation: this.pageOperation} as DataTableDto),
      (datatableView: DataTableDto) => resolve(datatableView))
    );
  }

  public onDeleteCurrentConfig(): void {
    this.store.deleteOne(() => {
      this.refreshDatatableViewSelectSubject.next();
      this.updateView(this.store.getSelectedDatatableView());
    });
  }

  public onPageSizeChanged(pageSize: number): void {
    this.store.updateSelectedDatatableView({pageSize});
  }

  public onAddSortObject(): void {
    this.sortObjectSubject.next({sortField: null, sort: Orders.Ascending});
    this.validateSorting();
  }

  public onRemoveSortObject(): void {
    this.sortObjectSubject.next(null);
    this.validateSorting();
  }

  public onSortFieldChanged(sortField: string): void {
    this.sortObjectSubject.next({...this.sortObjectSubject.getValue(), sortField});
    this.validateSorting();
  }

  public onSortOrderChanged(): void {
    const sortObject = this.sortObjectSubject.getValue();
    this.sortObjectSubject.next({
      ...sortObject,
      sort: sortObject.sort === Orders.Ascending ? Orders.Descending : Orders.Ascending
    });
    this.validateSorting();
  }

  public async onAddFilter(parentFilter?: LocalFilter): Promise<void> {
    const newFilters = cloneDeep(await firstValueFrom(this.filters$.pipe(take(1))));
    const newFilter = {
      property: null,
      type: null,
      operator: FilterOperators.Default,
    } as LocalFilter;

    if (parentFilter) {
      replaceFilterDeep(parentFilter, 'identifier', newFilters, reference => {
        reference.children = [...(reference.children ?? []), newFilter] as LocalFilter[];
      });
    } else {
      newFilters.push(newFilter);
    }

    this.filtersSubject.next(newFilters);
    this.validateFilters();
  }

  public async onFilterChanged(parentFilter: LocalFilter, index: number, changes: Partial<LocalFilter>): Promise<void> {
    const newFilters = cloneDeep(await firstValueFrom(this.filters$.pipe(take(1))));

    if (changes.property) {
      const column = cloneDeep(await firstValueFrom(this.allColumns$.pipe(take(1))))
        .find(x => Boolean(x) && (`${x.identifier}.${x.idKey}` === changes.property || x.identifier === changes.property));
      const isSpecial = this.isFilterColumnSpecial(column);

      if (column && !isSpecial) {
        changes = {
          ...(changes ?? {}),
          type: column.option === DatatableTableColumnOption.STEREOTYPE ? FilterTypes.Custom : FilterTypes.DataTransferObject
        };
      }

      if (isNull(changes.value) && isSpecial) {
        changes = {...(changes ?? {}), children: []};
      }
    }

    if (parentFilter) {
      replaceFilterDeep(parentFilter, 'identifier', newFilters, reference => {
        reference.children[index] = {...reference.children[index], ...changes};

        if (reference.children.length && reference.children.every(x => x.property === reference.children[0].property)) {
          reference.label = reference.children[0].property;
        }
      });
    } else {
      newFilters[index] = {...newFilters[index], ...changes};
    }

    this.filtersSubject.next(newFilters);
    this.validateFilters();
  }

  public async onRemoveFilter(filter: LocalFilter): Promise<void> {
    const newFilters = cloneDeep(await firstValueFrom(this.filters$.pipe(take(1))));
    replaceFilterDeep(filter, 'identifier', newFilters, (_, deleteFilter) => deleteFilter());
    this.filtersSubject.next(newFilters);
    this.validateFilters();
  }

  public onReset(): void {
    this.store.getOne(
      this.store.getSelectedDatatableView().dataTableId,
      datatableView => this.updateView(datatableView, true)
    );
  }

  public async onApply(): Promise<void> {
    const allColumns = await firstValueFrom(this.allColumns$.pipe(take(1)));
    const editableActiveColumns = this.editableActiveColumnsSubject.getValue();

    const activeColumns = editableActiveColumns.map(x => ({
      ...allColumns.find(y => x.identifier === y.identifier),
      position: x.position
    }));

    this.originalActiveColumnsSubject.next(activeColumns);
    this.originalSortObjectSubject.next(this.sortObjectSubject.getValue());
    if (this.enableViews) {
      this.store.setOriginalDatatableView(this.store.getSelectedDatatableView());
    }
    this.originalFiltersSubject.next(this.filtersSubject.getValue());

    this.settingsChange.emit({
      datatableConfig: this.prepareConfig(this.store?.getSelectedDatatableView() ?? null)
    });
    this.onHide();
  }

  public onSaveAndApply(): void {
    this.store.saveOne(this.prepareConfig(this.store.getSelectedDatatableView()), () => this.onApply());
  }

  public onHide(): void {
    super.onHide();

    const originalActiveColumns = this.originalActiveColumnsSubject.getValue();
    const originalSortObject = this.originalSortObjectSubject.getValue();
    const originalFilters = this.originalFiltersSubject.getValue();
    const originalDatatableConfig = this.enableViews ? this.store.getOriginalDatatableView() : null;

    this.ngOnDestroy();
    this.setup();

    this.activeColumnsSubject.next(originalActiveColumns);
    this.sortObjectSubject.next(originalSortObject);
    this.filtersSubject.next(originalFilters);
    if (this.enableViews) {
      this.datatableConfig = originalDatatableConfig;
    }

    this.validateColumns();
    this.validateSorting();
    this.validateFilters();

    this.ngOnInit();
    this.tabsComponent?.activateTab('columns');
  }

  private setup(): void {
    this.store = new EntityDatatableSettingsSidebarStore(this.injector);

    this.readDatatableViewPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadDataTable);

    this.allColumns$ = this.dataColumnsSubject.asObservable().pipe(
      mergeMap(dataColumns => this.optionalColumnsSubject.asObservable().pipe(
        mergeMap(optionalColumns => this.stereotypeColumnsSubject.asObservable().pipe(
          map(stereotypeColumns => [
            ...dataColumns.map(x => ({
              ...x,
              name: this.columnTypings.find(y => y.key === x.prop)?.name ?? x.name,
              identifier: x.prop.toString(),
              option: DatatableTableColumnOption.DATA,
              isArchived: false
            })),
            ...optionalColumns.map(x => ({
              ...x,
              name: this.columnTypings.find(y => y.key === x.prop)?.name ?? x.name,
              identifier: x.prop.toString(),
              option: DatatableTableColumnOption.OPTIONAL,
              isArchived: false
            })),
            ...stereotypeColumns.map(x => ({
              ...x,
              identifier: x.customPropertyId.toString(),
              option: DatatableTableColumnOption.STEREOTYPE,
              isArchived: x.isOfArchivedStereotype
            }))
          ] as LocalDatatableTableColumn[])
        ))
      ))
    );

    this.activeColumns$ = this.activeColumnsSubject.asObservable().pipe(
      mergeMap(activeColumns => this.allColumns$.pipe(
        map(allColumns => {
          const mapColumns = (x: LocalDatatableTableColumn, y: DatatableTableColumn): boolean => {
            if (x.option === DatatableTableColumnOption.STEREOTYPE) {
              return y.customPropertyId === x.customPropertyId;
            }

            return y.prop === x.prop;
          };
          const filtered = allColumns.filter(x => Boolean(activeColumns.find(y => mapColumns(x, y))));
          return sortBy(filtered, x => activeColumns.findIndex(y => mapColumns(x, y))).map((x, index) => ({
            ...x,
            position: index
          }));
        })
      ))
    );

    this.editableActiveColumns$ = this.editableActiveColumnsSubject.asObservable();
    this.subscribe(this.activeColumns$.pipe(
      distinctUntilChanged()
    ), activeColumns => {
      this.editableActiveColumnsSubject.next(activeColumns);
      this.validateColumns();
    });

    this.availableColumns$ = this.allColumns$.pipe(
      mergeMap(allColumns => this.editableActiveColumns$.pipe(
        map(activeColumns => allColumns.map(column => ({
          ...column,
          disabled: Boolean(activeColumns.find(x => x.identifier === column.identifier))
        })))
      )),
      map(columns => columns.filter(column => column.disabled ? true : !column.isArchived)) // Filtering out archived columns that aren't active in table
    );

    this.sortableColumns$ = this.allColumns$.pipe(
      mergeMap(allColumns => this.editableActiveColumns$.pipe(
        map(activeColumns =>
          allColumns.filter(column => Boolean(column.sortable) && Boolean(activeColumns.find(x => x.identifier === column.identifier))))
      ))
    );

    this.sortObject$ = this.sortObjectSubject.asObservable();

    this.columnsItems$ = this.editableActiveColumns$.pipe(
      map(activeColumns => activeColumns.map((column, index) => ({
        title: column.identifier,
        position: index,
        deletable: true,
        hasError: isUndefined(column.option),
        getExternalData: () => column
      })))
    );

    this.filters$ = this.filtersSubject.asObservable().pipe(
      map(filters =>
        filters.map((filter, index) => this.mapFilterToLocalFilter(cloneDeep(filter), index.toString())) as LocalFilter[])
    );

    this.filterTreeItems$ = this.filters$.pipe(
      mergeMap(filters => this.allColumns$.pipe(
        map(columns => ([
          ...filters.map((filter, index) => this.mapFilterTreeItem(filter, index.toString(), columns)),
          this.getAddFilterTreeItem()
        ]))
      ))
    );

    this.columnsValid$ = this.validSubject.asObservable().pipe(
      map(valid => !isUndefined(valid.columns) ? Boolean(valid.columns) : true)
    );

    this.sortingValid$ = this.validSubject.asObservable().pipe(
      map(valid => !isUndefined(valid.sorting) ? Boolean(valid.sorting) : true)
    );

    this.filtersValid$ = this.validSubject.asObservable().pipe(
      map(valid => !isUndefined(valid.filters) ? Boolean(valid.filters) : true)
    );

    this.valid$ = this.validSubject.asObservable().pipe(
      map(valid => {
        if (!Object.keys(valid).length) {
          return false;
        }

        return Object.keys(valid).every(x => Boolean(valid[x]));
      })
    );
  }

  private async updateView(datatableView: DataTableDto, overrideValues?: boolean): Promise<void> {
    this.pageSizeSubject.next(datatableView?.pageSize ?? this.environment?.defaultPageSize);

    if (overrideValues) {
      const allColumns = await firstValueFrom(this.allColumns$.pipe(take(1)));
      const newColumns = allColumns.filter(x => datatableView.columns.find(y => this.areColumnsEqual(x, y)));
      this.activeColumnsSubject.next(sortBy(newColumns, x => (datatableView.columns ?? [])
        .sort((a, b) => a.position - b.position)
        .findIndex(y => this.areColumnsEqual(x, y))
      ));
      const sortColumn = (datatableView.columns ?? []).find(x => !isUndefined(x.sortOrder) && !isNull(x.sortOrder));
      this.sortObjectSubject.next(sortColumn ? {
        sortField: sortColumn.type === DataTableColumnType.ByTransfer ?
          (sortColumn as DataTableTransferColumnDto).property : (sortColumn as DataTableCustomColumnDto).customPropertyId.toString(),
        sort: sortColumn.sortOrder
      } : null);
      this.filtersSubject.next((datatableView.filters ?? []).map(x => mapDatatableFilterToFilter(x)));
    }
  }

  private prepareConfig(config: DataTableDto): DataTableDto {
    return {
      ...(config ?? {}),
      pageSize: this.pageSizeSubject.getValue(),
      columns: this.editableActiveColumnsSubject.getValue().map(column => this.mapColumn(column)),
      filters: this.filtersSubject.getValue().map(filter => this.mapFilter(filter))
    };
  }

  /* istanbul ignore next */
  private mapColumn(column: LocalDatatableTableColumn): DataTableColumnDto {
    let columnType: DataTableColumnType;
    const sortObject = this.sortObjectSubject.getValue();

    switch (column.option) {
      case DatatableTableColumnOption.STEREOTYPE:
        columnType = DataTableColumnType.ByCustomProperty;
        break;
      default:
        columnType = DataTableColumnType.ByTransfer;
        break;
    }

    if (columnType === DataTableColumnType.ByTransfer) {
      return {
        title: this.translate.instant(`core-shared.shared.fields.${DashCasePipe.transformString(column.name?.toString())}`),
        property: column.prop,
        type: columnType,
        position: column.position,
        sortOrder: sortObject && sortObject.sortField === column.prop ? sortObject.sort : undefined
      } as DataTableColumnDto;
    } else {
      return {
        title: column.name,
        customPropertyId: column.customPropertyId,
        type: columnType,
        position: column.position,
        sortOrder: sortObject && sortObject.sortField === column.prop ? sortObject.sort : undefined
      } as DataTableColumnDto;
    }
  }

  /* istanbul ignore next */
  private mapFilter(filter: Filter): DataTableFilterDto {
    let filterProperty: string;
    let filterType: DataTableFilterType;

    switch (filter.type) {
      case FilterTypes.DataTransferObject:
        filterProperty = filter.property;
        filterType = DataTableFilterType.ByTransfer;
        break;
      case FilterTypes.Custom:
        filterType = DataTableFilterType.ByCustomProperty;
        break;
      case FilterTypes.Grouped:
        filterProperty = filter.property;
        filterType = DataTableFilterType.ByGroup;
        break;
      default:
        filterType = DataTableFilterType.Base;
        break;
    }

    const newFilter = {
      property: filterProperty,
      customPropertyId: filter.type === FilterTypes.Custom ? parseInt(filter.property, 10) : undefined,
      operator: filter.operator,
      kind: filter.kind,
      type: filterType,
      value: filter.value,
      label: filter.label,
      children: filter.children?.map(x => this.mapFilter(x)) ?? undefined,
      combinator: filter.combinedAs ?? undefined
    } as DataTableFilterDto;

    for (const key in newFilter) {
      if (newFilter.hasOwnProperty(key) && typeof newFilter[key] === 'undefined') {
        delete newFilter[key];
      }
    }

    return newFilter;
  }

  private mapFilterToLocalFilter(filter: Filter, index: string, parent?: LocalFilter): LocalFilter {
    const newFilter = {...filter, identifier: `${parent?.identifier ?? 'root'}-${index}`} as LocalFilter;

    for (let i = 0; i < (newFilter.children?.length ?? 0); i++) {
      newFilter.children[i] = this.mapFilterToLocalFilter(newFilter.children[i], i.toString(), newFilter);
    }

    return newFilter;
  }

  /* istanbul ignore next */
  private mapFilterTreeItem(filter: LocalFilter, index: string, columns: LocalDatatableTableColumn[], parent?: LocalFilter): TreeNode {
    let isGrouped = filter.type === FilterTypes.Grouped;
    const isComplex = isFilterComplex(filter) && !parent;
    const parentIndexes = parent?.identifier?.replace('root-', '')?.split('-');
    const canAdd = parentIndexes?.length ? parentIndexes.length < 2 : true;
    const filterChildrenForLabel = ((filter.children ?? []) as Filter[]).filter(x => x.type !== FilterTypes.Grouped);
    const filterLabel = filterChildrenForLabel.length && filterChildrenForLabel.every(x => {
      const childFilterColumn = this.findFilterColumn(x.property, x, columns);

      if (childFilterColumn && this.isFilterColumnSpecial(childFilterColumn)) {
        return false;
      }

      return x.property === filter.children[0].property;
    }) ? filter.children[0].property : filter.property;
    const filterColumn = this.findFilterColumn(filterLabel, filter, columns);
    let filterChildren = filter.children ?? [];
    const isFilterSpecial = filterColumn && this.isFilterColumnSpecial(filterColumn);

    if (isGrouped && isFilterSpecial) {
      isGrouped = false;
      filterChildren = [];
    }

    return {
      key: index,
      type: 'default',
      label: filter.property,
      icon: NexnoxWebFaIconString.transformIcon(isComplex ? faMagic : faFilter),
      expanded: true,
      styleClass: `${isGrouped ? 'is-grouped' : ''}`,
      children: [
        ...filterChildren.map((childFilter: LocalFilter, childIndex) =>
          this.mapFilterTreeItem(childFilter, childIndex.toString(), columns, filter)),
        ...(isGrouped && canAdd ? [this.getAddFilterTreeItem(filter)] : [])
      ],
      data: {
        filter,
        isGrouped,
        property: filterColumn?.identifier,
        combinedAs: filter.combinedAs,
        parent,
        index,
        filterType: filter.type,
        hasProperty: !isUndefined(filter.property) && !isNull(filter.property),
        hasValue: (!isNull(filter.value) && !isUndefined(filter.value)) ||
          (isFilterSpecial && filter.children?.length && (filter.children ?? []).every(x => !isNull(x.value))),
        hasFilterType: !isUndefined(filter.type) && !isNull(filter.type),
        hasCombinedAs: !isUndefined(filter.combinedAs) && !isNull(filter.combinedAs),
        hasChildren: Boolean(filter.children?.length),
        filterColumn,
        canAdd
      }
    };
  }

  private getAddFilterTreeItem(parent?: LocalFilter): TreeNode {
    return {
      key: `${parent ? parent.property : 'root'}-add`,
      type: 'add',
      styleClass: 'is-add',
      data: parent
    };
  }


  /* istanbul ignore next */
  private findFilterColumn(filterLabel: string, filter: Filter, columns: LocalDatatableTableColumn[]): LocalDatatableTableColumn {
    return (columns ?? []).find(column => {
      switch (column.type) {
        case DatatableTableColumnType.REFERENCE:
          return `${column.identifier}.${column.idKey}` === filterLabel || column.identifier === filterLabel;
        default:
          return column.identifier === filterLabel;
      }
    });
  }

  private areColumnsEqual(column: LocalDatatableTableColumn, datatableColumn: DataTableColumnDto): boolean {
    if (datatableColumn.type === DataTableColumnType.ByCustomProperty) {
      return column.identifier === (datatableColumn as DataTableCustomColumnDto).customPropertyId?.toString();
    } else {
      return column.identifier === (datatableColumn as DataTableTransferColumnDto).property;
    }
  }

  private isFilterColumnSpecial(column: LocalDatatableTableColumn): boolean {
    if (!column?.type) {
      return false;
    }

    return column.type === DatatableTableColumnType.REFERENCE || column.type === DatatableTableColumnType.ENUM;
  }

  private validateColumns(): void {
    this.validSubject.next({
      ...this.validSubject.getValue(),
      columns: this.editableActiveColumnsSubject.getValue().every(x => !isUndefined(x.option))
    });
  }

  private validateSorting(): void {
    const sortObject = this.sortObjectSubject.getValue();
    this.validSubject.next({
      ...this.validSubject.getValue(),
      sorting: Boolean(sortObject?.sortField) || isNull(sortObject)
    });
  }

  private validateFilters(): void {
    this.validSubject.next({
      ...this.validSubject.getValue(),
      filters: this.filtersSubject.getValue().every(x => isFilterValid(x))
    });
  }
}
