import {Component, Input} from '@angular/core';
import {isObjectLike} from "lodash";
import dayjs from "dayjs";
import Duration from 'dayjs/plugin/duration';
import {
  CorePortalTenantRouter
} from "@nexnox-web/libs/core-portal/src/lib/router-overrides/tenant-router/tenant.router";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";

dayjs.extend(Duration);

export interface BasicTableConfig {
  columns: BasicTableColumnConfig[];
  actions?: BasicTableRowAction[];
  dateFormat?: string;
  class?: string;
}

export interface BasicTableColumnConfig {
  key: string;
  header: string;
  type: BasicTableColumnType;
  class?: string;
  align?: 'left' | 'right';
  prefix?: string;
  suffix?: string;
  enumOptions?: { label: string, value: number }[];

  link?(row: any): BasicTableLink;
}

export interface BasicTableRowAction {
  icon: IconDefinition;
  label?: string;
  tooltip?: string;
  class?: string;

  disabled$?(row: any): Observable<boolean>;

  hidden$?(row: any): Observable<boolean>;

  disabled?(row: any): boolean;

  hidden?(row: any): boolean;

  click(row: any, index?: number): void;
}

export interface BasicTableLink {
  commands: any[];
  module: string;
}

export interface BasicTableLoadingFlag {
  isRowLoading?: boolean;
}

export enum BasicTableColumnType {
  Text = 0,
  Date = 1,
  Currency = 2,
  HumanizedTimeSpan = 3,
  Link = 4,
  TextTranslation = 5,
  Enum = 6,
  UsageOfTotal = 7,
  Boolean = 8
}

@Component({
  selector: 'nexnox-web-basic-table',
  styleUrls: ['./basic-table.component.scss'],
  templateUrl: './basic-table.component.html'
})
export class BasicTableComponent {

  @Input() public config: BasicTableConfig;
  @Input() public data: any;
  @Input() public loading = false;

  public columnType = BasicTableColumnType;

  public faSpinner = faSpinner;

  constructor(
    private translate: TranslateService,
    private tenantRouter: CorePortalTenantRouter
  ) {
  }

  public getRowData(row: any, key: string): any {
    let layer: string[] = key.split('.');
    layer = layer.length > 0 ? layer : [key];
    let rowData: any = row;

    // key can be deep like: resource.contact.displayName
    for (let i = 0; i < layer.length; i++) {
      if (isObjectLike(rowData)) {
        rowData = rowData[layer[i]]
      } else {
        rowData = `(${this.translate.instant('missions.descriptions.not-specified')})`;
      }
    }
    return rowData;
  }

  public getBooleanValue(row: any, key: string): string {
    return `core-portal.core.general.${row[key]}`;
  }

  public getHumanizedTimeSpan(row: any, key: string): string {
    const timeSpan = this.getRowData(row, key) ?? '';
    let time = timeSpan.split(':');
    let humanizedTimeSpan = '';
    // DD
    if (timeSpan.indexOf('.') > 0) {
      const days = timeSpan.split('.')[0];
      time = timeSpan.split('.')[1].split(':')
      humanizedTimeSpan += +days > 0 ? dayjs.duration(days, 'days').humanize() + ', ' : '';
    }
    // HH:MM:SS
    humanizedTimeSpan += +time[0] > 0 ? dayjs.duration(time[0], 'hours').humanize() + ', ' : '';
    humanizedTimeSpan += +time[1] > 0 ? dayjs.duration(time[1], 'minutes').humanize() + ', ' : '';
    humanizedTimeSpan += +time[2] > 0 ? dayjs.duration(time[2], 'seconds').humanize() + ', ' : '';
    // slice last comma and return
    return humanizedTimeSpan.slice(0, humanizedTimeSpan.length - 2);
  }

  public getURL(link: BasicTableLink): string {
    return this.tenantRouter.createUrlTree((link?.commands ?? []), {module: link?.module}).toString();
  }

  public getEnumTranslation(enumOptions: { value: number, label: string }[], value: number): string {
    return enumOptions?.filter((option) => option.value.toString() === value.toString())[0]?.label ?? 'Enum value not found! (undefined)';
  }

  public getUsageOfTotal(row: any, key: string): any {
    const usage = row[key];
    const percent = +usage.usage / Math.max(+usage.total, 1) * 100;
    return {
      usage: usage.usage,
      total: usage.total,
      percent: percent
    }
  }
}
