import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CorePortalPermissionGuard } from 'libs/core-portal/src';
import { PathsDetailComponent, PathsListComponent } from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from 'libs/core-shared/src';

export const ORGA_PORTAL_KNOWLEDGE_PATHS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: PathsListComponent,
    data: {
      permissions: [AppPermissions.DisplayKnowledge]
    }
  },
  {
    path: ':knowledgePathId',
    component: PathsDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadKnowledgeArticle],
      breadcrumbs: I18nBreadcrumbResolver,
      text: 'orga-portal.knowledge.subtitles.path-detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ORGA_PORTAL_KNOWLEDGE_PATHS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class PathsRoutingModule {
}
