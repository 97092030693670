import {BaseXsStore, BaseXsStoreActions, BaseXsStoreReducerTypes, PropsAction} from '@nexnox-web/core-store';
import {
  ResourcesGuiXsStoreSelectByIdPayload,
  ResourcesGuiXsStoreSelectByIdSuccessPayload
} from './resources-gui-xs-store.payload';
import {createAction, createSelector, MemoizedSelector, props} from '@ngrx/store';
import {immerOn} from 'ngrx-immer/store';

export interface ResourcesGuiXsStoreState {
  listLoading: boolean;
  listLoaded: boolean;
  detailLoading: boolean;
  detailLoaded: boolean;
  isSelected: boolean;
}

export interface ResourcesGuiXsStoreActions extends BaseXsStoreActions {
  selectById: PropsAction<ResourcesGuiXsStoreSelectByIdPayload>;
  selectByIdSuccess: PropsAction<ResourcesGuiXsStoreSelectByIdSuccessPayload>;
}

export interface ResourcesGuiXsStoreSelectors {
  selectListLoading: MemoizedSelector<ResourcesGuiXsStoreState, boolean>;
  selectDetailLoading: MemoizedSelector<ResourcesGuiXsStoreState, boolean>;
  selectDetailLoaded: MemoizedSelector<ResourcesGuiXsStoreState, boolean>;
  selectIsSelected: MemoizedSelector<ResourcesGuiXsStoreState, boolean>;
}

export class ResourcesGuiXsStore extends BaseXsStore<ResourcesGuiXsStoreState> {
  public actions: ResourcesGuiXsStoreActions;
  public selectors: ResourcesGuiXsStoreSelectors;

  public getInitialState(): ResourcesGuiXsStoreState {
    return {
      ...super.getInitialState(),
      listLoading: false,
      listLoaded: false,
      detailLoading: false,
      detailLoaded: false,
      isSelected: false
    };
  }

  protected createActions(label: string): ResourcesGuiXsStoreActions {
    return {
      ...super.createActions(label),

      selectById: createAction(
        BaseXsStore.getType(label, 'Select by id'),
        props<ResourcesGuiXsStoreSelectByIdPayload>()
      ),
      selectByIdSuccess: createAction(
        BaseXsStore.getType(label, 'Select by id success'),
        props<ResourcesGuiXsStoreSelectByIdSuccessPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: ResourcesGuiXsStoreState
  ): BaseXsStoreReducerTypes<ResourcesGuiXsStoreState, ResourcesGuiXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.selectById, (draft, { from }) => {
        if (from === 'list') {
          draft.detailLoading = true;
          return;
        } else if (from === 'detail') {
          draft.listLoading = true;
          return;
        }

        draft.detailLoading = true;
        draft.listLoading = true;
      }),
      immerOn(this.actions.selectByIdSuccess, (draft, { resource }) => {
        draft.listLoading = false;
        draft.listLoaded = true;
        draft.detailLoading = false;
        draft.detailLoaded = true;
        draft.isSelected = Boolean(resource);
      })
    ];
  }

  protected createSelectors(): ResourcesGuiXsStoreSelectors {
    const stateSelector = this.options.stateSelector;

    return {
      ...super.createSelectors(),

      selectListLoading: createSelector(stateSelector, state => state.listLoading),
      selectDetailLoading: createSelector(stateSelector, state => state.detailLoading),
      selectIsSelected: createSelector(stateSelector, state => state.isSelected),
      selectDetailLoaded: createSelector(stateSelector, state => state.detailLoaded)
    };
  }
}
