import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {AppTenantDto, Brands, Seeds} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';

const seedEnumOptions = [
  { label: 'core-portal.service.tenants.seed-types.0', value: Seeds.Semco }
];

const brandOptions = [
  { label: 'core-portal.service.tenants.brand-types.0', value: Brands.Nexnox },
  { label: 'core-portal.service.tenants.brand-types.1', value: Brands.Semco },
  { label: 'core-portal.service.tenants.brand-types.2', value: Brands.Pilona },
  { label: 'core-portal.service.tenants.brand-types.3', value: Brands.Winterhalter }
];

const tenantActiveOptions = [
  { label: 'core-portal.service.tenants.tenant-active-options.1', value: true },
  { label: 'core-portal.service.tenants.tenant-active-options.0', value: false }
];

@Component({
  selector: 'nexnox-web-service-tenants-tenant-edit',
  templateUrl: './tenant-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantEditComponent extends CorePortalEntityEditBaseComponent<AppTenantDto> {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'displayName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.display-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'name',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text',
          titleKey: 'displayName',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'domain',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            description: 'core-portal.service.tenants.descriptions.domain-suffix',
            label: 'core-shared.shared.fields.domain',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'brand',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: brandOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.brand',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: brandOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: brandOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'internalCustomNumber',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.internal-customer-number',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'seed',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: Seeds.Semco,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.seed',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: seedEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: seedEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.readonly': () => true
        }
      },

      {
        key: 'isActive',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: true,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-active',
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: tenantActiveOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: tenantActiveOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => true,
          'templateOptions.readonly': () => true
        }
      },
      {
        type: 'core-portal-divider',
        className: 'col-md-12',
        hideExpression: () => this.creating
      },
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 mb-2 px-0 field-group-mb-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.service.tenants.fields.booked-volume',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'activeUserVolume',
            type: 'input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-3',
            templateOptions: {
              type: 'number',
              min: 0,
              corePortalTranslated: {
                label: 'core-shared.shared.fields.active-user-volume',
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },
          {
            key: 'activeLocationVolume',
            type: 'input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-3',
            templateOptions: {
              type: 'number',
              min: 0,
              corePortalTranslated: {
                label: 'core-shared.shared.fields.active-location-volume',
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },
          {
            key: 'activeNotificationRuleVolume',
            type: 'input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-3',
            templateOptions: {
              type: 'number',
              min: 0,
              corePortalTranslated: {
                label: 'core-shared.shared.fields.active-notification-rule-volume',
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },
          {
            key: 'activeGdprRuleVolume',
            type: 'input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-3',
            templateOptions: {
              type: 'number',
              min: 0,
              corePortalTranslated: {
                label: 'core-shared.shared.fields.active-gdpr-rule-volume',
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          }
        ]
      }
    ];
  }
}
