import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {
  CorePortalAttachmentsComponent,
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyActionWrapperTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {
  CorePortalFeatureMasterDataLocationService
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {
  AppEntityType,
  AppPermissions,
  AttachmentForTechDto,
  ContactDto,
  FilterDto,
  FilterOperators,
  FilterTypes,
  IAssignContactDto,
  IAssignResourcDto,
  LinkDto,
  PageableRequest,
  ResourceDto,
  ResourceType,
  StateDto,
  TicketDto
} from '@nexnox-web/core-shared';
import {
  TechPortalLinksComponent,
  TechPortalResourceService,
  TechPortalTicketService
} from '@nexnox-web/tech-portal-lib';
import {
  TechPortalFeatureTicketSettingsPriorityService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {isEqual, isNumber} from 'lodash';
import {ConfirmationService} from 'primeng/api';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, exhaustMap, filter, map, skip, startWith, take} from 'rxjs/operators';
import {
  CorePortalFeatureMasterDataContactService
} from "@nexnox-web/core-portal/features/master-data/features/contacts";
import {TicketAssignContactSidebarComponent, TicketAssignResourceSidebarComponent} from "./../../sidebars";

@Component({
  selector: 'nexnox-web-tickets-ticket-edit',
  templateUrl: './ticket-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureTicketEditComponent extends CorePortalEntityEditBaseComponent<TicketDto> implements OnInit {
  @ViewChild('locationSelectLabelTitleTemplate', {static: true}) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', {static: true}) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  @ViewChild('resourceDescriptionTemplate', {static: true}) public resourceDescriptionTemplate: TemplateRef<any>;

  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('linksComponent') public linksComponent: TechPortalLinksComponent;

  @ViewChild('assignContactSidebar', {static: true}) public assignContactSidebar: TicketAssignContactSidebarComponent;
  @ViewChild('assignResourceSidebar', {static: false}) public assignResourceSidebar: TicketAssignResourceSidebarComponent;

  @Input() public assignContactLoading$: Observable<boolean>;
  @Input() public assignResourceLoading$: Observable<boolean>;

  @Output() public assignContact: EventEmitter<IAssignContactDto> = new EventEmitter<IAssignContactDto>();
  @Output() public unassignContact: EventEmitter<undefined> = new EventEmitter();

  @Output() public assignResource: EventEmitter<IAssignResourcDto> = new EventEmitter<IAssignResourcDto>();
  @Output() public unassignResource: EventEmitter<undefined> = new EventEmitter();

  public attachmentsSubject: BehaviorSubject<AttachmentForTechDto[]> = new BehaviorSubject<AttachmentForTechDto[]>([]);
  public linksSubject: BehaviorSubject<LinkDto[]> = new BehaviorSubject<LinkDto[]>([]);

  public resourceIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public resourceEditableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public resourceSyncSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readSolutionPermission$: Observable<boolean>;

  public isShowResourceAssignmentSidebar = false;

  private refreshLocationSelectSubject: Subject<void> = new Subject<void>();

  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    private resourceService: TechPortalResourceService,
    private locationService: CorePortalFeatureMasterDataLocationService,
    private ticketService: TechPortalTicketService,
    private priorityService: TechPortalFeatureTicketSettingsPriorityService,
    private permissionService: CorePortalPermissionService,
    private confirmationService: ConfirmationService,
    private contactService: CorePortalFeatureMasterDataContactService,
  ) {
    super(injector, 'TechPortalFeatureTicketEditComponent');

    this.readSolutionPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadSolution);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[]): void {
    this.setModel({...this.model, attachments});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onLinksChange(links: LinkDto[]): void {
    this.setModel({...this.model, links});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onAssignResource(payload: IAssignResourcDto): void {
    if (this.creating) {
      this.onResourceChange(payload.resource)
    } else {
      this.assignResource.emit(payload)
    }
  }

  public onResourceChange(resource: ResourceDto): void {
    this.setModel({
      ...this.model,
      resource: resource ?? null,
      location: resource?.location ?? null
    });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onModelChange(model: TicketDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      links: this.linksComponent ? this.linksComponent.isModelValid() : true,
      attachments: this.attachmentsComponent ? this.attachmentsComponent.isModelValid() : true
    });

    super.onModelChange(model);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      ...this.getCurrentStateField(),
      ...this.getStereotypeFields(false, this.creating),
      {
        key: 'priority',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.priority',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.priorityService,
          idKey: 'priorityId',
          displayKey: 'name',
          wholeObject: true,
          clearable: false,
          defaultFilters$: this.selectedStereotype$.pipe(
            map(stereotype => {
              if (!stereotype || stereotype.entityType !== AppEntityType.Ticket) {
                return [];
              }

              return [{
                property: 'prioritySetId',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: stereotype.prioritySet?.prioritySetId?.toString()
              }] as FilterDto[];
            })
          ),
          firstToDefault: true,
          skipGetOne: true,
          waitUntil$: this.selectedStereotype$.pipe(
            filter(selectedStereotype => Boolean(selectedStereotype)),
            take(1)
          )
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly || !isNumber(this.model?.stereotypeId)
        },
        hideExpression: () => !this.creating,
        hooks: {
          onInit: field => {
            this.subscribe(this.selectedStereotype$.pipe(
              filter(stereotype => Boolean(stereotype)),
              skip(1),
              distinctUntilChanged()
            ), () => field.formControl.setValue(null));
          }
        }
      },
      {
        key: 'resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-actions', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'tickets.fields.resource',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            },
            descriptionTemplate: this.resourceDescriptionTemplate
          } as CorePortalFormlyTranslatedTyping,
          corePortalActionWrapper: {
            actions: [
              {
                icon: faPencilAlt,
                tooltip: 'core-portal.core.general.edit',
                loading$: this.assignResourceLoading$,
                onClick: () => this._openResourceSidebar(),
                permissions$: [this.permissionService.hasPermission$(AppPermissions.AssignTicketResource)]
              }
            ]
          } as CorePortalFormlyActionWrapperTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
            module: 'inventory',
            suffix: (model) => model?.resource?.location && this.creating && model?.resource?.type === ResourceType.Device ? `(${model.resource.location.name})` : '',
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
          module: 'inventory',
        },
        expressionProperties: {
          'templateOptions.readonly': () => true,
        },
        hooks: {
          onInit: field => {
            this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b)),
              skip(1)
            ), (value: ResourceDto) => {
              this.resourceIdSubject.next(value?.resourceId ?? null);
            });
          }
        }
      },
      {
        key: 'editor',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-actions', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.editor'
          },
          corePortalActionWrapper: {
            actions: [{
              icon: faPencilAlt,
              tooltip: 'core-portal.core.general.edit',
              loading$: this.assignContactLoading$,
              hidden: (model) => this.creating,
              onClick: () => this.assignContactSidebar.onShow(),
              permissions$: [this.permissionService.hasPermission$(AppPermissions.AssignTicketEditor)]
            }]
          } as CorePortalFormlyActionWrapperTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'displayName',
            link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          skipGetOne: true,
          link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
          module: 'management'
        },
        expressionProperties: {
          'templateOptions.readonly': () => !this.creating
        }
      },
      {
        key: 'description',
        type: 'core-portal-editor',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.description',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalEditor: {
            language: this.translate.currentLang
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      }
    ];
  }

  private getCurrentStateField(): FormlyFieldConfig[] {
    if (!this.creating) {
      return [];
    }

    /* istanbul ignore next */
    return [
      {
        key: 'currentState',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'tickets.fields.current-state',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.ticketService,
          idKey: 'stateId',
          displayKey: 'name',
          firstToDefault: true,
          clearable: false,
          wholeObject: true,
          skipGetOne: true,
          link: (state: StateDto) => state?.stateId ? [ 'states', state.stateId] : null,
          module: 'settings',
          refresh$: this.selectedStereotype$.pipe(
            distinctUntilChanged(),
            skip(2)
          ),
          overrideGetPage: (pageNumber: number, pageSize: number, filters: FilterDto[]) => this.selectedStereotype$.pipe(
            filter(selectedStereotype => Boolean(selectedStereotype)),
            debounceTime(400),
            exhaustMap(selectedStereotype => this.ticketService.getPreviewStates(selectedStereotype.stateMachine?.stateMachineId).pipe(
              map(response => ({
                items: response.nextStates,
                paging: {
                  totalPages: 1,
                  pageNumber: 1,
                  pageSize: response.nextStates.length,
                  totalItems: response.nextStates.length
                }
              } as PageableRequest<any>))
            )),
            take(1)
          ).toPromise()
        },
        expressionProperties: {
          'templateOptions.disabled': () => !isNumber(this.model?.stereotypeId),
          'templateOptions.required': () => this.creating
        },
        hooks: {
          onInit: field => {
            this.subscribe(this.selectedStereotype$.pipe(
              filter(stereotype => Boolean(stereotype)),
              skip(1),
              distinctUntilChanged()
            ), () => field.formControl.setValue(null));
          }
        }
      }
    ];
  }

  protected setModel(model: TicketDto): void {
    super.setModel(model);

    this.attachmentsSubject.next(model?.attachments ?? []);
    this.linksSubject.next(model?.links ?? []);
  }

  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.attachmentsComponent?.onReset();
    this.linksComponent?.onReset();

    if (this.resourceEditableSubject.getValue()) {
      this.resourceEditableSubject.next(!readonly);
      this.resourceSyncSubject.next(false);
    }
  }

  private _openResourceSidebar(): void {
    // Workaround for saved filters to prevent sidebar from loading table
    // before a location has been initialized
    this.isShowResourceAssignmentSidebar = true;
    setTimeout(() => {
      this.assignResourceSidebar.onShow();
      this.subscribe(this.assignResourceSidebar.visible$, visible => {
        this.isShowResourceAssignmentSidebar = visible;
      });
    });
  }
}
