<nexnox-web-tabs [internal]="true"
                 [isTransparent]="true"
                 startTab="issue-template-edit">

  <!-- Issue template edit tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="issue-template-edit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid()">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this" displayKey="name"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Issue template notification edit tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="notifications"
               [heading]="'core-portal.settings.issue-template.notifications' | translate">
    <nexnox-web-settings-notification-issue [notifications]="notifications"
                                            [issueContentType]="issueContentType"
                                            [readonly]="readonly"
                                            [loading]="loading"
                                            (notificationsChanged)="onNotificationsChanged($event)">
    </nexnox-web-settings-notification-issue>
  </ng-template>

  <!-- Issue template published resources tab -->
  <ng-template *ngIf="readonly || !creating"
               nexnoxWebTabTemplate
               tabId="published-resources"
               [heading]="'core-portal.settings.issue-template.published-resources' | translate">
    <nexnox-web-settings-issue-published-resources [resources]="resources"
                                                   [readonly]="readonly"
                                                   [loading]="loading">
    </nexnox-web-settings-issue-published-resources>
  </ng-template>
</nexnox-web-tabs>
