import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreCreateOnePayload,
  PagedEntitiesXsStoreEffects,
  pagedEntitiesXsStoreSetLoadingForId,
  PagedEntitiesXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import { AppEntityType, ContactSimpleDto, CoreSharedApiBaseService, MissionDto, MissionType } from '@nexnox-web/core-shared';
import { Action, createAction, on, props, select } from '@ngrx/store';
import { Injectable, Injector, Type } from '@angular/core';
import { TechPortalFeatureMissionService } from '../../services';
import { createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, groupBy, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  MissionListXsStoreAssignToMePayload,
  MissionListXsStoreAssignToPayload,
  MissionListXsStoreChangeStatePayload,
  MissionListXsStoreChangeStateSuccessPayload,
  MissionListXsStoreCompleteOrResumePayload,
  MissionListXsStoreExportPayload,
  MissionListXsStoreExportSuccessPayload
} from './mission-list-xs-store.payloads';
import { authStore, CorePortalCCPControlPointService } from '@nexnox-web/core-portal';
import { TranslateService } from '@ngx-translate/core';
import { immerOn } from 'ngrx-immer/store';

export interface MissionListXsStoreActions extends PagedEntitiesXsStoreActions<MissionDto> {
  createOneManually: PropsAction<PagedEntitiesXsStoreCreateOnePayload<MissionDto>>;
  createOneByInspection: PropsAction<PagedEntitiesXsStoreCreateOnePayload<MissionDto>>;
  startOneByInspection: PropsAction<PagedEntitiesXsStoreCreateOnePayload<MissionDto>>;

  changeState: PropsAction<MissionListXsStoreChangeStatePayload>;
  changeStateSuccess: PropsAction<MissionListXsStoreChangeStateSuccessPayload>;

  complete: PropsAction<MissionListXsStoreCompleteOrResumePayload>;
  completeSuccess: PropsAction<MissionListXsStoreCompleteOrResumePayload>;

  resume: PropsAction<MissionListXsStoreCompleteOrResumePayload>;
  resumeSuccess: PropsAction<MissionListXsStoreCompleteOrResumePayload>;

  export: PropsAction<MissionListXsStoreExportPayload>;
  exportSuccess: PropsAction<MissionListXsStoreExportSuccessPayload>;

  assignToMe: PropsAction<MissionListXsStoreAssignToMePayload>;
  assignToMeSuccess: PropsAction<MissionListXsStoreAssignToPayload>;

  assignTo: PropsAction<MissionListXsStoreAssignToPayload>;
  assignToSuccess: PropsAction<MissionListXsStoreAssignToPayload>;
}

export class MissionListXsStore extends PagedEntitiesXsStore<MissionDto> {
  public actions: MissionListXsStoreActions;

  protected createActions(label: string): MissionListXsStoreActions {
    return {
      ...super.createActions(label),

      createOneManually: createAction(
        BaseXsStore.getType(label, 'Create one manually'),
        props<PagedEntitiesXsStoreCreateOnePayload<MissionDto>>()
      ),
      createOneByInspection: createAction(
        BaseXsStore.getType(label, 'Create one by inspection'),
        props<PagedEntitiesXsStoreCreateOnePayload<MissionDto>>()
      ),
      startOneByInspection: createAction(
        BaseXsStore.getType(label, 'Start one by inspection'),
        props<PagedEntitiesXsStoreCreateOnePayload<MissionDto>>()
      ),

      changeState: createAction(
        BaseXsStore.getType(label, 'Change state'),
        props<MissionListXsStoreChangeStatePayload>()
      ),
      changeStateSuccess: createAction(
        BaseXsStore.getType(label, 'Change state success'),
        props<MissionListXsStoreChangeStateSuccessPayload>()
      ),

      complete: createAction(
        BaseXsStore.getType(label, 'Complete'),
        props<MissionListXsStoreCompleteOrResumePayload>()
      ),
      completeSuccess: createAction(
        BaseXsStore.getType(label, 'Complete success'),
        props<MissionListXsStoreCompleteOrResumePayload>()
      ),

      resume: createAction(
        BaseXsStore.getType(label, 'Resume'),
        props<MissionListXsStoreCompleteOrResumePayload>()
      ),
      resumeSuccess: createAction(
        BaseXsStore.getType(label, 'Resume success'),
        props<MissionListXsStoreCompleteOrResumePayload>()
      ),

      export: createAction(
        BaseXsStore.getType(label, 'Export'),
        props<MissionListXsStoreExportPayload>()
      ),
      exportSuccess: createAction(
        BaseXsStore.getType(label, 'Export success'),
        props<MissionListXsStoreExportSuccessPayload>()
      ),

      assignToMe: createAction(
        BaseXsStore.getType(label, 'Assign to me'),
        props<MissionListXsStoreAssignToMePayload>()
      ),
      assignToMeSuccess: createAction(
        BaseXsStore.getType(label, 'Assign to me success'),
        props<MissionListXsStoreAssignToPayload>()
      ),

      assignTo: createAction(
        BaseXsStore.getType(label, 'Assign to'),
        props<MissionListXsStoreAssignToPayload>()
      ),
      assignToSuccess: createAction(
        BaseXsStore.getType(label, 'Assign to success'),
        props<MissionListXsStoreAssignToPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<MissionDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<MissionDto>, MissionListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.createOneManually, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.createOneByInspection, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.startOneByInspection, draft => {
        draft.loading = true;
      }),


      immerOn(this.actions.changeState, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          changeState: true
        });
      }),
      on(this.actions.changeStateSuccess, (state, {
        id,
        state: missionState,
        plannedStart,
        plannedEnd,
        actualStart,
        actualEnd,
        lastEmailSend
      }) => this.adapter.updateOne({
        id: id.toString(),
        changes: {
          entity: {
            ...state.entities[id.toString()].entity,
            state: missionState,
            plannedStart,
            plannedEnd,
            actualStart,
            actualEnd,
            lastEmailSend
          },
          model: {
            ...state.entities[id.toString()].model,
            state: missionState,
            plannedStart,
            plannedEnd,
            actualStart,
            actualEnd,
            lastEmailSend
          }
        }
      }, {
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          changeState: false
        })
      })),

      immerOn(this.actions.complete, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          complete: true
        });
      }),
      on(this.actions.completeSuccess, (state, {id}) =>
        this.adapter.updateOne({
          id: id.toString(),
          changes: {
            entity: {
              ...state.entities[id.toString()].entity,
              isCompleted: true
            },
            model: {
              ...state.entities[id.toString()].model,
              isCompleted: true
            }
          }
        }, {
          ...state,
          entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
            complete: false
          })
        })),

      immerOn(this.actions.resume, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          resume: true
        });
      }),
      on(this.actions.resumeSuccess, (state, {
        id
      }) => this.adapter.updateOne({
        id: id.toString(),
        changes: {
          entity: {
            ...state.entities[id.toString()].entity,
            isCompleted: false
          },
          model: {
            ...state.entities[id.toString()].model,
            isCompleted: false
          }
        }
      }, {
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          resume: false
        })
      })),

      immerOn(this.actions.export, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          export: true
        });
      }),
      immerOn(this.actions.exportSuccess, (draft, { id }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
          export: false
        });
      }),

      on(this.actions.assignToMe, (state, { id }) => ({
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          assignToMe: true
        })
      })),
      on(this.actions.assignToMeSuccess, (state, {
        id,
        contact
      }) => this.adapter.updateOne({
        id: id.toString(),
        changes: {
          entity: {
            ...state.entities[id.toString()].entity,
            solutionContact: contact
          },
          model: {
            ...state.entities[id.toString()].model,
            solutionContact: contact
          }
        }
      }, {
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          assignToMe: false
        })
      })),

      on(this.actions.assignTo, (state, { id }) => ({
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          assignTo: true
        })
      })),
      on(this.actions.assignToSuccess, (state, {
        id,
        contact
      }) => this.adapter.updateOne({
        id: id.toString(),
        changes: {
          entity: {
            ...state.entities[id.toString()].entity,
            solutionContact: contact
          },
          model: {
            ...state.entities[id.toString()].model,
            solutionContact: contact
          }
        }
      }, {
        ...state,
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
          assignTo: false
        })
      }))
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: MissionDto) => MissionDto,
    prepareModel: (entity: MissionDto, model: MissionDto) => MissionDto,
    sanitizeModel: (model: MissionDto, entity: MissionDto) => MissionDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<MissionDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<MissionDto> {
      public createOneManually$: any;
      public createOneByInspection$: any;
      public startOneByInspection$: any;

      public changeState$: Observable<Action>;
      public changeStateSuccess$: Observable<Action>;

      public complete$: Observable<Action>;
      public completeSuccess$: Observable<Action>;

      public resume$: Observable<Action>;
      public resumeSuccess$: Observable<Action>;

      public export$: Observable<Action>;
      public exportSuccess$: Observable<Action>;

      public assignToMe$: Observable<Action>;
      public assignToMeSuccess$: Observable<Action>;

      public assignTo$: Observable<Action>;
      public assignToSuccess$: Observable<Action>;

      protected actions: MissionListXsStoreActions;
      protected service: TechPortalFeatureMissionService;
      protected controlPointService: CorePortalCCPControlPointService;

      constructor(
        protected injector: Injector,
        private translate: TranslateService
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);

        this.controlPointService = injector.get(CorePortalCCPControlPointService);
      }

      protected createEffects(): void {
        super.createEffects();

        this.createOneManually$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.createOneManually),
          withLatestFrom(this.store.pipe(select(this.tenantIdSelector))),
          switchMap(([{ model, parentIds }, tenantId]) => {
            const sanitizedModel = this.prepareModelForCreate({ ...model, type: MissionType.Manual }, tenantId);
            return this.service.createOneManually(sanitizedModel, parentIds).pipe(
              map(entity => this.actions.createOneSuccess({
                entity: this.prepareEntity(entity),
                model: this.prepareModel(this.prepareEntity(entity), {} as any),
                parentIds
              })),
              catchError(error => of(this.actions.error({ error, action: this.actions.createOneManually })))
            );
          })
        ));

        this.createOneByInspection$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.createOneByInspection),
          withLatestFrom(this.store.pipe(select(this.tenantIdSelector))),
          switchMap(([{ model }, tenantId]) => {
            const sanitizedModel = this.prepareModelForCreate({ ...model }, tenantId);
            return this.controlPointService.createMissionByInspection(sanitizedModel).pipe(
              map(entity => this.actions.createOneSuccess({
                entity: this.prepareEntity(entity),
                model: this.prepareModel(this.prepareEntity(entity), {} as any)
              })),
              catchError(error => of(this.actions.error({ error, action: this.actions.createOneByInspection })))
            );
          })
        ));

        this.startOneByInspection$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.startOneByInspection),
          withLatestFrom(this.store.pipe(select(this.tenantIdSelector))),
          switchMap(([{ model }, tenantId]) => {
            const sanitizedModel = this.prepareModelForCreate({ ...model }, tenantId);
            return this.controlPointService.startMissionByInspection(sanitizedModel).pipe(
              map(entity => this.actions.createOneSuccess({
                entity: this.prepareEntity(entity),
                model: this.prepareModel(this.prepareEntity(entity), {} as any)
              })),
              catchError(error => of(this.actions.error({ error, action: this.actions.startOneByInspection })))
            );
          })
        ));

        /* Change state */
        this.changeState$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.changeState),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({ id, state }) => this.service.changeState(id, state).pipe(
              map(({ plannedStart, plannedEnd, actualStart, actualEnd, lastEmailSend }) => this.actions.changeStateSuccess({
                id,
                state,
                plannedStart,
                plannedEnd,
                actualStart,
                actualEnd,
                lastEmailSend,
                name: this.translate.instant(`missions.mission-states.${state}`)
              })),
              catchError(error => of(this.actions.error({ error, action: this.actions.changeState })))
            ))
          ))
        ));

        this.changeStateSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.changeStateSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        /* Complete */
        this.complete$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.complete),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({ id }) => this.service.complete(id).pipe(
              map(() => this.actions.completeSuccess({id})),
              catchError(error => of(this.actions.error({ error, action: this.actions.complete })))
            ))
          ))
        ));

        this.completeSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.completeSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        /* Resume */
        this.resume$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.resume),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({ id }) => this.service.resume(id).pipe(
              map(() => this.actions.resumeSuccess({id})),
              catchError(error => of(this.actions.error({ error, action: this.actions.resume })))
            ))
          ))
        ));

        this.resumeSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.resumeSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        /* Export */
        this.export$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.export),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({ id, templateId }) => this.service.export(id, templateId).pipe(
              map(({ uri }) => this.actions.exportSuccess({ id, templateId, uri })),
              catchError(error => of(this.actions.error({ error, action: this.actions.export })))
            ))
          ))
        ));

        this.exportSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.exportSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        /* Assign to me */
        this.assignToMe$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.assignToMe),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({ id }) => this.service.assignToMe(id).pipe(
              withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
              map(([_, tenant]) => this.actions.assignToMeSuccess({
                id,
                contact: (tenant?.names ?? []).length ? tenant.names[0] : null
              })),
              catchError(error => of(this.actions.error({ error, action: this.actions.assignToMe })))
            ))
          ))
        ));

        this.assignToMeSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.assignToMeSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        /* Assign to */
        this.assignTo$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.assignTo),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            exhaustMap(({ id, contact, parentIds }) => this.service.assignTo(id, contact.contactId, parentIds).pipe(
              map(() => this.actions.assignToSuccess({ id, contact, parentIds })),
              catchError(error => of(actions.error({ error, action: this.actions.assignTo })))
            ))
          ))
        ));

        this.assignToSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.assignToSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.changeStateSuccess, action, ({ name }) => this.changeStateSuccessActionCallback(name));
        this.checkAction(this.actions.completeSuccess, action, () => this.completeSuccessActionCallback());
        this.checkAction(this.actions.resumeSuccess, action, () => this.resumeSuccessActionCallback());
        this.checkAction(this.actions.exportSuccess, action, ({ uri }) => this.exportSuccessActionCallback(uri));
        this.checkAction(this.actions.assignToMeSuccess, action, () => this.assignToMeSuccessActionCallback());
        this.checkAction(this.actions.assignToSuccess, action, ({ contact }) => this.assignToSuccessActionCallback(contact));
      }

      protected changeStateSuccessActionCallback(name: string): void {
        this.apiNotificationService.showTranslatedSuccess('missions.toasts.state-changed', { name });
      }

      protected completeSuccessActionCallback(): void {
        this.apiNotificationService.showTranslatedSuccess('missions.toasts.completed');
      }

      protected resumeSuccessActionCallback(): void {
        this.apiNotificationService.showTranslatedSuccess('missions.toasts.resumed');
      }

      protected exportSuccessActionCallback(uri: string): void {
        window.open(uri, '_blank');
      }

      protected assignToMeSuccessActionCallback(): void {
        this.apiNotificationService.showTranslatedSuccess('missions.toasts.assign-to-me');
      }

      protected assignToSuccessActionCallback(contact: ContactSimpleDto): void {
        this.apiNotificationService.showTranslatedSuccess('missions.toasts.assign-to', { contact: contact.displayName });
      }
    }

    return Effects;
  }
}
