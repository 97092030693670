import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActionButton, CorePortalActionBarService, CorePortalPermissionService} from '../../../../services';
import {combineLatest, isObservable, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, map, switchMap, take} from 'rxjs/operators';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";

interface LocalAction extends ActionButton {
  buttonsValue?: LocalAction[];
  shouldShowValue?: boolean;
  isDisabledValue?: boolean;
  isSplitDisabledValue?: boolean;
  isLoadingValue?: boolean;
}

@Component({
  selector: 'nexnox-web-layout-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionBarComponent implements OnInit {
  public actions$: Observable<LocalAction[]>;
  public leftActions$: Observable<LocalAction[]>;
  public rightActions$: Observable<LocalAction[]>;
  public isOneShowing$: Observable<boolean>;

  public trackActionsBy: any;

  private subscription: Subscription;

  constructor(
    private actionBarService: CorePortalActionBarService,
    private permissionService: CorePortalPermissionService
  ) {
  }

  public getAsyncIcon(icon: IconDefinition | Observable<IconDefinition>): Observable<IconDefinition> {
    return (icon instanceof Observable) ? icon : of(icon);
  }

  public ngOnInit(): void {
    this.actions$ = this.actionBarService.actions$.pipe(
      distinctUntilChanged(),
      switchMap(actions => actions?.length ? combineLatest(actions.map(action => this.getActionMapping(action))) : of([])),
      catchError((error, caught) => {
        // Bugfix: Sometimes action observable-streams get empty on route change (scope changes before reset)
        // v v v actions$ observable gets reinitialized that way after empty error caught
        this.ngOnInit();
        return of([]);
      })
    );

    this.leftActions$ = this.actions$.pipe(
      map(actions => actions.filter(x => !x.alignRight))
    );
    this.rightActions$ = this.actions$.pipe(
      map(actions => actions.filter(x => x.alignRight))
    );

    this.isOneShowing$ = this.actions$.pipe(
      map(actionsShown => actionsShown.some(x => x.shouldShowValue))
    );

    this.trackActionsBy = (index: number, action: ActionButton) => index;
  }

  public isDropDown(actionType): Observable<boolean> {
    let isDropDown = false;
    if (isObservable(actionType)) {
      this.subscription = actionType.pipe(take(1)).subscribe({
          next: (type) => {
            isDropDown = type === 'dropdown';
          },
          complete: () => {
            this.subscription?.unsubscribe();
          }
        }
      )
    } else {
      isDropDown = actionType === 'dropdown'
    }
    return of(isDropDown);
  }

  private getActionMapping(action: ActionButton): Observable<LocalAction> {
    return this.actionShouldShow(action).pipe(
      distinctUntilChanged(),
      switchMap(shouldShow => this.actionIsDisabled(action).pipe(
        distinctUntilChanged(),
        switchMap(isDisabled => this.actionIsSplitDisabled(action).pipe(
          distinctUntilChanged(),
          switchMap(isSplitDisabled => this.actionIsLoading(action).pipe(
            distinctUntilChanged(),
            switchMap(isLoading => {
              let nextObservable: Observable<LocalAction[] | undefined>;

              if (action.buttons) {
                nextObservable = action.buttons.pipe(
                  switchMap(buttons =>
                    buttons?.length ? combineLatest(buttons.map(button => this.getActionMapping(button))) : of([])),
                  catchError(error => throwError(error))
                );
              } else {
                nextObservable = of(undefined);
              }

              return nextObservable.pipe(
                map(buttons => ({
                  ...action,
                  buttonsValue: buttons,
                  shouldShowValue: shouldShow,
                  isDisabledValue: isDisabled,
                  isSplitDisabledValue: isSplitDisabled,
                  isLoadingValue: isLoading
                } as LocalAction))
              );
            })
          )),
          catchError(error => throwError(error))
        )),
        catchError(error => throwError(error))
      )),
      catchError(error => throwError(error)),
    );
  }

  private actionShouldShow(action: ActionButton): Observable<boolean> {
    const getShouldShow = (): Observable<boolean> => action.shouldShow().pipe(
      switchMap(shouldShow => {
        if (action.permission) {
          return this.permissionService.hasPermission$(action.permission).pipe(
            map(hasPermission => shouldShow && hasPermission)
          );
        }

        return of(shouldShow);
      })
    );

    if (action.shouldShow) {
      if (action.type && isObservable(action.type)) {
        this.subscription = action.type?.pipe(take(1)).subscribe({
            next: (type) => {
              if (type === 'button') {
                return getShouldShow();
              }
            },
            complete: () => {
              this.subscription?.unsubscribe();
            }
          }
        );
      } else if (action.type === 'button') {
        return getShouldShow();
      }
    } else if (action.type) {
      let isDropDown = action.type === 'dropdown';
      if (isObservable(action.type)) {
        this.subscription = action.type?.pipe(take(1)).subscribe({
            next: (type) => {
              isDropDown = type === 'dropdown';
            },
            complete: () => {
              this.subscription?.unsubscribe();
            }
          }
        )
      }
      if (isDropDown) {
        const dropdownShown$ = action.buttons.pipe(
          switchMap(buttons => combineLatest([
            of(false),
            ...buttons.map(childButton => this.actionShouldShow(childButton))
          ])),
          map(buttonsShown => buttonsShown.some(x => Boolean(x)))
        );

        if (action.shouldShow) {
          return getShouldShow();
        }

        return dropdownShown$.pipe(
          switchMap(dropdownShown => {
            if (action.permission) {
              return this.permissionService.hasPermission$(action.permission).pipe(
                map(hasPermission => dropdownShown && hasPermission)
              );
            }

            return of(dropdownShown);
          })
        );
      }
    }

    return action.permission ? this.permissionService.hasPermission$(action.permission) : of(true);
  }

  private actionIsDisabled(action: ActionButton): Observable<boolean> {
    if (action.isDisabled) {
      return action.isDisabled().pipe(
        switchMap(isDisabled => this.actionIsLoading(action).pipe(
          map(loading => isDisabled || loading)
        ))
      );
    }

    return this.actionIsLoading(action);
  }

  private actionIsSplitDisabled(action: ActionButton): Observable<boolean> {
    return this.actionIsDisabled(action).pipe(
      switchMap(isDisabled => this.actionIsLoading(action).pipe(
        switchMap(isLoading => {
          if (action.isSplitDisabled) {
            return action.isSplitDisabled().pipe(
              map(isSplitDisabled => {
                if (isLoading) {
                  return true;
                }

                if (!isSplitDisabled) {
                  return false;
                }

                return isDisabled || isSplitDisabled;
              })
            );
          }

          return of(isDisabled);
        })
      ))
    );
  }

  private actionIsLoading(action: ActionButton): Observable<boolean> {
    if (action.isLoading) {
      return action.isLoading();
    }

    return of(false);
  }
}
