<ng-template #entityEditTemplate
             let-title="title"
             let-id="id">
    <div class="row w-100">
        <div class="col-xl-8 pl-0 pr-0 pr-xl-2">
            <nexnox-web-tickets-ticket-edit #ticketEditComponent
                                            #modelComponent
                                            [title]="title"
                                            [id]="id"
                                            [model]="model$ | async"
                                            [readonly]="readonly$ | async"
                                            [creating]="false"
                                            [loading]="loading$ | async"
                                            [stereotypes$]="stereotypes$"
                                            [assignContactLoading$]="loadingAssignContact$"
                                            [assignResourceLoading$]="loadingAssignRessource$"
                                            (assignContact)="onAssignContact($event)"
                                            (unassignContact)="onUnassignContact()"
                                            (assignResource)="onAssignResource($event)"
                                            (unassignResource)="onUnassignResource()"
                                            (modelChange)="onModelChange($event)">
            </nexnox-web-tickets-ticket-edit>
        </div>

        <div class="col-xl-4 pl-0 pl-xl-2 pr-0">
            <nexnox-web-tickets-ticket-status-edit #modelComponent
                                                   [title]="'tickets.subtitles.ticket-status' | translate:{
                                              state: (model$ | async)?.currentState?.name
                                             }"
                                                   [model]="model$ | async"
                                                   [readonly]="readonly$ | async"
                                                   [creating]="false"
                                                   [loading]="loading$ | async"
                                                   [stereotypes$]="stereotypes$"
                                                   [stereotyped]="false"
                                                   (modelChange)="onModelChange($event)"
                                                   (editEditor)="onAssignToAction()">
            </nexnox-web-tickets-ticket-status-edit>

            <nexnox-web-tickets-ticket-events *ngIf="passedIds"
                                              [isEmbedded]="true"
                                              [canCreate]="false"
                                              [parentIds]="passedIds"
                                              [getEntityOnModeChange]="false"
                                              [loading]="loading$ | async">
            </nexnox-web-tickets-ticket-events>

            <nexnox-web-tickets-ticket-mission-links [model$]="model$"
                                                     [loading]="loading$ | async">
            </nexnox-web-tickets-ticket-mission-links>

            <nexnox-web-tickets-related-ticket-links [model$]="model$"
                                                     [loading]="loading$ | async">
            </nexnox-web-tickets-related-ticket-links>


            <ng-container *ngIf="model$ | async as model">
                <nexnox-web-entity-labels *ngIf="displayLabelPermission$ | async"
                                          [tenantId]="tenantId$ | async"
                                          [entityId]="this.id"
                                          [labelType]="labelType"
                                          [isAutomaticLabeling$]="isLabelingSince$"
                                          [labels]="labels$ | async"
                                          [loading]="loading$ | async"
                                          (labelsChange)="onLabelsChange($event, model)">
                </nexnox-web-entity-labels>
            </ng-container>
        </div>
    </div>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="label"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
    <!-- Solution Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="solution"
                 *ngIf="readSolutionPermission$ | async"
                 [heading]="'solutions.subtitles.solution' | translate"
                 (selectTab)="onNavigateToTab(['solution'])">
        <ng-container *ngIf="model$ | async as model">
            <nexnox-web-tickets-ticket-solution *ngIf="passedIds"
                                                [isEmbedded]="true"
                                                [canCreate]="true"
                                                [solution]="$any(model?.solution)"
                                                [hasChat]="model?.hasChat"
                                                [relationId]="model?.ticketId"
                                                relationIdProperty="ticketId">
            </nexnox-web-tickets-ticket-solution>
        </ng-container>
    </ng-template>

    <!-- Missions Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="missions"
                 *ngIf="readMissionPermission$ | async"
                 [heading]="'missions.subtitles.missions' | translate"
                 [lazy]="true"
                 (selectTab)="onNavigateToTab(['missions'])">
        <ng-container *ngIf="model$ | async as model">
            <nexnox-web-tickets-ticket-missions *ngIf="passedIds && model.solution"
                                                [isEmbedded]="true"
                                                [canCreate]="false"
                                                [canRouteToDetail]="true"
                                                [canDelete]="true"
                                                [detailLink]="'/missions/all'"
                                                module="inventory"
                                                [parentIds]="passedIds"
                                                [solution]="$any((model$ | async)?.solution)">
            </nexnox-web-tickets-ticket-missions>
        </ng-container>
    </ng-template>

    <!-- Links -->
    <ng-template nexnoxWebTabTemplate
                 tabId="links"
                 [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
                 [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
                 (selectTab)="onNavigateToTab(['links'])">
        <nexnox-web-tech-links *ngIf="model$ | async as model"
                               #linksComponent
                               #modelComponent
                               [links$]="links$"
                               [entityId]="model.ticketId"
                               [type]="linkedElementTypes.Ticket"
                               [readonly]="readonly$ | async"
                               [loading]="loading$ | async"
                               (linksChange)="onLinksChange($event, model)">
        </nexnox-web-tech-links>
    </ng-template>

    <!-- Attachments -->
    <ng-template nexnoxWebTabTemplate
                 tabId="attachments"
                 [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsString$ | async)"
                 [hasError]="(attachmentsComponent ? !attachmentsComponent.isModelValid() : false) || (solutionAttachmentsComponent ? !solutionAttachmentsComponent.isModelValid() : false)"
                 (selectTab)="onNavigateToTab(['attachments'])">
        <nexnox-web-tech-attachments *ngIf="model$ | async as model"
                                     #attachmentsComponent
                                     #modelComponent
                                     [attachments$]="attachments$"
                                     [readonly]="readonly$ | async"
                                     [loading]="loading$ | async"
                                     (attachmentsChange)="onAttachmentsChange($event, model)">
        </nexnox-web-tech-attachments>

        <nexnox-web-tech-attachments *ngIf="readSolutionPermission$ | async"
                                     #solutionAttachmentsComponent
                                     #modelComponent
                                     cardboxTitle="tech-portal.subtitles.solution-attachments"
                                     [attachments$]="solutionAttachments$"
                                     [readonly]="true"
                                     [loading]="loading$ | async">
        </nexnox-web-tech-attachments>
    </ng-template>

    <!-- Audit Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="audit"
                 *ngIf="readAuditPermission$ | async"
                 [heading]="'core-portal.audit.title' | translate"
                 [lazy]="true"
                 (selectTab)="onNavigateToTab(['audit'])">
        <nexnox-web-tickets-ticket-audit *ngIf="passedIds"
                                         [isEmbedded]="true"
                                         [parentIds]="[entityTypes.Ticket, passedIds[0]]">
        </nexnox-web-tickets-ticket-audit>
    </ng-template>
</nexnox-web-entity-detail>

<nexnox-web-ticket-state-change-sidebar #ticketStateChangeSidebar>
</nexnox-web-ticket-state-change-sidebar>
