import { EntityXsStore, EntityXsStoreState } from 'libs/core-store/src';
import {AppEntityType, KnowledgeArticleDto, KnowledgePathDto} from 'libs/core-shared/src';
import { createSelector } from '@ngrx/store';
import { selectKnowledgePathState } from '../../paths.selectors';
import { OrgaPortalKnowledgePathService } from 'libs/orga-portal/src';

export interface PathsDetailStoreState extends EntityXsStoreState<KnowledgePathDto> {
}

/* istanbul ignore next */
export const pathsDetailStore = new EntityXsStore<KnowledgePathDto>({
  actionLabel: 'Orga Portal - Knowledge - Knowledge Paths Detail',
  stateSelector: createSelector(selectKnowledgePathState, state => state.pathsDetail),
  serviceType: OrgaPortalKnowledgePathService,
  entityType: AppEntityType.KnowledgePath,
  stereotyped: false
});
