import { NgModule } from '@angular/core';
import { CorePortalModule } from '@nexnox-web/core-portal';
import { OrgaPortalAttachmentsComponent } from './components';
import {OrgaPortalDocumentService, OrgaPortalKnowledgeArticleService, OrgaPortalKnowledgePathService} from './services';
import { FormlyModule } from '@ngx-formly/core';
import { TypeOption } from '@ngx-formly/core/lib/services/formly.config';
import { FormlyFolderSelectComponent } from './formly-types';
import { TreeSelectModule } from 'primeng/treeselect';

const exportedComponents = [
  OrgaPortalAttachmentsComponent
];

const formlyTypes: TypeOption[] = [
  { name: 'orga-portal-folder-select', component: FormlyFolderSelectComponent }
];
const formlyComponents = [
  FormlyFolderSelectComponent
];

@NgModule({
  declarations: [
    ...exportedComponents,
    ...formlyComponents
  ],
  imports: [
    CorePortalModule,
    TreeSelectModule,

    FormlyModule.forChild({
      types: formlyTypes
    })
  ],
  exports: [
    FormlyModule,

    ...exportedComponents
  ],
  providers: [
    OrgaPortalKnowledgeArticleService,
    OrgaPortalKnowledgePathService,
    OrgaPortalDocumentService
  ]
})
export class OrgaPortalModule {
}
