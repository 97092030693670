import {pathsDetailStore, pathsListStore} from './stores';

export * from './stores';
export * from './paths.state';
export * from './paths.selectors';

export const pathsReducers = {
  pathsList: pathsListStore.reducer,
  pathsDetail: pathsDetailStore.reducer
};

export const pathsEffects = [
  pathsListStore.effects,
  pathsDetailStore.effects
];

export const pathsServices = [
];
