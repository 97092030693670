import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {CorePortalEntityDetailBaseComponent, TabsComponent} from '@nexnox-web/core-portal';
import {UnsubscribeHelper} from '@nexnox-web/core-shared';
import {firstValueFrom, NEVER, Observable} from 'rxjs';
import {delay, filter, map, take} from 'rxjs/operators';
import {TabTemplateDirective} from '../../../../tabs/directives';

@Component({
  selector: 'nexnox-web-entity-detail',
  templateUrl: './entity-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityDetailComponent extends UnsubscribeHelper implements OnInit {
  @Input() public tabId: string;
  @Input() public tabHeading: string;
  @Input() public tabCommands: any[];
  @Input() public useTabs = true;
  @Input() public hideOverflow = true;
  @Input() public ready$: Observable<any>;

  @Input() public entityDetailBaseComponent: CorePortalEntityDetailBaseComponent<any>;
  @Input() public entityEditTemplate: TemplateRef<any>;

  @ContentChildren(TabTemplateDirective) public tabTemplates: QueryList<TabTemplateDirective>;

  @ViewChild('tabsComponent') public tabsComponent: TabsComponent;

  public isArchived: boolean;

  public ngOnInit(): void {
    this.subscribeToRoute();
  }

  public onNavigateToTab(commands: any[]): void {
    this.entityDetailBaseComponent?.onNavigateToTab(commands);
  }

  public onForceTab(tabId: string): void {
    this.tabsComponent?.activateTab(tabId);
  }

  private subscribeToRoute(): void {
    this.subscribe((this.entityDetailBaseComponent?.route?.firstChild?.data ?? NEVER).pipe(
      filter(data => Boolean(data?.tab)),
      take(1)
    ), data => {
      if (this.ready$) {
        this.ready$.pipe(
          filter(x => Boolean(x)),
          take(1),
          delay(1)
        ).subscribe(() => this.tabsComponent?.activateTab(data.tab));
      } else {
        setTimeout(() => this.tabsComponent?.activateTab(data.tab));
      }
    });
  }
}
