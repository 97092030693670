import {
  issueListStore,
  IssueListStoreState,
  messageIssueDetailStore,
  MessageIssueDetailStoreState,
  missionIssueDetailStore,
  MissionIssueDetailStoreState,
  ticketIssueDetailStore,
  TicketIssueDetailStoreState,
  winterhalterIssueDetailStore,
  WinterhalterIssueDetailStoreState,
} from './stores';

export interface IssuesState {
  issueList: IssueListStoreState;
  messageIssueDetail: MessageIssueDetailStoreState;
  missionIssueDetail: MissionIssueDetailStoreState;
  ticketIssueDetail: TicketIssueDetailStoreState;
  winterhalterIssueDetail: WinterhalterIssueDetailStoreState;
}

export const getInitialIssuesState = (): IssuesState => ({
  issueList: issueListStore.getInitialState(),
  messageIssueDetail: messageIssueDetailStore.getInitialState(),
  missionIssueDetail: missionIssueDetailStore.getInitialState(),
  ticketIssueDetail: ticketIssueDetailStore.getInitialState(),
  winterhalterIssueDetail: winterhalterIssueDetailStore.getInitialState()
});
