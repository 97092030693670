import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, DataTableInRestrictionDto, NotificationRuleDto} from '@nexnox-web/core-shared';
import {notificationRulesDetailStore} from '../../store';
import {NotificationTargetLocalDto} from "../../components";

@Component({
  selector: 'nexnox-web-settings-notification-rules-detail',
  templateUrl: './notification-rules-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationRulesDetailComponent extends CorePortalEntityDetailBaseComponent<NotificationRuleDto> {
  public title = 'core-portal.settings.subtitles.notification-rules.notification-rules-detail';

  protected idParam = 'notificationRuleId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, notificationRulesDetailStore);
  }

  public onRestrictionChange(restriction: DataTableInRestrictionDto, model: NotificationRuleDto): void {
    this.onModelChange({...model, restriction});
  }

  public onTargetsChanged(targets: NotificationTargetLocalDto[], model: NotificationRuleDto): void {
    this.onModelChange({...model, targets});
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.notification-rules.edit',
      'core-portal.settings.actions.notification-rules.save',
      'core-portal.settings.actions.notification-rules.delete',
      'core-portal.settings.actions.notification-rules.delete-description',
      AppPermissions.UpdateNotificationRule,
      AppPermissions.DeleteNotificationRule
    );
  }
}
