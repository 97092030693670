import {ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CombineOperator, DocumentTemplateContextType, FilterOperators, FilterTypes} from '@nexnox-web/core-shared';
import {CorePortalEntityCreatePresetService} from "@nexnox-web/core-portal";
import {DocumentTemplateListComponent} from "../../containers";
import {TemplateUsageTypes} from "@nexnox-web/tech-portal/features/templates/src/lib/models";

@Component({
  selector: 'nexnox-web-settings-templates-issue-template-document-list-wrapper',
  templateUrl: '../../containers/document-templates/document-template-list/document-template-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueDocumentTemplateListWrapperComponent extends DocumentTemplateListComponent implements OnInit, OnDestroy {

  public templateUsageType = TemplateUsageTypes.Issue;

  constructor(
    protected injector: Injector,
    protected entityCreatePresetService: CorePortalEntityCreatePresetService
  ) {
    super(injector);

    this.defaultFilter = [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        property: 'contextType',
        children: [
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'contextType',
            value: DocumentTemplateContextType.Issue.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'contextType',
            value: DocumentTemplateContextType.MessageIssue.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'contextType',
            value: DocumentTemplateContextType.MissionIssue.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'contextType',
            value: DocumentTemplateContextType.TicketIssue.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'contextType',
            value: DocumentTemplateContextType.WinterhalterIssue.toString()
          }
        ]
      }
    ];

    this.componentId = 'IssueDocumentTemplateListWrapperComponent';
  }

  public ngOnInit(): void {
    this.setWrapperCreatePreset();
    super.ngOnInit();
    this.detailLink = 'templates/issue-document-templates';
  }

  public ngOnDestroy(): void {
    this.isEmbedded = true;
    super.ngOnDestroy();
  }

  public setWrapperCreatePreset(): void {
    this.entityCreatePresetService.setPreset('TechPortalFeatureDocumentTemplateEditComponent', {
      contextType: DocumentTemplateContextType.Issue
    });
  }
}
