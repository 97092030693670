import {
  issueListStore,
  messageIssueDetailStore,
  messageIssueDetailStoreReducer,
  missionIssueDetailStore,
  missionIssueDetailStoreReducer,
  ticketIssueDetailStore,
  ticketIssueDetailStoreReducer,
  winterhalterIssueDetailStore,
  winterhalterIssueDetailStoreReducer
} from './stores';
import {
  MessageIssueService,
  MissionIssueService,
  TechPortalFeatureIssueListService,
  TicketIssueService,
  WinterhalterIssueService
} from "./services";

export * from './stores';
export * from './services';

export * from './issues.state';
export * from './issues.selectors';

export const issuesReducers = {
  issueList: issueListStore.reducer,
  messageIssueDetail: messageIssueDetailStoreReducer,
  missionIssueDetail: missionIssueDetailStoreReducer,
  ticketIssueDetail: ticketIssueDetailStoreReducer,
  winterhalterIssueDetail: winterhalterIssueDetailStoreReducer,
};

export const issuesEffects = [
  issueListStore.effects,
  messageIssueDetailStore.effects,
  missionIssueDetailStore.effects,
  ticketIssueDetailStore.effects,
  winterhalterIssueDetailStore.effects
];

export const issuesServices = [
  TechPortalFeatureIssueListService,
  MessageIssueService,
  MissionIssueService,
  TicketIssueService,
  WinterhalterIssueService
];
